// import React, { useEffect, useRef } from "react";
// import { useState, useCallback } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchProperties,
//   saveActiveProperty,
// } from "../reduxToolkit/Slice/propertyslice";
// import { BgThemeButton } from "./UI/Buttons";
// import { Link, useNavigate } from "react-router-dom";
// import {
//   checkIsAuthenticatedFalse,
//   checkIsAuthenticatedTrue,
// } from "../reduxToolkit/Slice/isAuthenticatedSlice";
// import { Tooltip } from "@shopify/polaris";

// const SidebarHotelOptions = () => {
//   const hotelOptionsRef = useRef(null);
//   const [hotelOptionsIsOpen, setHotelOptionsIsOpen] = useState(false);
//   const [isFetching, setIsFetching] = useState(true);
//   const [allProperties, setAllProperties] = useState([]);
//   const [permissions, setPermissions] = useState([]);
//   const selectedProperty = JSON.parse(localStorage.getItem("selectedProperty"));
//   // const selectedProperty = JSON.parse(localStorage.getItem("selectedProperty"));
//   const [showAddPropertyPopup, setShowAddPropertyPopup] = useState(false);

//   const navigate = useNavigate();
//   const userToken = JSON.parse(localStorage.getItem("userToken"));
//   const [selectedHotelOption, setSelectedHotelOption] =
//     useState(selectedProperty);

//   const dispatch = useDispatch();

//   console.log(
//     "process.env.REACT_APP_BASE_URL_AWS",
//     process.env.REACT_APP_BASE_URL_AWS
//   );

//   // redux
//   useEffect(() => {
//     dispatch(saveActiveProperty(selectedHotelOption));
//   }, [selectedHotelOption]);

//   useEffect(() => {
//     localStorage.setItem(
//       "selectedProperty",
//       JSON.stringify(selectedHotelOption)
//     );
//   }, [selectedHotelOption]);

//   // useEffect(() => {
//   //   localStorage.setItem("permissions", JSON.stringify(permissions));
//   // }, [permissions]);

//   const handleClickOutside = (event) => {
//     if (
//       hotelOptionsRef.current &&
//       !hotelOptionsRef.current.contains(event.target)
//     ) {
//       setHotelOptionsIsOpen(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const fetchInfo = async () => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}/api/v1/user-profile`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );

//       if (response.status === 401 || response.status === 500) {
//         localStorage.clear();
//         return;
//       }

//       const data = await response.json();
//       setAllProperties(data.data.properties);
//       setPermissions(data.data.permissions);
//       localStorage.setItem(
//         "permissions",
//         JSON.stringify(data.data.permissions)
//       );
//       localStorage.setItem("user", JSON.stringify(data?.data?.user));
//       if (data?.data?.properties?.length > 0) {
//         if (selectedProperty) {
//           setSelectedHotelOption(selectedProperty);
//           dispatch(saveActiveProperty(selectedHotelOption));
//           setIsFetching(false);
//         } else {
//           setSelectedHotelOption(data?.data?.properties[0]);
//           setIsFetching(false);
//         }
//       } else if (data?.data?.properties.length === 0) {
//         navigate("/nopropertypage");
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     fetchInfo();
//   }, []);

//   const toggleHotelOptionsDropdown = useCallback(() => {
//     setHotelOptionsIsOpen(!hotelOptionsIsOpen);
//     // fetchInfo();
//   }, [hotelOptionsIsOpen]);

//   const handleOptionClick = (option) => {
//     setSelectedHotelOption(option);
//     setHotelOptionsIsOpen(false);
//   };

//   const handleCloseAddFirstProperty = () => {
//     setShowAddPropertyPopup(!showAddPropertyPopup);
//   };

//   return (
//     <>
//       <div className="hotel_options_dropdown" ref={hotelOptionsRef}>
//         {hotelOptionsIsOpen && (
//           <div>
//             <div className="hotel_options_dropdown_options">
//               {allProperties?.map((option, index) => (
//                 <div
//                   className="hotel_options_dropdown_options_child"
//                   key={index}
//                   onClick={() => handleOptionClick(option)}
//                 >
//                   {option?.property_name}
//                 </div>
//               ))}
//             </div>
//           </div>
//         )}
//         <div
//           className="hotel_options_dropdown_btn"
//           onClick={toggleHotelOptionsDropdown}
//         >
//           {/* <Tooltip content={<span>{selectedHotelOption?.property_name}</span>}> */}

//           <div className="truncate">{selectedHotelOption?.property_name}</div>
//           {/* </Tooltip> */}

//           {hotelOptionsIsOpen ? (
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="9"
//               height="6"
//               viewBox="0 0 9 6"
//               fill="none"
//             >
//               <path
//                 d="M7.47043 5.5L0.543295 5.5C0.441855 5.50001 0.342435 5.47162 0.256301 5.41804C0.170167 5.36446 0.100757 5.28784 0.0559277 5.19684C0.0110988 5.10584 -0.00735669 5.00411 0.00265116 4.90317C0.012659 4.80222 0.0507354 4.70609 0.112559 4.62567L3.49738 0.250806C3.55742 0.172777 3.63458 0.109584 3.72292 0.0660981C3.81126 0.0226125 3.9084 3.58339e-07 4.00686 3.49731e-07C4.10532 3.41124e-07 4.20247 0.0226124 4.29081 0.0660981C4.37914 0.109584 4.4563 0.172777 4.51635 0.250806L7.89474 4.62567C7.95625 4.70566 7.99425 4.8012 8.00451 4.90158C8.01477 5.00195 7.99687 5.1032 7.95282 5.19398C7.90876 5.28475 7.8403 5.36147 7.7551 5.41552C7.6699 5.46957 7.57133 5.49882 7.47043 5.5Z"
//                 fill="#666666"
//               />
//             </svg>
//           ) : (
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="9"
//               height="6"
//               viewBox="0 0 9 6"
//               fill="none"
//             >
//               <path
//                 d="M0.536891 2.12005e-09H7.46403C7.56547 -8.95738e-06 7.66489 0.02838 7.75102 0.0819603C7.83716 0.135541 7.90657 0.212164 7.9514 0.30316C7.99623 0.394156 8.01468 0.495889 8.00467 0.596834C7.99467 0.697778 7.95659 0.793909 7.89476 0.874331L4.50995 5.24919C4.4499 5.32722 4.37274 5.39042 4.2844 5.4339C4.19607 5.47739 4.09892 5.5 4.00046 5.5C3.902 5.5 3.80485 5.47739 3.71652 5.4339C3.62818 5.39042 3.55102 5.32722 3.49097 5.24919L0.112581 0.874331C0.0510789 0.794342 0.0130717 0.6988 0.00281478 0.598423C-0.00744212 0.498046 0.0104546 0.396799 0.0545071 0.306024C0.0985597 0.215249 0.167027 0.138533 0.252227 0.0844814C0.337427 0.0304294 0.435998 0.00117986 0.536891 2.12005e-09Z"
//                 fill="#666666"
//               />
//             </svg>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default SidebarHotelOptions;

import React, { useEffect, useRef } from "react";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProperties,
  saveActiveProperty,
} from "../reduxToolkit/Slice/propertyslice";
import { BgThemeButton } from "./UI/Buttons";
import { Link, useNavigate } from "react-router-dom";
import {
  checkIsAuthenticatedFalse,
  checkIsAuthenticatedTrue,
} from "../reduxToolkit/Slice/isAuthenticatedSlice";
import { Tooltip } from "@shopify/polaris";
import { getActiveApp } from "../utils/utils";

const SidebarHotelOptions = ({ showSidebar, setShowSidebar }) => {
  const activeApp = getActiveApp()
  const hotelOptionsRef = useRef(null);
  const [hotelOptionsIsOpen, setHotelOptionsIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [allProperties, setAllProperties] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const selectedProperty = JSON.parse(localStorage.getItem("selectedProperty"));
  const [showAddPropertyPopup, setShowAddPropertyPopup] = useState(false);

  const navigate = useNavigate();
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [selectedHotelOption, setSelectedHotelOption] =
    useState(selectedProperty);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(saveActiveProperty(selectedHotelOption));
  }, [selectedHotelOption]);

  useEffect(() => {
    localStorage.setItem(
      "selectedProperty",
      JSON.stringify(selectedHotelOption)
    );
    // localStorage.setItem("selectedStep", 1);
  }, [selectedHotelOption]);

  const handleClickOutside = (event) => {
    if (
      hotelOptionsRef.current &&
      !hotelOptionsRef.current.contains(event.target)
    ) {
      setHotelOptionsIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchInfo = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user-profile?active_app=${activeApp}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 401 || response.status === 500) {
        localStorage.clear();
        return;
      }

      const data = await response.json();
      setAllProperties(data.data.properties);
      setPermissions(data.data.permissions);
      localStorage.setItem(
        "permissions",
        JSON.stringify(data.data.permissions)
      );
      localStorage.setItem("user", JSON.stringify(data?.data?.user));
      if (data?.data?.properties?.length > 0) {
        if (selectedProperty) {
          setSelectedHotelOption(selectedProperty);
          dispatch(saveActiveProperty(selectedHotelOption));
          setIsFetching(false);
        } else {
          setSelectedHotelOption(data?.data?.properties[0]);
          setIsFetching(false);
        }
      } else if (data?.data?.properties.length === 0) {
        navigate("/nopropertypage");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const toggleHotelOptionsDropdown = useCallback(() => {
    setHotelOptionsIsOpen(!hotelOptionsIsOpen);
  }, [hotelOptionsIsOpen]);

  // const handleOptionClick = (option) => {
  //   setSelectedHotelOption(option);
  //   setHotelOptionsIsOpen(false)
  //   setShowSidebar(false);
  // };

  const handleOptionClick = (option) => {
    setSelectedHotelOption(option);
    setHotelOptionsIsOpen(false);

  
    setTimeout(() => {
      
      setShowSidebar(false); // Ensure that this setShowSidebar function is properly passed as a prop
    }, 500);
  };

  const handleCloseAddFirstProperty = () => {
    setShowAddPropertyPopup(!showAddPropertyPopup);
  };

  const toggleSidebar = () => {
    showSidebar((prevState) => !prevState); // Toggle sidebar popup
  };

  return (
    <>
      <div className="hotel_options_dropdown" ref={hotelOptionsRef}>
        {hotelOptionsIsOpen && (
          <div>
            <div className="hotel_options_dropdown_options">
              {allProperties?.map((option, index) => (
                <div
                  className="hotel_options_dropdown_options_child"
                  key={index}
                  onClick={() => handleOptionClick(option)}
                >
                  {option?.property_name}
                </div>
              ))}
            </div>
          </div>
        )}
        <div
          className="hotel_options_dropdown_btn"
          onClick={toggleHotelOptionsDropdown}
        >
          <div className="truncate">{selectedHotelOption?.property_name}</div>
          {hotelOptionsIsOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="6"
              viewBox="0 0 9 6"
              fill="none"
            >
              <path
                d="M7.47043 5.5L0.543295 5.5C0.441855 5.50001 0.342435 5.47162 0.256301 5.41804C0.170167 5.36446 0.100757 5.28784 0.0559277 5.19684C0.0110988 5.10584 -0.00735669 5.00411 0.00265116 4.90317C0.012659 4.80222 0.0507354 4.70609 0.112559 4.62567L3.49738 0.250806C3.55742 0.172777 3.63458 0.109584 3.72292 0.0660981C3.81126 0.0226125 3.9084 3.58339e-07 4.00686 3.49731e-07C4.10532 3.41124e-07 4.20247 0.0226124 4.29081 0.0660981C4.37914 0.109584 4.4563 0.172777 4.51635 0.250806L7.89474 4.62567C7.95625 4.70566 7.99425 4.8012 8.00451 4.90158C8.01477 5.00195 7.99687 5.1032 7.95282 5.19398C7.90876 5.28475 7.8403 5.36147 7.7551 5.41552C7.6699 5.46957 7.57133 5.49882 7.47043 5.5Z"
                fill="#666666"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="6"
              viewBox="0 0 9 6"
              fill="none"
            >
              <path
                d="M0.536891 2.12005e-09H7.46403C7.56547 -8.95738e-06 7.66489 0.02838 7.75102 0.0819603C7.83716 0.135541 7.90657 0.212164 7.9514 0.30316C7.99623 0.394156 8.01468 0.495889 8.00467 0.596834C7.99467 0.697778 7.95659 0.793909 7.89476 0.874331L4.50995 5.24919C4.4499 5.32722 4.37274 5.39042 4.2844 5.4339C4.19607 5.47739 4.09892 5.5 4.00046 5.5C3.902 5.5 3.80485 5.47739 3.71652 5.4339C3.62818 5.39042 3.55102 5.32722 3.49097 5.24919L0.112581 0.874331C0.0510789 0.794342 0.0130717 0.6988 0.00281478 0.598423C-0.00744212 0.498046 0.0104546 0.396799 0.0545071 0.306024C0.0985597 0.215249 0.167027 0.138533 0.252227 0.0844814C0.337427 0.0304294 0.435998 0.00117986 0.536891 2.12005e-09Z"
                fill="#666666"
              />
            </svg>
          )}
        </div>
      </div>
    </>
  );
};

export default SidebarHotelOptions;
