import React, { useState } from "react";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import SelectDateRange from "../SelectDateRange/SelectDateRange";
import { BgThemeButton, BgThemeButtonDisabled } from "../../UI/Buttons";
import { Button, Tooltip } from "@shopify/polaris";
import styles from "./inventorySliderComponents.module.scss";

const InventoryBulkBlock = ({
  setShow,
  baseUrl,
  property_id,
  userToken,
  fetchInfo,
  categories,
  currentDate,
  permissions,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [blockInventoryData, setBlockInventoryData] = useState({
    property_id: property_id?.id,
    from_date: "",
    to_date: "",
  });
  const blockInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();

    console.log("called");

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/inventories/block/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...blockInventoryData,
            room_categories: selectedValues,
          }),
        }
      );

      const data = await response.json();

      if (response.ok && data.success) {
        showSuccessToast(data.message);
        fetchInfo(property_id?.id);
      } else {
        showErrorToast(data.message || "An error occurred.");
        console.log("Post request failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Check if the value is not already in the array before adding it
      if (!selectedValues.includes(value)) {
        setSelectedValues([...selectedValues, value]);
        addCheckbox();
      }
    } else {
      // Remove the value from the array if it exists
      setSelectedValues(selectedValues.filter((item) => item !== value));
    }
  };
  const addCheckbox = () => {
    const newCheckbox = {
      id: Date.now(),
      label: `Option ${checkboxOptions.length + 1}`,
      value: `option${checkboxOptions.length + 1}`,
    };

    setCheckboxOptions([...checkboxOptions, newCheckbox]);
  };
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  return (
    <>
      <div className={styles.container}>
        <div>
          <span style={{ paddingBottom: "5px" }}>Select Date Range</span>
          <div style={{ paddingTop: "5px" }}>
            <SelectDateRange
              dataFromChild={(from_date, to_date) => {
                setBlockInventoryData({
                  ...blockInventoryData,
                  from_date: from_date,
                  to_date: to_date,
                });
              }}
              minDate={new Date(currentDate)}
            />
          </div>
        </div>
        <hr />

        <div>
          <div className="form-container-div">
            <div
              style={{
                color: "#333",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Select Room Categories
            </div>

            <div
              style={{
                display: "flex",
                gap: "10px",
                color: "#333",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
                flexDirection: "column",
              }}
            >
              {categories.map((room) => {
                return (
                  <div>
                    <input
                      type="checkbox"
                      name="update_type"
                      onChange={handleCheckboxChange}
                      value={room.id}
                      style={{ marginRight: "10px" }}
                    />
                    {room.room_name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="form-container-div-half"></div>
      </div>

      <div
        className={`flex align_items_center flex_gap_20 ${styles.buttonContainer}`}
      >
        {permissions && permissions.includes("update_block_inventory") ? (
          <BgThemeButton
            onClick={blockInventoryDataUpdateHandle}
            children={"Update"}
          />
        ) : (
          <Tooltip content={`Please contact your admin.`} dismissOnMouseOut>
            <BgThemeButtonDisabled type="submit" children={"Update"} />
          </Tooltip>
        )}

        <Button onClick={() => setShow(false)}>Cancel</Button>
      </div>
    </>
  );
};

export default InventoryBulkBlock;
