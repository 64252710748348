import { Button, Icon } from "@shopify/polaris";

import React, { useEffect, useState } from "react";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { BgThemeButton } from "../UI/Buttons";
import { getBaseUrl } from "../../utils/utils";

const InventoryChannelMapping = ({
  setShow,
  show,
  propertyData,
  selectedChannelMapping,
  roomCategories,
}) => {
  const baseURL = getBaseUrl();
  const userToken = localStorage.getItem("token");

  const [dataArray, setDataArray] = useState([]);

  const [addMapsData, setAddMapsData] = useState({
    property_id: propertyData.id,
    property_name: propertyData.property_name,
    meal_plan_name: "",
    channel_unique_id: "",
    property_room_id: "",
    channel_room_id: "",
    property_rate_plan_id: "",
    channel_rate_plan_id: "",
    channel_id: selectedChannelMapping?.channelId,
    channel_name: selectedChannelMapping?.channelName,
  });

  const setAddItemRateDataHandleChange = (event) => {
    if (event.target.name === "property_rate_plan_id") {
      setAddMapsData({
        ...addMapsData,
        [event.target.name]: event.target.value,
        meal_plan_name: rateOptions.find(
          (item) => +item.value === +event.target.value
        ).label,
      });
      return;
    } else {
      setAddMapsData({
        ...addMapsData,
        [event.target.name]: event.target.value,
      });
    }
  };
  const [selectedRoomRates, setSelectedRoomRates] = useState([]);

  const rateOptions = [
    { label: "Select", value: "" }, // Add the default "Select" option
    ...selectedRoomRates?.map((room) => {
      return { label: room.rate_plan_name, value: room.id };
    }),
  ];
  const roomOptions = [
    { label: "Select", value: "" }, // Add the default "Select" option
    ...roomCategories?.map((room) => {
      return { label: room.room_name, value: room.id };
    }),
  ];

  const [roomId, setRoomId] = useState(null);

  const onRoomChangeFunc = (e) => {
    setRoomId(e.target.value);
    const roomId = +e.target.value;
    e.preventDefault();

    if (addMapsData.channel_unique_id === "") {
      showErrorToast("Please enter the channel ID");
      return;
    }

    fetch(`${baseURL}/api/v1/get/rooms/rates/${roomId}/${propertyData.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setSelectedRoomRates(data.roomRates);

        if (data.success) {
          setAddMapsData({
            ...addMapsData,
            property_room_id: roomId,
            room_name: roomOptions.find((room) => +room.value === roomId)
              ?.label,
          });
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const handleAddClick = () => {
    if (addMapsData.property_id === "") {
      showErrorToast("Please select Property ");
      return;
    }
    if (addMapsData.channel_unique_id === "") {
      showErrorToast("Please enter Channel Id ");
      return;
    }

    if (addMapsData.property_room_id === "") {
      showErrorToast("Please select Rooms Types");
      return;
    }

    if (addMapsData.channel_room_id === "") {
      showErrorToast("Please Enter Channel Room Id");
      return;
    }
    if (addMapsData.property_rate_plan_id === "") {
      showErrorToast("please select  Rate Plan Id");
      return;
    }
    if (addMapsData.channel_rate_plan_id === "") {
      showErrorToast("please Enter Channel rate plan Id");
      return;
    }

    setDataArray((prevArray) => [...prevArray, addMapsData]);

    setAddMapsData((prev) => ({
      ...prev,
      property_room_id: "",
      channel_room_id: "",
      property_rate_plan_id: "",
      channel_rate_plan_id: "",
      rate_plan_name: "",
      room_name: "",
    }));
  };
  const handleSubmitAddMeals = async (event) => {
    event.preventDefault();
  
    try {
      const response = await fetch(`${baseURL}/api/v1/map/channels`, {
        method: "POST",
        body: JSON.stringify({
          data: dataArray,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
  
      const data = await response.json();
  
      if (data[0].success === true) {
        showSuccessToast(data.message || "Channel mapped successfully!");
        setShow(false); // Close the modal or hide the form if needed
      } else {
        if (data[0].message === "Already Mapped") {
          showErrorToast("This channel has already been mapped.");
          setShow(false); 
        } else {
          throw new Error(data[0].message || "Something went wrong!");
        }
      }
    } catch (error) {
      showErrorToast(error.message || "An error occurred during the request.");
    }
  };
  
  return (
    <>
      <div className="bg-backdrop">
        <div className="stock-add-model-container" style={{ width: "850px" }}>
          <div
            className="flex justify_content_between align_items_center"
            style={{ marginBottom: "15px" }}
          >
            <div className="add-ota-model-container-heading align_items_center">
              Mapping
            </div>
            <div
              onClick={() => setShow(false)}
              style={{ cursor: "pointer", fontSize: "20px", color: "#8d8989" }}
            >
             X
            </div>
          </div>

          <div className="form-container-div-half">
            <div className="form-container-div">
             
              <VerticalInputFieldCustom
                titleName={"Properties"}
                disabled={true}
                value={propertyData.property_name}
              />
            </div>
            <div className="form-container-div">
              <VerticalInputFieldCustom
                titleName="Channel Id"
                name="channel_unique_id"
                type="number"
                value={addMapsData.channel_unique_id}
                onChange={setAddItemRateDataHandleChange}
                disabled={dataArray.length > 0 ? true : false}
              />
            </div>
          </div>

          {roomOptions && roomOptions.length > 0 && (
            <div className="flex flex_gap_10">
              <VerticalInputSelectCustomCopy
                titleName={"Rooms"}
                name="property_room_id"
                options={roomOptions}
                value={addMapsData.property_room_id}
                onChange={(e) => onRoomChangeFunc(e)}
                style={{ width: "200px" }}
              />

              <VerticalInputFieldCustom
                titleName="Channel Room Id"
                name="channel_room_id"
                type="number"
                value={addMapsData.channel_room_id}
                onChange={setAddItemRateDataHandleChange}
                style={{ width: "150px" }}
              />
              {rateOptions && rateOptions.length > 0 && (
                <div className="flex flex_gap_10 align_items_end">
                  <VerticalInputSelectCustomCopy
                    titleName={"Room Rate"}
                    options={rateOptions}
                    name={"property_rate_plan_id"}
                    value={addMapsData.property_rate_plan_id}
                    onChange={setAddItemRateDataHandleChange}
                    style={{ width: "200px" }}
                  />

                  <VerticalInputFieldCustom
                    titleName="Channel Rate Id"
                    name="channel_rate_plan_id"
                    type="number"
                    value={addMapsData.channel_rate_plan_id}
                    onChange={setAddItemRateDataHandleChange}
                    style={{ width: "150px" }}
                  />

                  <button
                    onClick={handleAddClick}
                    style={{
                      cursor: "pointer",
                      background: "transparent",
                      border: "1px solid #3968ed",
                      borderRadius: "2px",
                      padding: "2px",
                      display: "grid",
                      placeItems: "center",
                      width: "fit-content",
                      height: "fit-content",
                    }}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 0H9V9H0V11H9V20H11V11H20V9H11V0Z"
                        fill="#3968ed"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          )}

          {dataArray.length > 0 ? (
            <>
              <div style={{ margin: "10px  0" }}>
                {dataArray.map((item, index) => {
                  return (
                    <div
                      className="flex flex_gap_10 align_items_end"
                      key={index}
                    >
                      <VerticalInputFieldCustom
                        value={item.room_name}
                        disabled={true}
                        style={{ width: "200px" }}
                      />
                      <VerticalInputFieldCustom
                        value={item.channel_room_id}
                        disabled={true}
                        style={{ width: "150px" }}
                      />

                      <VerticalInputFieldCustom
                        value={item.meal_plan_name}
                        disabled={true}
                        style={{ width: "200px" }}
                      />
                      <VerticalInputFieldCustom
                        value={item.channel_rate_plan_id}
                        disabled={true}
                        style={{ width: "150px" }}
                      />

                      <div>
                        <button
                          onClick={() =>
                            setDataArray(
                              dataArray.filter(
                                (_, dataIndex) => dataIndex !== index
                              )
                            )
                          }
                          style={{
                            cursor: "pointer",
                            background: "transparent",
                            border: "none",
                          }}
                        >
                          <svg
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.2001 3.2H12.6001C13.0419 3.2 13.4001 3.55817 13.4001 4L13.4001 4.8H0.600098L0.600119 4C0.600119 3.55817 0.958291 3.2 1.40012 3.2H3.80012V1.2C3.80012 0.537258 4.33738 0 5.00012 0H9.00012C9.66286 0 10.2001 0.537259 10.2001 1.2V3.2ZM5.40012 1.6V3.2H8.60012V1.6H5.40012Z"
                              fill="#E03838"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1.40012 6.4H12.6001V14.8C12.6001 15.4627 12.0629 16 11.4001 16H2.60012C1.93738 16 1.40012 15.4627 1.40012 14.8V6.4ZM4.6001 8.8H3.0001V13.6H4.6001V8.8ZM7.8001 8.8H6.2001V13.6H7.8001V8.8ZM9.4001 8.8H11.0001V13.6H9.4001V8.8Z"
                              fill="#E03838"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}

          <div className="flex flex_gap_10" style={{ margin: "15px 0" }}>
            <BgThemeButton
              onClick={(e) => handleSubmitAddMeals(e)}
              children={"Add"}
              disabled={dataArray.length > 0 ? false : true}
            />
            <Button onClick={() => setShow(false)}>Cancel</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryChannelMapping;
