import React, { useState, useEffect, useCallback } from "react";
import SelectDatePickerCustom from "../SelectDateRange/SelectDatePickerCustom";
import DaySelectionWeekDays from "../../daySelectionWeekDays/DaySelectionWeekDays";
import { Button } from "@shopify/polaris";
import { BgThemeButton } from "../../UI/Buttons";
import SelectDateRange from "../SelectDateRange/SelectDateRange";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import styles from "./inventorySliderComponents.module.scss";

const InventoryUnblockChannelManager = ({
  categories,
  currentDate,
  selectedValues,
  setSelectedValues,
  addCheckbox,
  baseUrl,
  property_id,
  userToken,
  setLoader,
  channels,
  setShow,
  disabledSelectChannelOptions,
  defaultSelectedChannel

}) => {
  useEffect(() =>{
    if(defaultSelectedChannel && defaultSelectedChannel.length > 0){
      setSelectedChannel(defaultSelectedChannel.map((item)=>({channel_id: item.id, channel_name: item.channel_name})));
    }
  } ,[defaultSelectedChannel])
  const [dates, setDates] = useState({ from_date: "", to_date: "" });
 
  const [days, setDays] = useState({});

  const handleDaysChange = useCallback((data) => {
    setDays(data);
  }, []);



  const [inventoryData, setInventoryData] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      if (!inventoryData.some((item) => item.room_id === parseInt(value))) {
        setInventoryData([
          ...inventoryData,
          { room_id: parseInt(value), available_rooms: "" },
        ]);
      }
    } else {
      // Remove the item from inventoryData if unchecked
      setInventoryData(
        inventoryData.filter((item) => item.room_id !== parseInt(value))
      );
    }
  };

  const handleInputChange = (event, roomId) => {
    const { value } = event.target;

    setInventoryData(
      inventoryData.map((item) =>
        item.room_id === roomId
          ? { ...item, available_rooms: parseInt(value) || "" }
          : item
      )
    );
  };

  const [selectedChannel, setSelectedChannel] = useState([]);

  const handleCheckboxChangeChannel = (event) => {
    const { value, checked } = event.target;
    const channel_id = parseInt(value, 10); // Convert to number if necessary
    const channel_name = event.target.dataset.name; // Get the name from data attribute

    setSelectedChannel((prevSelectedChannels) => {
      if (checked) {
        // If checked, add to the array
        return [...prevSelectedChannels, {  channel_id, channel_name }];
      } else {
        // If unchecked, remove from the array
        return prevSelectedChannels.filter((channel) => channel.id !==  channel_id);
      }
    });
  };

  const updateStayRestrictions = async () => {
    // Validation checks
    if (selectedChannel.length === 0) {
      showErrorToast("Please select at least one channel.");
      return;
    }
  
    if (inventoryData.length === 0) {
      showErrorToast("Please add inventory data.");
      return;
    }
  
    for (let item of inventoryData) {
      if (!item.available_rooms || item.available_rooms === '') {
        showErrorToast("Please ensure all selected rooms have available rooms specified.");
        return;
      }
    }
  
    if (!dates || !dates.from_date || !dates.to_date) {
      showErrorToast("Please select both 'from' and 'to' dates.");
      return;
    }
  
    if (Object.keys(days).length === 0) {
      showErrorToast("Please select at least one day.");
      return;
    }
  
    const postData = {
      from_date: dates.from_date,
      to_date: dates.to_date,
      channels: selectedChannel,
      status: "Open",
      inventory_data: inventoryData,
      ...days
    };
  
    console.log(postData, "post data");
  
    try {
      setLoader(true);
  
      const response = await fetch(
        `${baseUrl}/api/v1/update/inventory/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(postData),
        }
      );
      
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setShow(false);
       window.location.reload();
      } else {
        showErrorToast(data.message || "Failed to update stay restrictions.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred while updating stay restrictions.");
    } finally {
      setLoader(false);
    }
  };    

  const handleCloseBookingSlider = () => {
    setShow(false);
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  };

  useEffect(() =>{
    if(defaultSelectedChannel && defaultSelectedChannel.length > 0){
      setSelectedChannel(defaultSelectedChannel.map((item)=>({channel_id: item.id, channel_name: item.channel_name})));
    }
  } ,[defaultSelectedChannel])
  return (
    <>
      <div className={styles.container}>
        <div style={{ width: "100%" }}>
          <div style={{ paddingBottom: "5px" }}>Select Date Range</div>
          <SelectDateRange
            dataFromChild={(from_date, to_date) => {
              setDates({
                from_date: from_date,
                to_date: to_date,
              });
            }}
            minDate={new Date(currentDate)}
          />
        </div>
        <hr />
        <div>
          <DaySelectionWeekDays onSaveDates={handleDaysChange} />
        </div>

        <hr />

        <div>
          <div className={styles.headings}>
          Select Room Categories
          </div>
        
          {categories.map((room) => {
            const inventoryItem = inventoryData.find(
              (item) => item.room_id === room.id
            );

            return (
              <div
                key={room.id}
                className="flex justify_content_between"
                style={{ margin: "5px 0" }}
              >
                <div>
                  <input
                    type="checkbox"
                    name="update_type"
                    onChange={handleCheckboxChange}
                    value={room.id}
                    checked={!!inventoryItem}
                    style={{ marginRight: "10px" }}
                  />
                  {room.room_name}
                </div>

                {inventoryItem && (
                  <input
                    type="number"
                    value={inventoryItem.available_rooms}
                    onChange={(e) => handleInputChange(e, room.id)}
                    className={styles.inputRoomData}
                  />
                )}
              </div>
            );
          })}
        </div>

        <hr />

        <div>
          <div>
            <div
              className={styles.headings}
            >
              Select Channels
            </div>

            <div
              style={{
                display: "flex",
                gap: "10px",
                color: "#333",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
                flexDirection: "column",
              }}
            >
              {
                channels?.length > 0 &&
                channels?.map((room) => (
                  <div key={room.channel_id}>
                    <input
                      type="checkbox"
                      value={room.channel_id}
                      data-name={room.channel_name} // Store the channel name as data attribute
                      onChange={handleCheckboxChangeChannel}
                      style={{ marginRight: "10px" }}
                      disabled={disabledSelectChannelOptions}
                      checked={selectedChannel.some(
                        (selected) => selected.channel_id === room.id || selected.channel_id === room.channel_id
                      )}
                    />
                    {room.channel_name}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`flex align_items_center flex_gap_20 ${styles.buttonContainer}`}
      >
        <BgThemeButton
          type="submit"
          onClick={updateStayRestrictions}
          children={"Update"}
        />

        <Button onClick={handleCloseBookingSlider}>Cancel</Button>
      </div>
    </>
  );
};

export default InventoryUnblockChannelManager;
