import React, { useState, useEffect } from "react";
import { popupStyles } from "./styles";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import SearchComponent from "../SearchComponent/SearchComponent";
import SelectDateRange from "../SelectDateRange/SelectDateRange";
import { BgThemeButton, BgOutlineThemeButton } from "../../UI/Buttons";
import Loader from "../../UI/Loader/Loader";
import LoaderSmall from "../../UI/Loader/LoaderSmall";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { useSelector } from "react-redux";
import { calculateTotalArrayObjectKey } from "../../../utils/utils";

const ReleaseRoomsManualAgentsPopup = ({ setShowReleaseRoomPopup, data }) => {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(false); // loader
  const [checkedCategories, setCheckedCategories] = useState({});
  const [roomCategories, setRoomCategories] = useState([]);
  const [dateRange, setDateRange] = useState({
    from_date: "",
    to_date: "",
  });

  const fetchRoomCategories = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/agent/hold/room/${property_id.id}?holdInventoryId=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch room categories");
      }

      const data = await response.json();
      setRoomCategories(data?.data.roomDetails);

      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };
  useEffect(() => {
    if (property_id?.id) {
      fetchRoomCategories(data.id);
    }
  }, [property_id, data.id]);

  const [selectedRoomCategories, setSelectedRoomCategories] = useState([]);
  const [availableRooms, setAvailableRooms] = useState({});
  const [roomData, setRoomData] = useState([]);
  const handleReleaseRoomsChange = (roomName, id, value) => {
    const holdRoomValue =
      roomCategories.find((item) => item.room_id === id)?.hold_rooms || 0;
    const validValue = Math.min(Number(value), holdRoomValue);

    setCheckedCategories((prev) => ({
      ...prev,
      [`${roomName}Input`]: validValue,
    }));

    setRoomData((prev) => {
      const existingCategory = prev.find((item) => item.room_id === id);
      if (existingCategory) {
        return prev.map((item) =>
          item.room_id === id ? { ...item, release_rooms: validValue } : item
        );
      }
      return [
        ...prev,
        { room_id: id, release_rooms: validValue, room_name: roomName },
      ];
    });
  };

  const handleDateChange = (form_date, to_date) => {
    setDateRange({ from_date: form_date, to_date: to_date });
  };

  const checkedRoomCategories = (roomName, id) => {
    if (dateRange.from_date === "" || dateRange.to_date === "") {
      showErrorToast("Please select the Date Range");
      return;
    }

    if (selectedRoomCategories.includes(roomName)) {
      setSelectedRoomCategories(
        selectedRoomCategories.filter((item) => item !== roomName)
      );
      setRoomData(roomData.filter((item) => item.room_id !== id));
      setCheckedCategories((prev) => {
        const newCheckedCategories = { ...prev };
        delete newCheckedCategories[`${roomName}Input`];
        return newCheckedCategories;
      });
    } else {
      setSelectedRoomCategories([...selectedRoomCategories, roomName]);
    }
  };

  console.log(data, "data");

  const handleUpdateButtonClick = async () => {
    const postData = {
      requestType: "release_rooms",
      release_from_date: dateRange.from_date,
      release_to_date: dateRange.to_date,
      reference_type: "agent",
      reference_id: data.reference_id,
      hold_inventory_id: data.id,
      room_categories: roomData,

    }
     try {
      const response = await fetch(

        `${process.env.REACT_APP_BASE_URL}/api/v1/release/agent/hold/room/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(postData),
        }
      );
      const data = await response.json();

      if (data.success) {
        showSuccessToast(data.message);
        setShowReleaseRoomPopup(false);
      } else {
        showErrorToast(data.message);
      }
     } catch (error) {
      console.error(error);
      showErrorToast("An error occurred while processing your request.");
     }
   
  }
  console.log(roomCategories,"roomCategories")

  return (
    <div style={popupStyles.container}>
      <div style={popupStyles.containerChild}>
        <h4 style={popupStyles.popupHeadings}>Release Rooms</h4>

        <div>
          <table style={popupStyles.tableBig}>
            <thead>
              <tr>
                <th style={{ paddingRight: "5px" }}>Company Name</th>
                <th style={{ padding: "0 5px" }}>Phone (optional)</th>
                <th style={{ paddingLeft: "5px" }}>Select Date Range</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ paddingRight: "5px" }}>
                  <VerticalInputFieldCustom
                    value={data.reference_person_name}
                    disabled={true}
                  />
                </td>
                <td style={{ padding: "0 5px" }}>
                  <VerticalInputFieldCustom
                    value={data.reference_person_phone || ""}
                    disabled={true}
                  />
                </td>
                <td style={{ paddingLeft: "5px" }}>
                  <SelectDateRange
                    dataFromChild={handleDateChange}
                    minDate={new Date(data.from_date)}
                    maxDate={new Date(data.to_date)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* ===========room Categories=========== */}
        <div>
          <div className="flex justify_content_between align_items_center">
            <h4 style={popupStyles.categoryHeading}>Select Room Categories</h4>
            <span style={{ fontSize: "13px" }}>
              <i>
                Total Hold Rooms :{" "}
                {calculateTotalArrayObjectKey(roomCategories, "hold_rooms")}{" "}
              </i>
            </span>
          </div>
          {loader ? (
            // <Loader />
            <LoaderSmall />
          ) : (
            <>
              {roomCategories?.map((item, index) => (
                <div
                  key={index}
                  style={{ height: "40px" }}
                  className="flex justify_content_between align_items_center"
                >
                  <div>
                    <label className="flex flex_gap_10 pointer">
                      <input
                        type="checkbox"
                        checked={selectedRoomCategories.includes(
                          item.room_name
                        )}
                        onChange={() =>
                          checkedRoomCategories(item.room_name, item.id)
                        }
                      />{" "}
                      <span>{item.room_name}</span>
                    </label>
                  </div>
                  <div>
                    {selectedRoomCategories.includes(item.room_name) && (
                      <div className="flex flex_gap_15">
                        <div className="flex flex_gap_5 align_items_center">
                          <span>Hold Rooms</span>
                          <VerticalInputFieldCustom
                            disabled={true}
                            style={popupStyles.smallInput}
                            value={item.hold_rooms}
                          />
                        </div>

                        <div className="flex flex_gap_5 align_items_center">
                          <span>Release Rooms</span>
                          <VerticalInputFieldCustom
                            style={popupStyles.smallInput}
                            type="text"
                            value={
                              checkedCategories[`${item.room_name}Input`] || ""
                            }
                            onChange={(e) =>
                              handleReleaseRoomsChange(
                                item.room_name,
                                item.room_id,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="flex flex_gap_10" style={{ marginTop: "20px" }}>
          <BgThemeButton
            children={"Update"}
            onClick={handleUpdateButtonClick}
          />
          <BgOutlineThemeButton
            children={"Cancel"}
            onClick={() => setShowReleaseRoomPopup(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default ReleaseRoomsManualAgentsPopup;
