import styles from './Inputs.module.scss';

export const PrimaryInput = ({
    titleName,
    name,
    onChange,
    value,
    type,
    defaultValue,
    label,
    placeholder,
    required,
    min,
    max,
    onKeyDown,
    disabled,
    inputId,
    maxLength,
    onBlur,
    style,
    error,
    format,
    size,
    icon,
    isIconLeft,
    isIconRight,
}) => {
    return (
        <div className={styles.primary_input_container}>
            <div className={styles.primary_input_div}>
                {isIconLeft && <span className={styles.icon_left}>{icon}</span>}
                <input
                    on
                    format={format}
                    style={{
                        ...style,
                        // backgroundColor: error ? "#FFEAEA" : "",
                        paddingLeft: isIconLeft && '30px',
                        paddingRight: isIconRight && '30px',

                        borderColor: error ? "#ED3939" : "",
                    }}
                    onBlur={onBlur}
                    onWheel={(e) => e.target.blur()}
                    maxLength={maxLength}
                    id={inputId}
                    disabled={disabled}
                    onKeyDown={onKeyDown}
                    required={required}
                    defaultValue={defaultValue}
                    autoComplete="off"
                    className={`${styles.primary_input} ${styles[size]} ${error ? styles.error : ""} ${disabled ? styles.disabled : ""}`}
                    name={name}
                    onChange={onChange}
                    value={value}
                    type={type}
                    placeholder={placeholder}
                    min={min}
                    max={max}
                ></input>
                {isIconRight && <span className={styles.icon_right}>{icon}</span>}
            </div>
            {error && <span className={styles.error_text}>Error text</span>}
        </div>
    );
};

export const PrefixInput = ({
    titleName,
    name,
    onChange,
    value,
    type,
    defaultValue,
    label,
    placeholder,
    required,
    min,
    max,
    onKeyDown,
    disabled,
    inputId,
    maxLength,
    onBlur,
    style,
    error,
    format,
    size,
    prefixText
}) => {
    return (
        <div className={styles.prefix_input_container}>
            <div className={styles.prefix_input_div}>
                {prefixText && <div
                    className={`${styles.icon_left} ${styles[size]}`}
                    style={{
                        ...style,
                        borderColor: error ? "#ED3939" : disabled ? 'transparent' : '',
                    }}
                >
                    {prefixText}
                </div>
                }
                <input
                    on
                    format={format}
                    style={{
                        ...style,
                        borderColor: error ? "#ED3939" : "",
                    }}
                    onBlur={onBlur}
                    onWheel={(e) => e.target.blur()}
                    maxLength={maxLength}
                    id={inputId}
                    disabled={disabled}
                    onKeyDown={onKeyDown}
                    required={required}
                    defaultValue={defaultValue}
                    autoComplete="off"
                    className={`${styles.prefix_input} ${styles[size]} ${error ? styles.error : ""} ${disabled ? styles.disabled : ""}`}
                    name={name}
                    onChange={onChange}
                    value={value}
                    type={type}
                    placeholder={placeholder}
                    min={min}
                    max={max}
                ></input>
            </div>
            {error && <span className={styles.error_text}>Error text</span>}
        </div>
    );
};

export const SuffixInput = ({
    titleName,
    name,
    onChange,
    value,
    type,
    defaultValue,
    label,
    placeholder,
    required,
    min,
    max,
    onKeyDown,
    disabled,
    inputId,
    maxLength,
    onBlur,
    style,
    error,
    format,
    size,
    suffixText
}) => {
    return (
        <div className={styles.suffix_input_container}>
            <div className={styles.suffix_input_div}>
               
                <input
                    on
                    format={format}
                    style={{
                        ...style,
                        borderColor: error ? "#ED3939" : "",
                    }}
                    onBlur={onBlur}
                    onWheel={(e) => e.target.blur()}
                    maxLength={maxLength}
                    id={inputId}
                    disabled={disabled}
                    onKeyDown={onKeyDown}
                    required={required}
                    defaultValue={defaultValue}
                    autoComplete="off"
                    className={`${styles.suffix_input} ${styles[size]} ${error ? styles.error : ""} ${disabled ? styles.disabled : ""}`}
                    name={name}
                    onChange={onChange}
                    value={value}
                    type={type}
                    placeholder={placeholder}
                    min={min}
                    max={max}
                ></input>
                 {suffixText && <div
                    className={`${styles.icon_left} ${styles[size]}`}
                    style={{
                        ...style,
                        borderColor: error ? "#ED3939" : disabled ? 'transparent' : '',
                    }}
                >
                    {suffixText}
                </div>
                }
            </div>
            {error && <span className={styles.error_text}>Error text</span>}
        </div>
    );
};


{/* <div className="Polaris-Labelled__LabelWrapper">
                <div className="Polaris-Label">
                    <label className="Polaris-Label__Text">
                        {titleName}
                        {required === true ? <span style={{ color: "red" }}> *</span> : ""}
                    </label>
                </div>
            </div> */}

{/* <div
                    className={
                        error
                            ? "Polaris-TextField__Backdrop__errors"
                            : "Polaris-TextField__Backdrop"
                    }
                ></div> */}