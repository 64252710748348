export const SvgLock = ({ onClick }) => (
  <svg
    width="13"
    height="15"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={{ cursor: "pointer" }}
  >
    <path
      d="M1.49967 12.8334C1.17884 12.8334 0.904188 12.7191 0.675716 12.4907C0.447244 12.2622 0.333008 11.9875 0.333008 11.6667V5.83337C0.333008 5.51254 0.447244 5.23789 0.675716 5.00942C0.904188 4.78094 1.17884 4.66671 1.49967 4.66671H2.08301V3.50004C2.08301 2.6931 2.36738 2.00525 2.93613 1.4365C3.50488 0.867749 4.19273 0.583374 4.99967 0.583374C5.80662 0.583374 6.49447 0.867749 7.06322 1.4365C7.63197 2.00525 7.91634 2.6931 7.91634 3.50004V4.66671H8.49967C8.82051 4.66671 9.09516 4.78094 9.32363 5.00942C9.5521 5.23789 9.66634 5.51254 9.66634 5.83337V11.6667C9.66634 11.9875 9.5521 12.2622 9.32363 12.4907C9.09516 12.7191 8.82051 12.8334 8.49967 12.8334H1.49967ZM4.99967 9.91671C5.32051 9.91671 5.59516 9.80247 5.82363 9.574C6.0521 9.34553 6.16634 9.07087 6.16634 8.75004C6.16634 8.42921 6.0521 8.15455 5.82363 7.92608C5.59516 7.69761 5.32051 7.58337 4.99967 7.58337C4.67884 7.58337 4.40419 7.69761 4.17572 7.92608C3.94724 8.15455 3.83301 8.42921 3.83301 8.75004C3.83301 9.07087 3.94724 9.34553 4.17572 9.574C4.40419 9.80247 4.67884 9.91671 4.99967 9.91671ZM3.24967 4.66671H6.74967V3.50004C6.74967 3.01393 6.57954 2.60074 6.23926 2.26046C5.89898 1.92018 5.48579 1.75004 4.99967 1.75004C4.51356 1.75004 4.10037 1.92018 3.76009 2.26046C3.41981 2.60074 3.24967 3.01393 3.24967 3.50004V4.66671Z"
      fill="#333333"
    />
  </svg>
);
export const SvgUnLock = ({ onClick }) => (
  <svg
    width="13"
    height="15"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={{ cursor: "pointer" }}
  >
    <path
      d="M5.49967 9.91671C5.82051 9.91671 6.09516 9.80247 6.32363 9.574C6.5521 9.34553 6.66634 9.07087 6.66634 8.75004C6.66634 8.42921 6.5521 8.15455 6.32363 7.92608C6.09516 7.69761 5.82051 7.58337 5.49967 7.58337C5.17884 7.58337 4.90419 7.69761 4.67572 7.92608C4.44724 8.15455 4.33301 8.42921 4.33301 8.75004C4.33301 9.07087 4.44724 9.34553 4.67572 9.574C4.90419 9.80247 5.17884 9.91671 5.49967 9.91671ZM1.99967 12.8334C1.67884 12.8334 1.40419 12.7191 1.17572 12.4907C0.947244 12.2622 0.833008 11.9875 0.833008 11.6667V5.83337C0.833008 5.51254 0.947244 5.23789 1.17572 5.00942C1.40419 4.78094 1.67884 4.66671 1.99967 4.66671H6.08301V3.50004C6.08301 2.6931 6.36738 2.00525 6.93613 1.4365C7.50488 0.867749 8.19273 0.583374 8.99967 0.583374C9.72884 0.583374 10.3632 0.819138 10.9028 1.29067C11.4424 1.76219 11.7656 2.3431 11.8726 3.03337C11.892 3.15976 11.8483 3.26914 11.7413 3.3615C11.6344 3.45386 11.4983 3.50004 11.333 3.50004C11.1677 3.50004 11.0316 3.46601 10.9247 3.39796C10.8177 3.3299 10.74 3.2181 10.6913 3.06254C10.5844 2.6931 10.3778 2.38199 10.0715 2.12921C9.7653 1.87643 9.40801 1.75004 8.99967 1.75004C8.51356 1.75004 8.10037 1.92018 7.76009 2.26046C7.41981 2.60074 7.24967 3.01393 7.24967 3.50004V4.66671H8.99967C9.32051 4.66671 9.59516 4.78094 9.82363 5.00942C10.0521 5.23789 10.1663 5.51254 10.1663 5.83337V11.6667C10.1663 11.9875 10.0521 12.2622 9.82363 12.4907C9.59516 12.7191 9.32051 12.8334 8.99967 12.8334H1.99967Z"
      fill="#333333"
    />
  </svg>
);

export const SvgBaseAdult = () => {
  return (
    <span className="flex flex_direction_col align_items_center flex_gap_5">
      <svg
        width="25"
        height="15"
        viewBox="0 0 25 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.4998 6.79998C8.6596 6.79998 9.5998 5.85977 9.5998 4.69998C9.5998 3.54018 8.6596 2.59998 7.4998 2.59998C6.34001 2.59998 5.3998 3.54018 5.3998 4.69998C5.3998 5.85977 6.34001 6.79998 7.4998 6.79998Z"
          fill="#888888"
        />
        <path
          d="M5.0498 12.4001C4.60194 12.4001 4.15407 12.2293 3.81237 11.8875C3.12895 11.2041 3.12895 10.0961 3.81237 9.41267C4.22485 9.00844 5.5398 8.19998 7.4998 8.19998C9.4598 8.19998 10.7748 9.00844 11.1872 9.41267C11.8707 10.0961 11.8707 11.2041 11.1872 11.8875C10.8455 12.2293 10.3977 12.4001 9.9498 12.4001H5.0498Z"
          fill="#888888"
        />
        <path
          d="M17.4998 6.79998C18.6596 6.79998 19.5998 5.85977 19.5998 4.69998C19.5998 3.54018 18.6596 2.59998 17.4998 2.59998C16.34 2.59998 15.3998 3.54018 15.3998 4.69998C15.3998 5.85977 16.34 6.79998 17.4998 6.79998Z"
          fill="#888888"
        />
        <path
          d="M15.0498 12.4001C14.6019 12.4001 14.1541 12.2293 13.8124 11.8875C13.129 11.2041 13.129 10.0961 13.8124 9.41267C14.2248 9.00844 15.5398 8.19998 17.4998 8.19998C19.4598 8.19998 20.7748 9.00844 21.1872 9.41267C21.8707 10.0961 21.8707 11.2041 21.1872 11.8875C20.8455 12.2293 20.3977 12.4001 19.9498 12.4001H15.0498Z"
          fill="#888888"
        />
      </svg>
      <span
        style={{
          color: "rgb(136, 136, 136)",
          fontSize: "12px",
          lineHeight: "12px",
          fontWeight: "400",
        }}
      >
        Base Rate
      </span>
    </span>
  );
};

export const SvgBaseAdultWithoutHeading = () => {
  return (
    <span className="flex flex_direction_col align_items_center flex_gap_5">
      <svg
        width="25"
        height="15"
        viewBox="0 0 25 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.4998 6.79998C8.6596 6.79998 9.5998 5.85977 9.5998 4.69998C9.5998 3.54018 8.6596 2.59998 7.4998 2.59998C6.34001 2.59998 5.3998 3.54018 5.3998 4.69998C5.3998 5.85977 6.34001 6.79998 7.4998 6.79998Z"
          fill="#888888"
        />
        <path
          d="M5.0498 12.4001C4.60194 12.4001 4.15407 12.2293 3.81237 11.8875C3.12895 11.2041 3.12895 10.0961 3.81237 9.41267C4.22485 9.00844 5.5398 8.19998 7.4998 8.19998C9.4598 8.19998 10.7748 9.00844 11.1872 9.41267C11.8707 10.0961 11.8707 11.2041 11.1872 11.8875C10.8455 12.2293 10.3977 12.4001 9.9498 12.4001H5.0498Z"
          fill="#888888"
        />
        <path
          d="M17.4998 6.79998C18.6596 6.79998 19.5998 5.85977 19.5998 4.69998C19.5998 3.54018 18.6596 2.59998 17.4998 2.59998C16.34 2.59998 15.3998 3.54018 15.3998 4.69998C15.3998 5.85977 16.34 6.79998 17.4998 6.79998Z"
          fill="#888888"
        />
        <path
          d="M15.0498 12.4001C14.6019 12.4001 14.1541 12.2293 13.8124 11.8875C13.129 11.2041 13.129 10.0961 13.8124 9.41267C14.2248 9.00844 15.5398 8.19998 17.4998 8.19998C19.4598 8.19998 20.7748 9.00844 21.1872 9.41267C21.8707 10.0961 21.8707 11.2041 21.1872 11.8875C20.8455 12.2293 20.3977 12.4001 19.9498 12.4001H15.0498Z"
          fill="#888888"
        />
      </svg>
    </span>
  );
};

export const SvgExtraAdult = () => (
  <span className="flex flex_direction_col align_items_center flex_gap_5">
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4998 6.29998C8.6596 6.29998 9.5998 5.35977 9.5998 4.19998C9.5998 3.04018 8.6596 2.09998 7.4998 2.09998C6.34001 2.09998 5.3998 3.04018 5.3998 4.19998C5.3998 5.35977 6.34001 6.29998 7.4998 6.29998Z"
        fill="#888888"
      />
      <path
        d="M5.0498 11.9001C4.60194 11.9001 4.15407 11.7293 3.81237 11.3875C3.12895 10.7041 3.12895 9.59609 3.81237 8.91267C4.22485 8.50844 5.5398 7.69998 7.4998 7.69998C9.4598 7.69998 10.7748 8.50844 11.1872 8.91267C11.8707 9.59609 11.8707 10.7041 11.1872 11.3875C10.8455 11.7293 10.3977 11.9001 9.9498 11.9001H5.0498Z"
        fill="#888888"
      />
    </svg>

    <span
      style={{
        color: "rgb(136, 136, 136)",
        fontSize: "12px",
        lineHeight: "12px",
        fontWeight: "400",
      }}
    >
      Extra Adult
    </span>
  </span>
);
export const SvgExtraWithoutHeading = () => (
  <span className="flex flex_direction_col align_items_center flex_gap_5">
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4998 6.29998C8.6596 6.29998 9.5998 5.35977 9.5998 4.19998C9.5998 3.04018 8.6596 2.09998 7.4998 2.09998C6.34001 2.09998 5.3998 3.04018 5.3998 4.19998C5.3998 5.35977 6.34001 6.29998 7.4998 6.29998Z"
        fill="#888888"
      />
      <path
        d="M5.0498 11.9001C4.60194 11.9001 4.15407 11.7293 3.81237 11.3875C3.12895 10.7041 3.12895 9.59609 3.81237 8.91267C4.22485 8.50844 5.5398 7.69998 7.4998 7.69998C9.4598 7.69998 10.7748 8.50844 11.1872 8.91267C11.8707 9.59609 11.8707 10.7041 11.1872 11.3875C10.8455 11.7293 10.3977 11.9001 9.9498 11.9001H5.0498Z"
        fill="#888888"
      />
    </svg>
  </span>
);

export const SvgChild = () => (
  <span className="flex flex_direction_col align_items_center flex_gap_5">
    <svg
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6148_1569)">
        <path
          d="M2.75005 2.25C2.75005 1.78587 2.93442 1.34075 3.26261 1.01256C3.5908 0.684374 4.03592 0.5 4.50005 0.5C4.96417 0.5 5.40929 0.684374 5.73748 1.01256C6.06567 1.34075 6.25005 1.78587 6.25005 2.25C6.25005 2.71413 6.06567 3.15925 5.73748 3.48744C5.40929 3.81563 4.96417 4 4.50005 4C4.03592 4 3.5908 3.81563 3.26261 3.48744C2.93442 3.15925 2.75005 2.71413 2.75005 2.25ZM4.06255 11V13.625C4.06255 14.109 3.67153 14.5 3.18755 14.5C2.70356 14.5 2.31255 14.109 2.31255 13.625V8.36953L1.74106 9.27734C1.48403 9.6875 0.942624 9.80781 0.535202 9.55078C0.12778 9.29375 0.00199857 8.75508 0.25903 8.34766L1.35005 6.6168C2.0309 5.53125 3.22036 4.875 4.50005 4.875C5.77973 4.875 6.96919 5.53125 7.65005 6.61406L8.74106 8.34766C8.99809 8.75781 8.87505 9.29648 8.46762 9.55352C8.0602 9.81055 7.5188 9.6875 7.26176 9.28008L6.68755 8.36953V13.625C6.68755 14.109 6.29653 14.5 5.81255 14.5C5.32856 14.5 4.93755 14.109 4.93755 13.625V11H4.06255Z"
          fill="#888888"
        />
      </g>
      <defs>
        <clipPath id="clip0_6148_1569">
          <rect
            width="8.75"
            height="14"
            fill="white"
            transform="translate(0.125 0.5)"
          />
        </clipPath>
      </defs>
    </svg>

    <span
      style={{
        color: "rgb(136, 136, 136)",
        fontSize: "12px",
        lineHeight: "12px",
        fontWeight: "400",
      }}
    >
      Extra Child
    </span>
  </span>
);

export const SvgChildWithoutHeading = () => (
  <span className="flex flex_direction_col align_items_center flex_gap_5">
    <svg
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6148_1569)">
        <path
          d="M2.75005 2.25C2.75005 1.78587 2.93442 1.34075 3.26261 1.01256C3.5908 0.684374 4.03592 0.5 4.50005 0.5C4.96417 0.5 5.40929 0.684374 5.73748 1.01256C6.06567 1.34075 6.25005 1.78587 6.25005 2.25C6.25005 2.71413 6.06567 3.15925 5.73748 3.48744C5.40929 3.81563 4.96417 4 4.50005 4C4.03592 4 3.5908 3.81563 3.26261 3.48744C2.93442 3.15925 2.75005 2.71413 2.75005 2.25ZM4.06255 11V13.625C4.06255 14.109 3.67153 14.5 3.18755 14.5C2.70356 14.5 2.31255 14.109 2.31255 13.625V8.36953L1.74106 9.27734C1.48403 9.6875 0.942624 9.80781 0.535202 9.55078C0.12778 9.29375 0.00199857 8.75508 0.25903 8.34766L1.35005 6.6168C2.0309 5.53125 3.22036 4.875 4.50005 4.875C5.77973 4.875 6.96919 5.53125 7.65005 6.61406L8.74106 8.34766C8.99809 8.75781 8.87505 9.29648 8.46762 9.55352C8.0602 9.81055 7.5188 9.6875 7.26176 9.28008L6.68755 8.36953V13.625C6.68755 14.109 6.29653 14.5 5.81255 14.5C5.32856 14.5 4.93755 14.109 4.93755 13.625V11H4.06255Z"
          fill="#888888"
        />
      </g>
      <defs>
        <clipPath id="clip0_6148_1569">
          <rect
            width="8.75"
            height="14"
            fill="white"
            transform="translate(0.125 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </span>
);
export const SvgChildWithBed = () => (
  <span className="flex flex_direction_col align_items_center flex_gap_5">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 15 14"
      fill="none"
    >
      <path
        d="M2.25 4.08325V10.4999M2.25 8.16659H12.75M12.75 10.4999V5.83325C12.75 5.52383 12.6271 5.22709 12.4083 5.00829C12.1895 4.7895 11.8928 4.66659 11.5833 4.66659H6.91667V8.16659M4 5.83325C4 5.98796 4.06146 6.13633 4.17085 6.24573C4.28025 6.35513 4.42862 6.41659 4.58333 6.41659C4.73804 6.41659 4.88642 6.35513 4.99581 6.24573C5.10521 6.13633 5.16667 5.98796 5.16667 5.83325C5.16667 5.67854 5.10521 5.53017 4.99581 5.42077C4.88642 5.31138 4.73804 5.24992 4.58333 5.24992C4.42862 5.24992 4.28025 5.31138 4.17085 5.42077C4.06146 5.53017 4 5.67854 4 5.83325Z"
        stroke="#888888"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>

    <span
      style={{
        color: "rgb(136, 136, 136)",
        fontSize: "12px",
        lineHeight: "12px",
        fontWeight: "400",
      }}
    >
      Extra Child
    </span>
  </span>
);

export const SvgChildWithBedWithoutHeading = () => (
  <span className="flex flex_direction_col align_items_center flex_gap_5">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 15 14"
      fill="none"
    >
      <path
        d="M2.25 4.08325V10.4999M2.25 8.16659H12.75M12.75 10.4999V5.83325C12.75 5.52383 12.6271 5.22709 12.4083 5.00829C12.1895 4.7895 11.8928 4.66659 11.5833 4.66659H6.91667V8.16659M4 5.83325C4 5.98796 4.06146 6.13633 4.17085 6.24573C4.28025 6.35513 4.42862 6.41659 4.58333 6.41659C4.73804 6.41659 4.88642 6.35513 4.99581 6.24573C5.10521 6.13633 5.16667 5.98796 5.16667 5.83325C5.16667 5.67854 5.10521 5.53017 4.99581 5.42077C4.88642 5.31138 4.73804 5.24992 4.58333 5.24992C4.42862 5.24992 4.28025 5.31138 4.17085 5.42077C4.06146 5.53017 4 5.67854 4 5.83325Z"
        stroke="#888888"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>

    <span
      style={{
        color: "rgb(136, 136, 136)",
        fontSize: "12px",
        lineHeight: "12px",
        fontWeight: "400",
      }}
    >
      Extra Child
    </span>
  </span>
);

export const SvgPlus = () => (
  <svg
  width="14"
  height="15"
  viewBox="0 0 14 15"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clipPath="url(#clip0_6065_11193)">
    <path
      d="M2.6811 1H11.3189C12.5235 1 13.5 1.97651 13.5 3.1811V11.8189C13.5 13.0235 12.5235 14 11.3189 14H2.6811C1.47651 14 0.5 13.0235 0.5 11.8189V3.1811C0.5 1.97651 1.47651 1 2.6811 1Z"
      stroke="#3968ED"
    />
    <path d="M10.5868 7.5H3.41357" stroke="#3968ED" strokeMiterlimit="10" />
    <path d="M7 3.91344L7 11.0867" stroke="#3968ED" strokeMiterlimit="10" />
  </g>
  <defs>
    <clipPath id="clip0_6065_11193">
      <rect
        width="14"
        height="14"
        fill="white"
        transform="translate(0 0.5)"
      />
    </clipPath>
  </defs>
</svg>
);
export const SvgMinus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <g clipPath="url(#clip0_1270_876)">
      <path
        d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
        stroke="#3968ED"
      ></path>
      <path
        d="M10.5866 7H3.41333"
        stroke="#3968ED"
        strokeMiterlimit="10"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_1270_876">
        <rect width="14" height="14" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
);

export const SvgCancel = ({onClick}) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <mask
      id="mask0_11302_4819"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="14"
      height="14"
    >
      <rect width="14" height="14" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_11302_4819)">
      <path
        d="M3.73366 11.0834L2.91699 10.2667L6.18366 7.00008L2.91699 3.73341L3.73366 2.91675L7.00033 6.18341L10.267 2.91675L11.0837 3.73341L7.81699 7.00008L11.0837 10.2667L10.267 11.0834L7.00033 7.81675L3.73366 11.0834Z"
        fill="white"
      />
    </g>
  </svg>
);