
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ToggleSwtich.module.scss';

const ToggleSwitch = ({ isOn, handleToggle, size }) => {
    return (
        <div className={`${styles.toggleSwitch} ${styles[size]}`}>
            <input
                checked={isOn}
                onChange={handleToggle}
                className={styles.reactSwitchCheckbox}
                id={`react-switch-new`}
                type="checkbox"
            />
            <label
                className={styles.reactSwitchLabel}
                htmlFor={`react-switch-new`}
            >
                <span className={styles.reactSwitchButton} />
            </label>
        </div>
    );
};

ToggleSwitch.propTypes = {
    isOn: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    onColor: PropTypes.string,
};

export default ToggleSwitch;
