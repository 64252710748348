import React, { useEffect, useState } from "react";
import { setActiveApp } from "../../reduxToolkit/Slice/activeAppSlice";
import { useNavigate } from "react-router-dom";
import styles from "./selectApp.module.scss";
import { getActiveApp } from "../../utils/utils";
import Loader from "../UI/Loader/Loader";
import { showErrorToast } from "../../assets/toastUtils";
import { useDispatch } from "react-redux";
import { saveActiveProperty } from "../../reduxToolkit/Slice/propertyslice";

const SelectApp = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const options = [
    {
      name: "PMS",
      value: "pms",
    },
    {
      name: "Channel Manager",
      value: "channel_manager",
    },
    {
      name: "Booking Engine",
      value: "booking_engine",
    },
  ];
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const submitSelectedProperty = async (name) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user-profile?active_app=${name}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success && data.data.properties.length > 0) {
        localStorage.setItem(
          "selectedProperty",
          JSON.stringify(data.data.properties[0])
        );
        dispatch(saveActiveProperty(data.data.properties[0]));
        localStorage.setItem("activeApp", name);
        switch (name) {
          case "pms":
            navigate("/");
            break;
          case "channel_manager":
            navigate("/channel-manager");
            break;
          case "booking_engine":
            navigate("/booking-engine/bookings");
            break;
        }
        window.location.reload();
      } else {
        if (data.data.properties.length === 0) {
          showErrorToast("No Properties Found please contact admin");
        }
        console.error("Failed to update activeApp:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleOptionClick = (e) => {
    const name = e.target.value;
    if (e.target.value === "") {
      return;
    }
    submitSelectedProperty(name);
  };
  const [activeApp, setActiveApp] = useState(getActiveApp());
  useEffect(() => {
    setActiveApp(getActiveApp());
  }, []);
  return (
    <div className={styles.container}>
      <button onClick={toggleDropdown} className={styles.switchAppButton}>
        Switch App
      </button>
      {loading ? (
        <div className={styles.dropdownItemContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {isOpen && (
            <div className={styles.dropdownItemContainer}>
              <div className={"room-type-model-container"}>
                <div class="switch_property_popup_heading">
                  <h1> Switch App </h1>{" "}
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: "pointer" }}
                    onClick={toggleDropdown}
                  >
                    <path
                      d="M1 12.5L13 0.5"
                      stroke="#AAAAAA"
                      stroke-width="1.2"
                    ></path>
                    <path
                      d="M13 12.5L0.999999 0.500001"
                      stroke="#AAAAAA"
                      stroke-width="1.2"
                    ></path>
                  </svg>
                </div>
                <select
                  value={activeApp}
                  className={styles.select}
                  onChange={(e) => {
                    handleOptionClick(e);
                  }}
                >
                  <option value=""> --- select --- </option>
                  {options.map((item, index) => (
                    <option
                      key={index}
                      value={item.value}
                      disabled={item.value === activeApp}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SelectApp;
