import React, { useState } from "react";
import { BgThemeButton } from "../UI/Buttons";
import { Button } from "@shopify/polaris";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { getBaseUrl } from "../../utils/utils";

const InventoryAddChannel = ({
  setShow,
  show,
  fetchInfo,
  roomId,
  propertyId,
}) => {
  const baseURL = getBaseUrl();
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [addChannelData, setAddChannelData] = useState({
    channel_name: "",
    secret_key: "",
    channel_url: "",
    our_api_key: "",
    our_username: "",
    our_password: "",
    channel_username: "",
    channel_password: "",
  });
  const setAddItemDataHandleChange = (event) => {
    setAddChannelData({
      ...addChannelData,
      [event.target.name]: event.target.value,
    });
  };

  const submitChannelData = (e) => {
    if (
      !addChannelData.channel_name ||
      !addChannelData.secret_key ||
      !addChannelData.channel_url ||
      !addChannelData.our_api_key ||
      !addChannelData.our_username ||
      !addChannelData.our_password ||
      !addChannelData.channel_username ||
      !addChannelData.channel_password
    ) {
      showErrorToast("Please fill out all fields before submitting.");
      return;
    }

    fetch(`${baseURL}/api/v1/channel/create`, {
      method: "POST",
      body: JSON.stringify(addChannelData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.success) {
          fetchInfo(propertyId, roomId);
          showSuccessToast("Channel added Successfully");
          setShow(false);
          setAddChannelData({});
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  return (
    <>
      <div className="bg-backdrop">
        <div style={{ width: "700px" }} className="stock-add-model-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <div className="add-ota-model-container-heading">Add Channel</div>
            <div
              onClick={() => [setShow(false), setAddChannelData({})]}
              style={{ cursor: "pointer" }}
            >
              {/* <Icon source={CancelMajor} color="base" /> */}
            </div>
          </div>

          <div className="form-container-div-half">
            <div className="form-container-div">
              <VerticalInputFieldCustom
                required={true}
                titleName="Channel Name"
                name="channel_name"
                type="text"
                value={addChannelData.channel_name}
                onChange={setAddItemDataHandleChange}
              />
            </div>
            <div className="form-container-div">
              <VerticalInputFieldCustom
                required={true}
                titleName="Secret Key"
                name="secret_key"
                type="text"
                value={addChannelData.secret_key}
                onChange={setAddItemDataHandleChange}
              />
            </div>
            <div className="form-container-div">
              <VerticalInputFieldCustom
                required={true}
                titleName="URL"
                name="channel_url"
                type="text"
                value={addChannelData.channel_url}
                onChange={setAddItemDataHandleChange}
              />
            </div>
            <div className="form-container-div">
              <VerticalInputFieldCustom
                required={true}
                titleName="Our Username"
                name="our_username"
                type="text"
                value={addChannelData.our_username}
                onChange={setAddItemDataHandleChange}
              />
            </div>
          </div>

          <div className="form-container-div-half">
            <div className="form-container-div">
              <VerticalInputFieldCustom
                required={true}
                titleName="Our API key"
                name="our_api_key"
                type="text"
                value={addChannelData.our_api_key}
                onChange={setAddItemDataHandleChange}
              />
            </div>
            <div className="form-container-div">
              <VerticalInputFieldCustom
                required={true}
                titleName="Our Password"
                name="our_password"
                type="text"
                value={addChannelData.our_password}
                onChange={setAddItemDataHandleChange}
              />
            </div>
            <div className="form-container-div">
              <VerticalInputFieldCustom
                required={true}
                titleName="Channel Username"
                name="channel_username"
                type="text"
                value={addChannelData.channel_username}
                onChange={setAddItemDataHandleChange}
              />
            </div>
            <div className="form-container-div">
              <VerticalInputFieldCustom
                required={true}
                titleName="Channel Password"
                name="channel_password"
                type="text"
                value={addChannelData.channel_password}
                onChange={setAddItemDataHandleChange}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <BgThemeButton
                onClick={(e) => submitChannelData(e)}
                type="submit"
                children={"Add"}
              />
              <Button onClick={() => [setShow(false), setAddChannelData({})]}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryAddChannel;
