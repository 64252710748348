import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import Loader from "./UI/Loader/Loader";

import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import { useSelector } from "react-redux";
import { Button, Icon } from "@shopify/polaris";
import styles from "./styles/inventory/inventoryCommon.module.scss";

import { CancelMajor } from "@shopify/polaris-icons";
import {
  other_select_nav_items_channel_inventory,
  rooms_inner_lists,
} from "../assets/constant";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import NoDataFound from "./CustomComponents/NoDataFound";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import { getActiveApp, getBaseUrl } from "../utils/utils";
import {
  SelectBasic,
  SelectMultiple,
} from "./NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import InventoryDatePicker from "./CustomComponents/InventorySliderComponents/InventoryDatePicker";
import ConfirmPopup from "./NewCustomComp/confirmPopup/ConfirmPopup";
import InventoryUnblockChannelManager from "./CustomComponents/InventorySliderComponents/InventoryUnblockChannelManager";
import InventoryBulkBlockChannelManager from "./CustomComponents/InventorySliderComponents/InventoryBulkBlockChannelManager";
import InventoryRatesUpdate from "./CustomComponents/InventorySliderComponents/InventoryRatesUpdate";
import InventoryUpdate from "./CustomComponents/InventorySliderComponents/InventoryUpdate";
import StayRestrictions from "./CustomComponents/InventorySliderComponents/StayRestrictions";
import SliderComponentSkeleton from "./NewCustomComp/SliderComponentSkelon/SliderComponentSkeleton";
import {
  SvgLock,
  SvgUnLock,
  SvgExtraAdult,
  SvgChild,
  SvgChildWithBed,
  SvgBaseAdult,
  SvgPlus,
  SvgMinus,
  SvgCancel,
} from "../assets/svgIcons";

const PmsChannelsInventory = () => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const [purchaseHeadingActive, setPurchaseHeadingActive] = useState("");
  const [uniqueChannelId, setUniqueChannelId] = useState(null);

  const setPurchaseHeading = (idx) => {
    setUniqueChannelId(idx);
    if (purchaseHeadingActive === idx) {
      setPurchaseHeadingActive("active");
    } else {
      setPurchaseHeadingActive("");
    }
  };

  const getChannelData = (idx) => {
    setUniqueChannelId(idx);
    if (purchaseHeadingActive === idx) {
      setPurchaseHeadingActive("active");
    } else {
      setPurchaseHeadingActive("");
    }
  };
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [propertyUniqueId, setPropertyUniqueId] = useState("");
  const selectedPropertyUniqueId = useSelector((state) => state.property.value);

  const property_id = useSelector((state) => state.property.value);
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const [selectedDate, setSelectedDate] = useState(formattedDate);

  const dateClickHandler = (date) => {
    {
      setBulkInventoryData({
        ...bulkInventoryData,
        from_date: date,
      });
    }

    setSelectedDate(date);

    fetchInfoWithDate(property_id.id, date, uniqueChannelId);
  };

  const [doNotShowCategories, setDoNotShowCategories] = useState([]);

  const fetchInfo = async (propertyId, id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/${id}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const fetchChannelInfo = async (channelId, propertyId, index) => {
    setUniqueChannelId(channelId);
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/${channelId}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const fetchInfoWithDate = async (propertyId, date, channelId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/${channelId}/${propertyId}?date=${date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data);
      setDoNotShowCategories(data.data.rooms.map((room) => room.id));
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id && uniqueChannelId !== null) {
      fetchInfo(property_id?.id, uniqueChannelId);
    }
  }, [property_id, uniqueChannelId]);

  setTimeout(() => {
    setLoader(false);
  }, 3000);

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const [activeIndexes, setActiveIndexes] = useState([]);

  const [expandedItems, setExpandedItems] = useState([]);

  const [innerInnerClose, setInnerInnerClose] = useState(false);

  const togglePlanExpand = (rateIndex) => {
    if (expandedItems.includes(rateIndex)) {
      setExpandedItems(expandedItems.filter((i) => i !== rateIndex));
      setInnerInnerClose(true);
    } else {
      setExpandedItems([...expandedItems, rateIndex]);
      setInnerInnerClose(false);
    }
  };

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
  const [blockShowAddModelMenuActive, setBlockShowAddModelMenuActive] =
    useState(false);
  const [unblockShowAddModelMenuActive, setUnblockShowAddModelMenuActive] =
    useState(false);

  const [holdShowAddModelMenuActive, setHoldShowAddModelMenuActive] =
    useState(false);

  const [unholdShowAddModelMenuActive, setUnholdShowAddModelMenuActive] =
    useState(false);

  const [
    inventoriesOptionShowAddModelMenuActive,
    setInventoriesOptionShowAddModelMenuActive,
  ] = useState(false);

  const [bulkInventoryData, setBulkInventoryData] = useState({
    property_id: property_id?.id,
    from_date: "",
    to_date: "",
  });

  const [blockInventoryData, setBlockInventoryData] = useState({
    property_id: property_id?.id,
    from_date: "",
    to_date: "",
  });

  const [unblockInventoryData, setUnblockInventoryData] = useState({
    property_id: property_id?.id,
    from_date: "",
    to_date: "",
    // available_rooms: "",
  });

  const [holdInventoryData, setHoldInventoryData] = useState({
    property_id: property_id?.id,
    from_date: "",
    to_date: "",
  });

  const [selectedValues, setSelectedValues] = useState([]);

  // HOLD
  const [selectedRooms, setSelectedRooms] = useState({});
  const [holdInputValues, setHoldInputValues] = useState([]);
  const [bulkInputValues, setBulkInputValues] = useState([]);
  const [error, setError] = useState("");

  const holdHandleCheckboxChange = async (e, room) => {
    const roomId = room.id;
    const isChecked = e.target.checked;
    if (holdInventoryData.from_date === "") {
      showErrorToast("Please fill the above dates.");
      return;
    }

    if (isChecked) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/edit/${roomId}/${holdInventoryData.from_date}/${uniqueChannelId}/${property_id?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const availableRooms = data.available_rooms;

          setHoldInputValues((prevRoomData) => [
            ...prevRoomData,
            {
              property_id: property_id?.id,
              from_date: selectedDate, // Set the selected date here
              to_date: "",
              available_rooms: availableRooms,
              room_id: roomId,
              hold_room: "",
            },
          ]);
        } else {
          // Handle error if the fetch request is not successful
          console.error("Failed to fetch available rooms data");
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    } else {
      setHoldInputValues((prevRoomData) =>
        prevRoomData.filter((data) => data.room_id !== roomId)
      );
    }
  };

  const bulkHandleCheckboxChange = async (e, room) => {
    const roomId = room.id;
    const isChecked = e.target.checked;

    if (bulkInventoryData.from_date === "") {
      showErrorToast("Please fill the above dates.");
      return;
    }
    if (isChecked) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/edit/${roomId}/${bulkInventoryData.from_date}/${uniqueChannelId}/${property_id?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const availableRooms = data.available_rooms;

          setBulkInputValues((prevRoomData) => [
            ...prevRoomData,
            {
              from_date: selectedDate,
              to_date: "",
              available_rooms: availableRooms,
              room_id: roomId,
              inventory: "",
            },
          ]);
        } else {
          showErrorToast("Failed to fetch available rooms data");
        }
      } catch (error) {
        showErrorToast("An error occurred while fetching data:");
      }
    } else {
      setBulkInputValues((prevRoomData) =>
        prevRoomData.filter((data) => data.room_id !== roomId)
      );
    }
  };

  const handleHoldInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRoomData = [...holdInputValues];

    // Find the index of the current room data
    const roomDataIndex = updatedRoomData.findIndex(
      (data) => data.room_id === holdInputValues[index].room_id
    );

    if (!isNaN(value)) {
      if (
        name === "hold_room" &&
        parseInt(value) >= holdInputValues[index].available_rooms
      ) {
        showErrorToast("Hold Rooms should be less then Available Rooms.");
        return;
      }

      updatedRoomData[roomDataIndex][name] = value;
      setHoldInputValues(updatedRoomData);
    } else {
      showErrorToast("Please enter a valid number.");
    }
  };

  // new handle change
  const handleBulkInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRoomData = [...bulkInputValues];

    const roomDataIndex = updatedRoomData.findIndex(
      (data) => data.room_id === bulkInputValues[index].room_id
    );

    if (!isNaN(value)) {
      if (
        name === "inventory" &&
        parseInt(value) >= bulkInputValues[index].available_rooms
      ) {
        showErrorToast("Inventory Rooms should be less then Available Rooms.");
        return;
      }
      updatedRoomData[roomDataIndex][name] = value;
      setBulkInputValues(updatedRoomData);
    } else {
      showErrorToast("Please enter a valid number.");
    }
  };

  // Function to handle checkbox changes
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Check if the value is not already in the array before adding it
      if (!selectedValues.includes(value)) {
        setSelectedValues([...selectedValues, value]);
        addCheckbox();
      }
    } else {
      // Remove the value from the array if it exists
      setSelectedValues(selectedValues.filter((item) => item !== value));
    }
  };

  const [checkboxOptions, setCheckboxOptions] = useState([]);

  const addCheckbox = () => {
    const newCheckbox = {
      id: Date.now(),
      label: `Option ${checkboxOptions.length + 1}`,
      value: `option${checkboxOptions.length + 1}`,
    };

    setCheckboxOptions([...checkboxOptions, newCheckbox]);
  };

  const setBulkInventoryHandleChange = (event) => {
    setBulkInventoryData({
      ...bulkInventoryData,
      property_id: property_id.id,
      [event.target.name]: event.target.value,
    });
  };

  const setBlockInventoryHandleChange = (event) => {
    setBlockInventoryData({
      ...blockInventoryData,
      property_id: property_id.id,
      [event.target.name]: event.target.value,
    });
  };

  const setUnblockInventoryHandleChange = (event) => {
    setUnblockInventoryData({
      ...unblockInventoryData,
      property_id: property_id.id,
      [event.target.name]: event.target.value,
    });
  };

  const setHoldInventoryHandleChange = (event) => {
    setHoldInventoryData({
      ...holdInventoryData,
      property_id: property_id.id,
      [event.target.name]: event.target.value,
    });
  };

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const blockAddModalButtonClickHandler = () => {
    setBlockShowAddModelMenuActive(!blockShowAddModelMenuActive);
  };

  const unblockAddModalButtonClickHandler = () => {
    setUnblockShowAddModelMenuActive(!unblockShowAddModelMenuActive);
  };

  const holdAddModalButtonClickHandler = () => {
    setHoldShowAddModelMenuActive(!holdShowAddModelMenuActive);
  };

  const unholdAddModalButtonClickHandler = () => {
    setUnholdShowAddModelMenuActive(!unholdShowAddModelMenuActive);
  };

  const bulkInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);

      const response = await fetch(
        // /api/v1/inventories/store/1
        `${process.env.REACT_APP_BASE_URL}/api/v1/inventories/store/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...bulkInventoryData,
            room_categories: selectedValues,
          }),
        }
      );
      if (response.ok) {
        addModalButtonClickHandler();

        // Parse the JSON response
        const jsonResponse = await response.json();

        window.location.reload();
        // Reset the bulkInventoryData state
        setBulkInventoryData({
          property_id: property_id.id,
          from_date: "",
          available_rooms: "",
          update_type: "",
          room_price: "",
        });
        setLoader(false);
        // Reload the page
      } else {
        console.log("Post request failed with status:", response.status);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Ensure the loader is set to false in case of an error
      setLoader(false);
    }
  };
  const bulkInventoryDataUpdateHandleNew = async (e) => {
    e.preventDefault();

    try {
      setLoader(true);

      const response = await fetch(
        // /api/v1/inventories/store/1
        `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/store/${uniqueChannelId}/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...bulkInventoryData,
            room_categories: bulkInputValues,
          }),
        }
      );
      if (response.ok) {
        addModalButtonClickHandler();

        // Parse the JSON response
        const jsonResponse = await response.json();

        showSuccessToast(data.message);
        window.location.reload();
        // fetchInfo(property_id?.id, uniqueChannelId);
        // setInventoriesOptionShowAddModelMenuActive(false)
        // Reset the bulkInventoryData state
        setBulkInventoryData({
          property_id: property_id.id,
          from_date: "",
          available_rooms: "",
          update_type: "",
          room_price: "",
        });
        setLoader(false);
      } else {
        console.log("Post request failed with status:", response.status);
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred");
      // Ensure the loader is set to false in case of an error
      setLoader(false);
    }
  };
  const blockInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();

    setLoader(true);

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/block/${uniqueChannelId}/${property_id?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          ...blockInventoryData,
          room_categories: selectedValues,
        }),
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.message) {
          showSuccessToast(data.message);
          addModalButtonClickHandler();
          setBlockShowAddModelMenuActive(false);
          setShowAddModelMenuActive(false);
          setLoader(false);
          fetchInfo(property_id?.id, uniqueChannelId);
          setInventoriesOptionShowAddModelMenuActive(false);
        } else {
          showErrorToast(data.message);
          console.log("Post request failed with status:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const unblockInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();

    setLoader(true);

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/unblock/${uniqueChannelId}/${property_id?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          ...unblockInventoryData,
          room_categories: selectedValues,
        }),
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.message) {
          showSuccessToast(data.message);
          addModalButtonClickHandler();
          setBlockShowAddModelMenuActive(false);
          setShowAddModelMenuActive(false);
          setUnblockShowAddModelMenuActive(false);
          setLoader(false);
          fetchInfo(property_id?.id, uniqueChannelId);
          setInventoriesOptionShowAddModelMenuActive(false);
        } else {
          showErrorToast(data.message);
          console.log("Post request failed with status:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const holdInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();

    setLoader(true);
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/hold/${uniqueChannelId}/${property_id?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          ...holdInventoryData,
          room_categories: holdInputValues,
        }),
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          addModalButtonClickHandler();
          setBlockShowAddModelMenuActive(false);
          setShowAddModelMenuActive(false);
          setUnblockShowAddModelMenuActive(false);
          setLoader(false);
          fetchInfo(property_id?.id, uniqueChannelId);
          setInventoriesOptionShowAddModelMenuActive(false);
        } else {
          showErrorToast(data.message);
          console.log("Post request failed with status:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const unholdInventoryDataUpdateHandle = async (e) => {
    e.preventDefault();

    setLoader(true);

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/channel/inventories/unhold/${uniqueChannelId}/${property_id?.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          ...blockInventoryData,
          room_categories: selectedValues,
        }),
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          addModalButtonClickHandler();
          setBlockShowAddModelMenuActive(false);
          setShowAddModelMenuActive(false);
          setUnblockShowAddModelMenuActive(false);
          setLoader(false);
          setInventoriesOptionShowAddModelMenuActive(false);
          fetchInfo(property_id?.id, uniqueChannelId);
        } else {
          showErrorToast(data.message);
          console.log("Post request failed with status:", response.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const [selectedDatePickerDate, setSelectedDatePickerDate] =
    useState(formattedDate);

  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const monthMonth = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so we add 1
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${monthMonth}-${day}`;
  };

  const CustomInput = ({ onClick }) => (
    <div onClick={onClick} className="dateRangePicker">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clipPath="url(#clip0_1270_814)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.625 2H11.75V1.25C11.75 0.835786 11.4142 0.5 11 0.5C10.5858 0.5 10.25 0.835786 10.25 1.25V2H5V1.25C5 0.835786 4.66421 0.5 4.25 0.5C3.83579 0.5 3.5 0.835786 3.5 1.25V2H2.375C1.775 2 1.25 2.525 1.25 3.125V14.375C1.25 14.975 1.775 15.5 2.375 15.5H13.625C14.225 15.5 14.75 14.975 14.75 14.375V3.125C14.75 2.525 14.225 2 13.625 2ZM2.75 14H13.25V6.5H2.75V14Z"
            fill="#888888"
          />
        </g>
        <defs>
          <clipPath id="clip0_1270_814">
            <rect
              width="15"
              height="15"
              fill="white"
              transform="translate(0.5 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );

  const handleDateChange = (date) => {
    setSelectedDatePickerDate(formatDate(date));
    fetchInfoWithDate(property_id.id, formatDate(date), uniqueChannelId);
  };

  const handleIncrementDate = () => {
    if (selectedDatePickerDate) {
      const newDate = new Date(selectedDatePickerDate);
      newDate.setDate(newDate.getDate() + 1);
      setSelectedDatePickerDate(formatDate(newDate));
      fetchInfoWithDate(property_id.id, formatDate(newDate), uniqueChannelId);
    }
  };

  const handleDecrementDate = () => {
    if (selectedDatePickerDate) {
      const newDate = new Date(selectedDatePickerDate);
      newDate.setDate(newDate.getDate() - 1);
      setSelectedDatePickerDate(formatDate(newDate));
      fetchInfoWithDate(property_id.id, formatDate(newDate), uniqueChannelId);
    }
  };

  const toggleRoomAccordion = (roomIndex) => {
    if (activeIndexes.includes(roomIndex)) {
      setActiveIndexes(activeIndexes.filter((i) => i !== roomIndex));
    } else {
      // Room is closed, so open it
      setActiveIndexes([...activeIndexes, roomIndex]);
    }
  };

  const [activeInputRateIndex, setActiveInputRateIndex] = useState(null);
  const [activeFeildName, setActiveFeildName] = useState(null);

  const [inputValuesRates, setInputValuesRates] = useState([]);
  const [inputValuesInventory, setInputValuesInventory] = useState([]);

  const [sendInputPostRateData, setSendInputPostRateData] = useState(null);

  const [sendInputPostInventoryData, setSendInputPostInventoryData] =
    useState(null);

  const handleInputRatesOnChange = (
    rateId,
    value,
    fullObject,
    fieldName,
    index
  ) => {
    setActiveFeildName(fieldName);

    setSendInputPostRateData({
      // ...sendInputPostRateData,
      [fieldName]: value,
      date: fullObject.date,
      rate_plan_type: fullObject.rate_plan_type,
      room_id: fullObject.room_id,
      rate_plan_id: fullObject.mealplan_id,
    });

    const updatedInputValues = [...inputValuesRates];

    updatedInputValues[index] = {
      ...updatedInputValues[index],
      [fieldName]: value,
    };
    setInputValuesRates(updatedInputValues);
    setActiveInputRateIndex(rateId);
  };

  const [activeInputInventoryIndex, setActiveInputInventoryIndex] =
    useState(null);
  const [activeInputInventoryRoomId, setActiveInputInventoryRoomId] =
    useState(null);

  const handleInputInventoryOnChange = (event, fullObject, index, room_id) => {
    setActiveInputInventoryRoomId(room_id);
    setSendInputPostInventoryData({
      ...sendInputPostInventoryData,
      available_rooms: event.target.value,
      date: fullObject.date,
      room_id: fullObject.room_id,
    });

    const updatedInputValues = [...inputValuesInventory];

    updatedInputValues[index] = {
      ...updatedInputValues[index],
      available_rooms: event.target.value,
    };

    setInputValuesInventory(updatedInputValues);

    setActiveInputInventoryIndex(index);
  };

  const handleButtonRemove = () => {
    if (activeInputRateIndex !== null) {
      // const newInputData = [...inputData];
      // newInputData[activeInput] = "";
      // setInputData(newInputData);
      setActiveInputRateIndex(null);
    }
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const [{ mont, yea }, setDate] = useState({ month: 1, year: 2018 });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date("Wed Feb 07 2018 00:00:00 GMT-0500 (EST)"),
    end: new Date("Sat Feb 10 2018 00:00:00 GMT-0500 (EST)"),
  });

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  const [activeTab, setActiveTab] = useState(5);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const navigate = useNavigate();

  const handleUpdateRatesSingleSaveHandler = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/set/channel/rates/${uniqueChannelId}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...sendInputPostRateData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          // fetchInfo(property_id?.id, uniqueChannelId);
          window.location.reload();
        } else if (data.error) {
          const firstErrorKey = Object.keys(data.error)[0];
          const firstErrorMessage = data.error[firstErrorKey][0];
          showErrorToast(firstErrorMessage);
          showErrorToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      });
  };

  const handleUpdateInventoryHandler = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/set/channel/inventory/${uniqueChannelId}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...sendInputPostInventoryData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          window.location.reload();
        } else if (data.error) {
          showErrorToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      });
  };

  const [channelsArray, setChannelsArray] = useState([]);
  useEffect(() => {
    if (property_id) {
      fetchChannelArray(property_id?.id);
    }
  }, [property_id]);

  const fetchChannelArray = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/mapped/channels/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setChannelsArray(data.data);
      setUniqueChannelId(data?.data[0]?.id || "");
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };
  const activeURL = getBaseUrl();

  const controlsBulk = [
    "Inventory",
    "Rates",
    "Block",
    "Unblock",
    // "Drip Feed",
    // "Stay Restrictions",
  ];

  const handleBulkControlChange = (data) => {
    switch (data) {
      case "Stay Restrictions": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }
      case "Inventory": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }
      case "Rates": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }
      case "Block": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }
      case "Unblock": {
        setShowBookingSlider(true);
        setSliderHeading(data);
        break;
      }
      default:
        break;
    }
  };

  function getMonthDay(dateString) {
    const date = new Date(dateString);
    const day = date.getDate(); // Get the day (e.g., 23)
    const month = date
      .toLocaleString("default", { month: "short" })
      .toUpperCase(); // Get the month (e.g., AUG)

    return `${day}<br/> ${month}`;
  }

  const activeApp = getActiveApp();
  const [hoveredId, setHoveredId] = useState(null);

  const [showConfirmPopupUnblock, setShowConfirmPopupUnblock] = useState(false);
  const [deleteWithReasonData, setDeleteWithReasonData] = useState("");
  const [showConfirmPopupBlock, setShowConfirmPopupBlock] = useState(false);

  // const xyz =
  // console.log(xyz ,"xyz")

  const handleConfirmBlockInventory = async () => {
    const foundChannel = channelsArray.find(
      (item) => item.id === uniqueChannelId
    );
    const foundChannelArray = foundChannel
      ? [
          {
            channel_id: foundChannel.id,
            channel_name: foundChannel.channel_name,
          },
        ]
      : [];
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/update/inventory/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            from_date: deleteWithReasonData.childData.date,
            to_date: deleteWithReasonData.childData.date,
            status: "Close",
            channels: foundChannelArray,

            inventory_data: [
              {
                room_id: deleteWithReasonData?.childData.room_id,
                available_rooms:
                  deleteWithReasonData?.childData.available_rooms,
              },
            ],
            sun: "1",
            mon: "1",
            tue: "1",
            wed: "1",
            thr: "1",
            fri: "1",
            sat: "1",
          }),
        }
      );

      const data = await response.json();

      if (response.ok && data.success) {
        fetchInfo(property_id?.id, uniqueChannelId);
      } else {
        showErrorToast(data.message || "An error occurred.");
        console.log("Post request failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    } finally {
      setShowConfirmPopupUnblock(false);
      setShowConfirmPopupBlock(false);
    }
  };
  const handleConfirmUnBlockInventory = async () => {
    const foundChannel = channelsArray.find(
      (item) => item.id === uniqueChannelId
    );
    const foundChannelArray = foundChannel
      ? [
          {
            channel_id: foundChannel.id,
            channel_name: foundChannel.channel_name,
          },
        ]
      : [];
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/update/inventory/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            from_date: deleteWithReasonData.childData.date,
            to_date: deleteWithReasonData.childData.date,
            status: "Open",
            channels: foundChannelArray,
            inventory_data: [
              {
                room_id: deleteWithReasonData?.childData.room_id,
                available_rooms:
                  deleteWithReasonData?.childData.available_rooms,
              },
            ],
            sun: "1",
            mon: "1",
            tue: "1",
            wed: "1",
            thr: "1",
            fri: "1",
            sat: "1",
          }),
        }
      );

      const data = await response.json();

      if (response.ok && data.success) {
        showSuccessToast(data.message);
        fetchInfo(property_id?.id, uniqueChannelId);
      } else {
        showErrorToast(data.message || "An error occurred.");
        console.log("Post request failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    } finally {
      setShowConfirmPopupUnblock(false);
      setShowConfirmPopupBlock(false);
    }
  };

  //    code for nested table

  const [expandedState, setExpandedState] = useState({});

  // Toggle expanded state for a specific room + rateType
  const toggleExpanded = (roomIndex, rateType) => {
    const key = `${roomIndex}-${rateType}`; // Unique key for room + rateType

    setExpandedState((prevState) => ({
      ...prevState,
      [key]: !prevState[key], // Toggle the expanded state for the specific rateType
    }));
  };
  const handleInputChange = (roomIndex, rateType, date, field, value) => {
    // Clone data
    const updatedData = { ...data };

    // Update the specific rate
    if (updatedData.rooms[roomIndex].rates[rateType][date]) {
      updatedData.rooms[roomIndex].rates[rateType][date][0][field] = value;
    }

    // Update the data state
    setData(updatedData);

    // Track changes in inputs
    const key = `${roomIndex}-${rateType}-${date}-${field}`;
    const originalValue = originalData[key];
    setInputChanges((prevChanges) => ({
      ...prevChanges,
      [key]: value !== originalValue, // true if the value is different from the original
    }));
  };

  const [originalData, setOriginalData] = useState({});
  const [inputChanges, setInputChanges] = useState({});

  const baseUrl = getBaseUrl();
  const handleInputSaveButtonClick = async (
    rateData,
    date,
    channelName,
    channelId
  ) => {
    const foundChannel = channelsArray.find(
      (item) => item.id === uniqueChannelId
    );
    const foundChannelArray = foundChannel
      ? [
          {
            channel_id: foundChannel.id,
            channel_name: foundChannel.channel_name,
          },
        ]
      : [];
    const postData = {
      from_date: date,
      to_date: date,
      rates_data: rateData.map((rate) => {
        return {
          base_rate: rate.rate_bar_price,
          extra_adult: rate.extra_adult,
          extra_child_with_bed: rate.child_with_Bed,
          extra_child_without_bed: rate.child_without_Bed,
          meal_plan_id: rate.rate_plan_id,
          room_id: rate.room_id,
        };
      }),

      channels: foundChannelArray,

      sun: 1,
      mon: 1,
      tue: 1,
      wed: 1,
      thr: 1,
      fri: 1,
      sat: 1,
    };

    // console.log("post data", postData);

    try {
      setLoader(true);

      const response = await fetch(
        `${baseUrl}/api/v1/update/rates/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(postData),
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);

        setInputChanges({});
        fetchInfo(property_id?.id, uniqueChannelId);
      } else {
        showErrorToast(data.message || "Failed to update stay restrictions.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred while updating stay restrictions.");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const originalValues = {};
    if (data.rooms && data.rooms.length > 0) {
      data.rooms.forEach((room, roomIndex) => {
        Object.keys(room.rates).forEach((rateType) => {
          data.date_range.forEach((date) => {
            if (room.rates[rateType][date]) {
              const rate = room.rates[rateType][date][0];
              originalValues[
                `${roomIndex}-${rateType}-${date}-rate_bar_price`
              ] = rate.rate_bar_price;
              originalValues[`${roomIndex}-${rateType}-${date}-extra_adult`] =
                rate.extra_adult;
              originalValues[
                `${roomIndex}-${rateType}-${date}-child_with_Bed`
              ] = rate.child_with_Bed;
              originalValues[
                `${roomIndex}-${rateType}-${date}-child_without_Bed`
              ] = rate.child_without_Bed;
            }
          });
        });
      });
    }
    setOriginalData(originalValues);
  }, [data]);

  const handleShowHideRoomClick = (id) => {
    if (doNotShowCategories.includes(id)) {
      setDoNotShowCategories(doNotShowCategories.filter((item) => item !== id));
    } else {
      setDoNotShowCategories([...doNotShowCategories, id]);
    }
  };

  //=============== slider  ========

  const [showBookingSlider, setShowBookingSlider] = useState(false);
  const [sliderHeading, setSliderHeading] = useState("");

  const fetchRooms = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/v1/rooms/${property_id.id}`);

      const data = await response.json().then((data) => {
        if (data.success) {
          setCategories(data.rooms);
          setSelectedRoom(data.rooms[0]);
          setLoader(false);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [categories, setCategories] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState({});

  useEffect(() => {
    if (property_id) {
      fetchRooms();
    }
  }, [property_id]);

  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({});

  // controls handlers

  const [selectedControl, setSelectedControl] = useState([]);
  const handleStopCellCancel = (data) => {
    setSelectedControl(selectedControl.filter((item) => item !== data));
  };

  const controls = ["Stop Sell"];

  const handleControlChange = (value) => {
    setSelectedControl(value);
    const postControls = { is_stop_sell_active: 0, is_drip_feed_active: 0 };

    value.map((item) => {
      if (item === "Stop Cell") {
        postControls.is_stop_sell_active = 1;
      } else if (item === "Drip Feed") {
        postControls.is_drip_feed_active = 1;
      }
    });
  };

  const handleStopCellChange = (room, e, outerIndex, innerIndex) => {
    const { name, value } = e.target;
    if (parseInt(value) < 0 || parseInt(value) > 1) {
      showErrorToast("Please enter a value between 0 and 1");
      return;
    }

    setData((prev) => {
      return {
        ...prev,
        rooms: prev.rooms.map((item, oIndex) => {
          if (oIndex === outerIndex) {
            // Match the outerIndex (room)
            return {
              ...item,
              inventory: item.inventory.map((inventoryItem, iIndex) => {
                if (iIndex === innerIndex) {
                  // Match the innerIndex (inventory)
                  return {
                    ...inventoryItem,
                    [name]: value, // Update the specific property
                  };
                }
                return inventoryItem;
              }),
            };
          }
          return item;
        }),
      };
    });
  };

  const handleStopCellSave = async (data, stopSell) => {
    const channel = {
      channel_id: uniqueChannelId,
      channel_name: channelsArray.find((item) => item.id === uniqueChannelId)
        ?.channel_name,
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/update/inventory/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            from_date: stopSell.date,
            to_date: stopSell.date,
            stop_sell: stopSell.stop_sell,
            channels: [channel],
            inventory_data: [stopSell],
            sun: "1",
            mon: "1",
            tue: "1",
            wed: "1",
            thr: "1",
            fri: "1",
            sat: "1",
          }),
        }
      );

      const data = await response.json();

      if (response.ok && data.success) {
        fetchInfo(property_id?.id, selectedRoom.id, "channel_view");
      } else {
        showErrorToast(data.message || "An error occurred.");
        console.log("Post request failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    } finally {
      setShowConfirmPopupUnblock(false);
      setShowConfirmPopupBlock(false);
    }
  };

  return (
    <>
      <SidebarInnerLists
        innerLists={rooms_inner_lists}
        selectAtLast={true}
        other_select_nav_items_inventory={
          other_select_nav_items_channel_inventory
        }
        firstSelected={"Channel Inventory"}
        firstSelectedPermission={"read_channel_inventory"}
        firstSelectedLink={"/channels"}
      />
      {loader ? (
        <Loader />
      ) : (
        <>
          {/* /// =======================  new code =======================// */}

          {data && data.date_range && data.date_range.length > 0 ? (
            <div className="room-types-full-container">
              <div className="room-types-full-container-div">
                <div className="room-type-heading">Inventory</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div></div>
                </div>
              </div>

              <div className="inventory-full-container">
                <div
                  style={{
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {channelsArray &&
                    channelsArray.map((channel, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            fetchChannelInfo(channel.id, property_id.id, index)
                          }
                          className={`booking-first-row-head-divs ${
                            uniqueChannelId === channel.id ? "active" : ""
                          }`}
                        >
                          {channel.channel_name}
                        </div>
                      );
                    })}
                  <div className="flex  flex_gap_10 align_items_center">
                    <div style={{ minWidth: "150px", maxWidth: "200px" }}></div>
                  </div>
                  <div className="flex flex_gap_10">
                    <div style={{ width: "150px" }}>
                      <SelectMultiple
                        options={controls}
                        size={"medium"}
                        handleSelectTagChange={handleControlChange}
                        placeholder={"Controls"}
                        defaultSelected={selectedControl}
                      />
                    </div>
                    <div style={{ width: "150px" }}>
                      <SelectBasic
                        options={controlsBulk}
                        size={"medium"}
                        handleSelectTagChange={handleBulkControlChange}
                        placeholder={"Bulk Updates"}
                      />
                    </div>
                    <InventoryDatePicker
                      selectedDatePickerDate={selectedDatePickerDate}
                      handleDateChange={handleDateChange}
                      handleDecrementDate={handleDecrementDate}
                      handleIncrementDate={handleIncrementDate}
                    />
                  </div>
                </div>

                <div className="inventory-second-column-2div">
                  <div
                    style={{
                      borderRadius: "6px",
                    }}
                  ></div>
                </div>

                <table
                  style={{ width: "100%" }}
                  className={styles.tableInventory}
                >
                  <thead>
                    <tr>
                      <th className={styles.longHeader}>Inventory & Rates</th>
                      <th
                        style={{ border: "none" }}
                        className={styles.dateHeaders}
                      ></th>
                      {data.date_range?.map((date, index) => (
                        <th key={index} className={styles.dateHeaders}>
                          <div className="flex justify_content_center align_items_center">
                            <span
                              style={
                                index === 0
                                  ? {
                                      backgroundColor: "#3968ed",
                                      color: "white",
                                    }
                                  : {}
                              }
                              dangerouslySetInnerHTML={{
                                __html: getMonthDay(date),
                              }}
                              onClick={() => dateClickHandler(date)}
                            />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.rooms?.map((room, roomIndex) => (
                      <tr key={roomIndex}>
                        <td colSpan={data.date_range.length + 2}>
                          <table
                            style={{
                              width: "100%",
                              border: "1px solid #ccc",
                            }}
                            className={`${styles.innerTable} ${styles.InventoryRatesInnerTable}`}
                          >
                            <tbody>
                              <tr>
                                <td className={styles.longHeader}>
                                  <div className="flex flex_gap_10 align_items_center">
                                    <span
                                      onClick={() =>
                                        handleShowHideRoomClick(room.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {" "}
                                      {doNotShowCategories.includes(room.id)
                                        ? SvgMinus()
                                        : SvgPlus()}
                                    </span>
                                    <p className={styles.rateType}>
                                      {" "}
                                      {room.room_name}
                                    </p>
                                  </div>
                                </td>

                                <td
                                  style={{ border: "none" }}
                                  className={styles.innerTableData}
                                />

                                {room.inventory
                                  ?.slice(0, 15)
                                  .map((childData, index) => (
                                    <td
                                      key={index}
                                      className={styles.innerTableData}
                                    >
                                      <div className="flex justify_content_center align_items_center">
                                        {childData?.blocked ? (
                                          <span
                                            className={`flex flex_direction_col align_items_center  ${styles["flex-container"]}`}
                                          >
                                            <input
                                              disabled={true}
                                              defaultValue={"00"}
                                              className="inventory-1st-column-inner-input"
                                              style={{
                                                background: "#fb04044d",
                                              }}
                                              type="number"
                                            />
                                            {childData?.sold_rooms} Sold /{" "}
                                            {childData?.hold_rooms} Hold
                                            <span
                                              className={
                                                styles["hover-icon-visible"]
                                              }
                                              onMouseOver={() =>
                                                setHoveredId(index)
                                              }
                                              onMouseOut={() =>
                                                setHoveredId(null)
                                              }
                                            >
                                              {hoveredId === index ? (
                                                <SvgUnLock
                                                  onClick={() => {
                                                    setShowConfirmPopupUnblock(
                                                      true
                                                    );
                                                    setDeleteWithReasonData({
                                                      childData: childData,
                                                      roomData: room,
                                                    });
                                                  }}
                                                />
                                              ) : (
                                                <SvgLock />
                                              )}
                                              <span
                                                className={styles["tooltip"]}
                                              >
                                                <b>Blocked-By</b>{" "}
                                                {childData.blocked_by}
                                              </span>
                                            </span>
                                          </span>
                                        ) : (
                                          <span
                                            className={`flex flex_direction_col align_items_center  ${styles["flex-container"]}`}
                                          >
                                            <input
                                              disabled={true}
                                              defaultValue={
                                                childData.available_rooms
                                              }
                                              className="inventory-1st-column-inner-input"
                                              type="number"
                                            />
                                            {childData?.sold_rooms} Sold /{" "}
                                            {childData?.hold_rooms} Hold
                                            <span
                                              className={styles["hover-icon"]}
                                            >
                                              <SvgLock
                                                onClick={() => {
                                                  setShowConfirmPopupBlock(
                                                    true
                                                  );
                                                  setDeleteWithReasonData({
                                                    childData: childData,
                                                    roomData: room,
                                                  });
                                                }}
                                              />
                                            </span>
                                          </span>
                                        )}
                                      </div>
                                    </td>
                                  ))}
                              </tr>
                              {
                                // expand collapse room category conditionally
                                doNotShowCategories.includes(room.id) ? (
                                  <>
                                    {Object.keys(room.rates).map(
                                      (rateType, index) => {
                                        const key = `${roomIndex}-${rateType}`;
                                        return (
                                          <React.Fragment key={index}>
                                            <tr>
                                              <td
                                                className={`${styles.longHeader} ${styles.addBorderTop}`}
                                              >
                                                <div className="flex flex_gap_10 align_items_center">
                                                  <button
                                                    onClick={() =>
                                                      toggleExpanded(
                                                        roomIndex,
                                                        rateType
                                                      )
                                                    }
                                                  >
                                                    {expandedState[key]
                                                      ? SvgMinus()
                                                      : SvgPlus()}
                                                  </button>
                                                  <p
                                                    className={styles.rateType}
                                                  >
                                                    {rateType}
                                                  </p>
                                                </div>
                                              </td>
                                              <td
                                                className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                              >
                                                <SvgBaseAdult />
                                              </td>
                                              {data.date_range.map((date) => (
                                                <td
                                                  key={date}
                                                  className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                                >
                                                  <span
                                                    className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                  >
                                                    <input
                                                      type="number"
                                                      value={
                                                        room.rates[rateType][
                                                          date
                                                        ]?.[0]
                                                          ?.rate_bar_price ||
                                                        "0"
                                                      }
                                                      className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                      onChange={(e) =>
                                                        handleInputChange(
                                                          roomIndex,
                                                          rateType,
                                                          date,
                                                          "rate_bar_price",
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                    {inputChanges[
                                                      `${roomIndex}-${rateType}-${date}-rate_bar_price`
                                                    ] && (
                                                      <button
                                                        onClick={() =>
                                                          handleInputSaveButtonClick(
                                                            room.rates[
                                                              rateType
                                                            ][date],
                                                            date,
                                                            room.channel_name,
                                                            room.channel_id
                                                          )
                                                        }
                                                      >
                                                        Save
                                                      </button>
                                                    )}
                                                  </span>
                                                </td>
                                              ))}
                                            </tr>

                                            {/* Only show when expanded */}
                                            {expandedState[key] && (
                                              <>
                                                <tr>
                                                  <td />
                                                  <td
                                                    className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                                  >
                                                    <SvgExtraAdult />
                                                  </td>
                                                  {data.date_range.map(
                                                    (date) => (
                                                      <td
                                                        key={date}
                                                        className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                                      >
                                                        <span
                                                          className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                        >
                                                          <input
                                                            type="number"
                                                            value={
                                                              room.rates[
                                                                rateType
                                                              ][date]?.[0]
                                                                ?.extra_adult ||
                                                              "0"
                                                            }
                                                            className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                            onChange={(e) =>
                                                              handleInputChange(
                                                                roomIndex,
                                                                rateType,
                                                                date,
                                                                "extra_adult",
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                          {inputChanges[
                                                            `${roomIndex}-${rateType}-${date}-extra_adult`
                                                          ] && (
                                                            <button
                                                              onClick={() =>
                                                                handleInputSaveButtonClick(
                                                                  room.rates[
                                                                    rateType
                                                                  ][date],
                                                                  date,
                                                                  room.channel_name,
                                                                  room.channel_id
                                                                )
                                                              }
                                                            >
                                                              Save
                                                            </button>
                                                          )}
                                                        </span>
                                                      </td>
                                                    )
                                                  )}
                                                </tr>

                                                <tr>
                                                  <td />
                                                  <td
                                                    className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                                  >
                                                    <SvgChild />
                                                  </td>
                                                  {data.date_range.map(
                                                    (date) => (
                                                      <td
                                                        key={date}
                                                        className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                                      >
                                                        <span
                                                          className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                        >
                                                          <input
                                                            type="number"
                                                            value={
                                                              room.rates[
                                                                rateType
                                                              ][date]?.[0]
                                                                ?.child_with_Bed ||
                                                              "0"
                                                            }
                                                            className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                            onChange={(e) =>
                                                              handleInputChange(
                                                                roomIndex,
                                                                rateType,
                                                                date,
                                                                "child_with_Bed",
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                          {inputChanges[
                                                            `${roomIndex}-${rateType}-${date}-child_with_Bed`
                                                          ] && (
                                                            <button
                                                              onClick={() =>
                                                                handleInputSaveButtonClick(
                                                                  room.rates[
                                                                    rateType
                                                                  ][date],
                                                                  date,
                                                                  room.channel_name,
                                                                  room.channel_id
                                                                )
                                                              }
                                                            >
                                                              Save
                                                            </button>
                                                          )}
                                                        </span>
                                                      </td>
                                                    )
                                                  )}
                                                </tr>

                                                <tr>
                                                  <td />
                                                  <td
                                                    className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                                  >
                                                    <SvgChildWithBed />
                                                  </td>
                                                  {data.date_range.map(
                                                    (date) => (
                                                      <td
                                                        key={date}
                                                        className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                                      >
                                                        <span
                                                          className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                        >
                                                          <input
                                                            type="number"
                                                            value={
                                                              room.rates[
                                                                rateType
                                                              ][date]?.[0]
                                                                ?.child_without_Bed ||
                                                              "0"
                                                            }
                                                            className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                            onChange={(e) =>
                                                              handleInputChange(
                                                                roomIndex,
                                                                rateType,
                                                                date,
                                                                "child_without_Bed",
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                          {inputChanges[
                                                            `${roomIndex}-${rateType}-${date}-child_without_Bed`
                                                          ] && (
                                                            <button
                                                              onClick={() =>
                                                                handleInputSaveButtonClick(
                                                                  room.rates[
                                                                    rateType
                                                                  ][date],
                                                                  date,
                                                                  room.channel_name,
                                                                  room.channel_id
                                                                )
                                                              }
                                                            >
                                                              Save
                                                            </button>
                                                          )}
                                                        </span>
                                                      </td>
                                                    )
                                                  )}
                                                </tr>
                                              </>
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </>
                                ) : null
                              }

                              {
                                // ============ Stop Sell ==============
                                selectedControl.includes("Stop Sell") && (
                                  <tr>
                                    <td
                                      className={`${styles.innerLongHeader} ${styles.stopSellTableData}`}
                                      colSpan={2}
                                    >
                                      <span>
                                        {" "}
                                        <SvgCancel
                                          onClick={() =>
                                            handleStopCellCancel("Stop Sell")
                                          }
                                        />{" "}
                                        Stop Sell
                                      </span>
                                    </td>

                                    {room.inventory.map((stopSell, index) => (
                                      <>
                                        <td
                                          className={`${styles.innerTableData} ${styles.stopSellTableDataInputSmall}`}
                                          key={stopSell - index}
                                        >
                                          <span
                                            className={`flex flex_direction_col align_items_center  ${styles["flex-container"]}`}
                                          >
                                            <input
                                              className="inventory-1st-column-inner-input"
                                              type="number"
                                              value={stopSell.stop_sell || 0}
                                              name="stop_sell"
                                              onChange={(e) =>
                                                handleStopCellChange(
                                                  room,
                                                  e,
                                                  roomIndex,
                                                  index
                                                )
                                              }
                                              min={0}
                                              max={1}
                                            />
                                            <button
                                              onClick={() =>
                                                handleStopCellSave(
                                                  room,
                                                  stopSell
                                                )
                                              }
                                            >
                                              save
                                            </button>
                                          </span>
                                        </td>
                                      </>
                                    ))}
                                  </tr>
                                )
                              }
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <NoDataFound />
          )}

          {/* ==============new code ================ */}

          {showConfirmPopupBlock && (
            <ConfirmPopup
              setShowPopup={setShowConfirmPopupBlock}
              showPopup={showConfirmPopupBlock}
              heading={"Are you sure?"}
              subHeading={"You Want to Block?"}
              handleConfirmClick={handleConfirmBlockInventory}
            />
          )}
          {showConfirmPopupUnblock && (
            <ConfirmPopup
              setShowPopup={setShowConfirmPopupUnblock}
              showPopup={showConfirmPopupUnblock}
              heading={"Are you sure?"}
              subHeading={"You Want to Unblock?"}
              handleConfirmClick={handleConfirmUnBlockInventory}
            />
          )}

          {/* ============slider================= */}

          {showBookingSlider && (
            <SliderComponentSkeleton
              show={showBookingSlider}
              setShow={setShowBookingSlider}
              title={`Bulk Update: (${sliderHeading})`}
            >
              {sliderHeading === "Stay Restrictions" && (
                <StayRestrictions
                  categories={categories}
                  currentDate={currentDate}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  baseUrl={baseUrl}
                  property_id={property_id}
                  userToken={userToken}
                  setLoader={setLoader}
                  channels={channels}
                  setShow={setShowBookingSlider}
                />
              )}

              {sliderHeading === "Inventory" && (
                <InventoryUpdate
                  categories={categories}
                  currentDate={currentDate}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  baseUrl={baseUrl}
                  property_id={property_id}
                  userToken={userToken}
                  setLoader={setLoader}
                  channels={channelsArray}
                  setShow={setShowBookingSlider}
                  disabledSelectChannelOptions={true}
                  defaultSelectedChannel={channelsArray.filter(
                    (item) => item.id === uniqueChannelId
                  )}
                />
              )}

              {sliderHeading === "Rates" && (
                <InventoryRatesUpdate
                  categories={categories}
                  currentDate={currentDate}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  baseUrl={baseUrl}
                  property_id={property_id}
                  userToken={userToken}
                  setLoader={setLoader}
                  channels={channelsArray}
                  setShow={setShowBookingSlider}
                  disabledSelectChannelOptions={true}
                  defaultSelectedChannel={channelsArray.filter(
                    (item) => item.id === uniqueChannelId
                  )}
                />
              )}

              {sliderHeading === "Block" && (
                <>
                  <InventoryBulkBlockChannelManager
                    categories={categories}
                    currentDate={currentDate}
                    selectedValues={selectedValues}
                    setSelectedValues={setSelectedValues}
                    baseUrl={baseUrl}
                    property_id={property_id}
                    userToken={userToken}
                    setLoader={setLoader}
                    channels={channelsArray}
                    setShow={setShowBookingSlider}
                    permissions={permissions}
                    disabledSelectChannelOptions={true}
                    defaultSelectedChannel={channelsArray.filter(
                      (item) => item.id === uniqueChannelId
                    )}
                  />
                </>
              )}

              {sliderHeading === "Unblock" && (
                <>
                  <InventoryUnblockChannelManager
                    categories={categories}
                    currentDate={currentDate}
                    selectedValues={selectedValues}
                    setSelectedValues={setSelectedValues}
                    baseUrl={baseUrl}
                    property_id={property_id}
                    userToken={userToken}
                    setLoader={setLoader}
                    channels={channelsArray}
                    setShow={setShowBookingSlider}
                    permissions={permissions}
                    fetchInfo={fetchInfo}
                    disabledSelectChannelOptions={true}
                    defaultSelectedChannel={channelsArray.filter(
                      (item) => item.id === uniqueChannelId
                    )}
                  />
                </>
              )}
            </SliderComponentSkeleton>
          )}
        </>
      )}
    </>
  );
};

export default PmsChannelsInventory;
