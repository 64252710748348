import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../../../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import NoDataFound from "../../../Components/CustomComponents/NoDataFound";
import VerticalInputFieldCustom from "../../../Components/CustomComponents/VerticalInputFieldCustom";
import {
  BgOutlineBlackButton,
  BgThemeButton,
} from "../../../Components/UI/Buttons";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { formatIndianCurrency } from "../../../utils/utils";
import Loader from "../../../Components/UI/Loader/Loader";

const AdvancePayments = () => {
  const [loader, setLoader] = useState(true);
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);
  const { ID } = useParams();
  const [agentData, setAgentData] = useState([]);

  const fetchAgent = async () => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/show/agent/advance/payment/${property_id.id}?agent_information_id=${ID}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const data = await response.json();

      setAgentData(data.data);
    } catch (error) {
      console.error("Fetch agent data failed", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id?.id) {
      fetchAgent();
    }
  }, [property_id, ID]);

  const [editAgentData, setEditAgentData] = useState("");

  const fetchSingleAgentData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/agent/advance/payment/${id}/${property_id.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const data = await response.json();
     
      setEditAgentData(data.data);
    } catch (error) {
      console.error("Fetch agent data failed", error);
    }
  };

  const handleAgentEdit = (data) => {
    fetchSingleAgentData(data?.id);
  };

  const rowMarkup = agentData?.map((data) => (
    <CustomShopifyIndexTableRow style={{ borderBottom: "1px solid #ccc" }}>
      <CustomShopifyIndexTableCell>
        {formatIndianCurrency(data.advance_amount)}
      </CustomShopifyIndexTableCell>
      <CustomShopifyIndexTableCell>
        {formatIndianCurrency(data.balance_amount)}
      </CustomShopifyIndexTableCell>
      <CustomShopifyIndexTableCell>
        {formatIndianCurrency(data.used_amount)}
      </CustomShopifyIndexTableCell>
      <CustomShopifyIndexTableCell>
        <button
          style={{
            cursor: "pointer",
            border: "none",
            background: "transparent",
          }}
          onClick={() => handleAgentEdit(data)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M11.3919 1.98174L14.0182 4.60884L14.4562 4.1707C15.1813 3.44536 15.1813 2.26952 14.4562 1.54418C13.7308 0.818608 12.5547 0.818608 11.8294 1.54418L11.3919 1.98174Z"
              fill="#3968ED"
            ></path>
            <path
              d="M12.8124 5.81229L10.1861 3.18518L1.85249 11.5201L1 15L4.47873 14.1472L12.8124 5.81229Z"
              fill="#3968ED"
            ></path>
          </svg>
        </button>
      </CustomShopifyIndexTableCell>
    </CustomShopifyIndexTableRow>
  ));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditAgentData((prevData) => {
      const newValue = {
        ...prevData,
        [name]: value,
      };
      if (name === "used_amount" || name === "advance_amount") {
        newValue.balance_amount =
          newValue.advance_amount - newValue.used_amount;
      }
      return newValue;
    });
  };

  const validateAmounts = () => {
    return editAgentData.used_amount <= editAgentData.advance_amount;
  };

  const handleUpdate = async () => {
    if (!validateAmounts()) {
      showErrorToast("Used amount should not be greater than advance amount.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/update/agent/advance/payment/${editAgentData.id}/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            agent_information_id: editAgentData.agent_information_id,
            advance_amount: editAgentData.advance_amount,
            used_amount: editAgentData.used_amount,
            balance_amount: editAgentData.balance_amount,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data.data, "data");
      setEditAgentData("");

      fetchAgent();
    } catch (error) {
      console.error("Update agent data failed", error);
    }
  };
  const [showCreateAgent, setShowCreateAgent] = useState(false);
  const handleCreateAgentClick = () => {
    setShowCreateAgent(true);
  };
  //==========================================
  const [paymentData, setPaymentData] = useState({
    advanceAmount: "",
    usedAmount: "",
    balanceAmount: "",
  });

  const handleAdvanceChange = (event) => {
    const { name, value } = event.target;
    setPaymentData((prevData) => ({
      ...prevData,
      [name]: value,
      usedAmount: 0,
      balanceAmount: value,
    }));
  };

  const handleUsedAmountChange = (event) => {
    const { name, value } = event.target;
    if (Number(value) > Number(paymentData.advanceAmount)) {
      alert("Used Amount should not exceed Advance Amount");
      return;
    }
    setPaymentData((prevData) => ({
      ...prevData,
      [name]: value,
      balanceAmount: prevData.advanceAmount - value,
    }));
  };

  const handleSubmit = async () => {
    if (!paymentData.advanceAmount) {
      alert("Advance Amount is required");
      return;
    }
 
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create/agent/advance/payment/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            agent_information_id: ID,
            advance_amount: paymentData.advanceAmount,
            used_amount: paymentData.usedAmount,
            balance_amount: paymentData.balanceAmount,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const data = await response.json();
      showSuccessToast(data.message);
      setPaymentData({
        advanceAmount: "",
        usedAmount: "",
        balanceAmount: "",
      });
      setShowCreateAgent(false);
      fetchAgent();
    } catch (error) {
      showErrorToast("Error submitting data:", error);
      console.error("Error submitting data:", error);
    }
  };

  return (
    <div>
      <div>
        <BgThemeButton
          onClick={handleCreateAgentClick}
          style={{ float: "right", margin: "0 0 10px 0" }}
        >
          Create Agent
        </BgThemeButton>
      </div>

      {loader ? (
        <Loader />
      ) : (
        <>
          {agentData?.length > 0 ? (
            <div>
              <CustomShopifyIndexTable
                style={{ border: "1px solid #d6d6d6" }}
                headings={[
                  "Advance Amount",
                  "Balance Amount",
                  "Used Amount",
                  "",
                ]}
              >
                {rowMarkup}
              </CustomShopifyIndexTable>
            </div>
          ) : (
            <NoDataFound />
          )}
        </>
      )}

      {editAgentData && (
        <div className="global_popup_container_background">
          <div className="global_popup_container">
            <h2 className="addstaff_heading">Edit Advance Payment</h2>
            <div className="flex flex_gap_10">
              <div style={{ width: "50%" }}>
                <VerticalInputFieldCustom
                  titleName={"Advance Amount"}
                  value={editAgentData.advance_amount}
                  onChange={handleInputChange}
                  name="advance_amount"
                />
              </div>
              <div style={{ width: "50%" }}>
                <VerticalInputFieldCustom
                  titleName={"Used Amount"}
                  value={editAgentData.used_amount}
                  onChange={handleInputChange}
                  name="used_amount"
                />
              </div>
            </div>

            <div style={{ width: "50%", marginTop: "10px" }}>
              <VerticalInputFieldCustom
                titleName={"Balance Amount"}
                value={editAgentData.balance_amount}
                disabled={true}
                name="balance_amount"
              />
            </div>

            <div
              className="flex flex_gap_10"
              style={{ width: "50%", marginTop: "15px" }}
            >
              <BgThemeButton onClick={handleUpdate}>Update</BgThemeButton>
              <BgOutlineBlackButton onClick={() => setEditAgentData(null)}>
                Cancel
              </BgOutlineBlackButton>
            </div>
          </div>
        </div>
      )}

      {showCreateAgent && (
        <div className="global_popup_container_background">
          <div className="global_popup_container" style={{ width: "400px" }}>
            <h4 className="addstaff_heading">Add Advance Payments</h4>
            <div className="flex flex_gap_10" style={{ margin: "10px 0" }}>
              <div style={{ width: "100%" }}>
                <VerticalInputFieldCustom
                  titleName="Advance Amount"
                  name="advanceAmount"
                  value={paymentData.advanceAmount}
                  onChange={handleAdvanceChange}
                />
              </div>
              {/* <div style={{ width: "50%" }}>
                <VerticalInputFieldCustom
                  titleName="Used Amount"
                  name="usedAmount"
                  value={paymentData.usedAmount}
                  onChange={handleUsedAmountChange}
                  disabled={!paymentData.advanceAmount}
                />
              </div> */}
            </div>
            {/* <div style={{ width: "50%" }}>
              <VerticalInputFieldCustom
                titleName="Balance Amount"
                name="balanceAmount"
                value={paymentData.balanceAmount}
                disabled={true}
              />
            </div> */}
            <div className="flex flex_gap_10" style={{ marginTop: "10px" }}>
              <BgThemeButton onClick={handleSubmit}>Submit</BgThemeButton>
              <BgOutlineBlackButton onClick={() => setShowCreateAgent(false)}>
                Cancel
              </BgOutlineBlackButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvancePayments;
