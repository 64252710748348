import React, { useState } from "react";
import styles from "./multipleImageUploadComponent.module.scss";
import { FaPlus, FaCancelRounder } from "./icons/uploadIcons";

const MultipleImageUploadComponent = ({
  onFileChange = (data, name) => {
    console.log(data, name);
  }, // callback to send data to the parent
}) => {
  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    const uploadedFiles = Array.from(e.target.files); // Get all selected files
    const newFiles = [...files]; // Copy the current state

    uploadedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileData = { src: reader.result, name: file.name }; // Store file data (src and name)
        newFiles.push(fileData); // Push each file to the array
        setFiles(newFiles); // Update state with new files
        onFileChange && onFileChange(reader.result, file.name); // Send each file and name to the parent
      };
      reader.readAsDataURL(file); // Read each file as data URL
    });
  };

  const handleDelete = (index) => {
    const newFiles = files.filter((_, i) => i !== index); // Remove the image at the specified index
    setFiles(newFiles); // Update state
  };

  return (
    <>
      <div className={styles.container}>
        {files.length === 0 ? (
          <div className="flex flex_direction_col align_items_center ">
            <div className={styles.uploadContainer}>
              <label htmlFor="upload" className={styles.uploadLabel}>
                <FaPlus className={styles.icon} />
                <p>Upload</p>
                <input
                  id="upload"
                  type="file"
                  accept={"image/jpeg, image/png"}
                  multiple // Enable multiple file selection
                  onChange={handleFileChange}
                  className={styles.uploadInput}
                />
              </label>
            </div>
            <p
              className={styles.uploadingText}
            >{`or drop JPG, PNG file here`}</p>
          </div>
        ) : (
          <div className={styles.previewContainer}>
            {files.map((file, index) => (
              <div key={index} className={styles.previewItem}>
               <div>
               <img src={file.src} alt={file.name} className={styles.previewImage} />
               
                <button
                  onClick={() => handleDelete(index)}
                  className={styles.deleteButton}
                >
                  <FaCancelRounder />
                </button>
               </div>
               <p className={styles.fileName}>{file.name}</p> {/* Display file name below the image */}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default MultipleImageUploadComponent;