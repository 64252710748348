// import React, { useState, useEffect } from "react";
// import "./DashboardCompetitorAnalysis.css";
// import CompetitorsAfter from "../../CompetitorsAfter";
import { useSelector } from "react-redux";
// import Loader from "../Loader/Loader";
import LoaderSmall from "../Loader/LoaderSmall";
// import TextDateFormatter from "../../Communication/TextDateFormatter/TextDateFormatter";
import { Tooltip } from "@shopify/polaris";

import React, { useState, useEffect } from "react";
import "./DashboardCompetitorAnalysis.css";
import TextDateFormatter from "../../Communication/TextDateFormatter/TextDateFormatter";
import { Link } from "react-router-dom";

const CustomCompetitorsFrontDesk = ({ orderLimit, minRowCount }) => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [data, setData] = useState(null);
  const [compNewData, setCompNewData] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);

  useEffect(() => {
    const fetchData = async (propertyId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/competitors/rates/${propertyId}?date=${currentDate}`
        );
        const data = await response.json();
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    const fetchSingleData = async (propertyId) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/competitors/thirty/days/rates/${propertyId}`
        );
        const data = await response.json();
        setCompNewData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (property_id) {
      fetchData(property_id?.id);
      fetchSingleData(property_id?.id);
    }
  }, [property_id]);

  if (loading) {
    return <LoaderSmall />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const uniqueDates = [
    ...new Set(data?.property_rates.map((item) => item.date)),
  ].slice(0, 7);

  const arrayFromOrderLimit = Array.from(
    { length: orderLimit },
    (_, index) => index + 1
  );

  //for empty rows
  const competitorData = data?.competitors;
  const minimumEmptyRows = minRowCount;
  const emptyCompetitors = Array.from(
    { length: minimumEmptyRows - competitorData?.length },
    (_, index) => ({ id: `empty_${index}`, competitor_name: "", rates: [] }) //check spellings if any errors occurs in api
  );

  return (
    <div
      style={{ marginTop: "20px", marginBottom: "20px" }}
      className="DashboardCompetitorAnalysisTwoContainer"
    >
      <div
        style={{
          display: "flex",
          padding: "20px",
          width: "100%",
          justifyContent: "space-between",
          borderBottom: "0.5px solid #CCCCCC",
        }}
      >
        <div
          style={{
            flexBasis: "30%",
            padding: "15px",
            textAlign: "center",
            background: "#FFEAEA",
            borderRadius: "4px",
          }}
        >
          <div style={{ fontSize: "16px" }}>
            <strong>
              ₹{Math.round(compNewData && compNewData?.competitorAvgRates)}
            </strong>
          </div>
          <div style={{ fontSize: "14px" }}>Competitor Average Rate</div>
        </div>

        <div
          style={{
            flexBasis: "30%",
            padding: "15px",
            textAlign: "center",
            background: "#DFF8DF",
            borderRadius: "4px",
          }}
        >
          <div style={{ fontSize: "16px" }}>
            <strong>
              ₹{Math.round(compNewData && compNewData?.propertyBaseRates)}
            </strong>
          </div>
          <div style={{ fontSize: "14px" }}>Our Average Rate</div>
        </div>

        <div
          style={{
            flexBasis: "30%",
            padding: "15px",
            textAlign: "center",
            background: "#DFF8DF",
            borderRadius: "4px",
          }}
        >
          <div style={{ fontSize: "16px" }}>
            <strong>
              ₹{Math.round(compNewData && compNewData?.propertyAvgRates)}
            </strong>
          </div>
          <div style={{ fontSize: "14px" }}>MMT Average Rate</div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="DashboardCompetitorAnalysisTwo-heading">
          Our Competitors
        </div>
        {/* <div className="DashboardCompetitorAnalysisTwo-heading">
          Comp. Avg Rate : 4500
        </div> */}
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "15px",
          padding: "20px",
          paddingTop: "0px",
          fontSize: "14px",
        }}
      >
        {competitorData && competitorData.length > 0 ? (
          <>
            {competitorData.map((comp, index) => {
              return (
                <div>
                  <span style={{ marginRight: "5px" }}>{index + 1}.</span>
                  {comp.competitor_name}
                </div>
              );
            })}
          </>
        ) : (
          <div>No Competitors</div>
        )}
      </div>
    </div>
  );
};

export default CustomCompetitorsFrontDesk;
