import React, { useState, useEffect } from "react";
import SelectDatePickerCustom from "../SelectDateRange/SelectDatePickerCustom";
import DaySelectionWeekDays from "../../daySelectionWeekDays/DaySelectionWeekDays";
import { Button } from "@shopify/polaris";
import { BgThemeButton } from "../../UI/Buttons";
import SelectDateRange from "../SelectDateRange/SelectDateRange";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import styles from "./inventorySliderComponents.module.scss";

const StayRestrictions = ({
  categories,
  currentDate,
  selectedValues,
  setSelectedValues,
  addCheckbox,
  baseUrl,
  property_id,
  userToken,
  setLoader,
  channels,
  setShow,
}) => {
  const [dates, setDates] = useState({ from_date: "", to_date: "" });
  const [formState, setFormState] = useState({
    closeToArrival: 0,
    closeToDeparture: 0,
  });

  // Handler for checkbox changes
  const handleRestrictionsChange = (e) => {
    const { name, checked } = e.target;
    setFormState({
      ...formState,
      [name]: checked ? 1 : 0,
    });
  };
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Check if the value is not already in the array before adding it
      if (!selectedValues.includes(value)) {
        setSelectedValues([...selectedValues, value]);
        addCheckbox();
      }
    } else {
      // Remove the value from the array if it exists
      setSelectedValues(selectedValues.filter((item) => item !== value));
    }
  };

  // chanel listing aip

  const [selectedChannel, setSelectedChannel] = useState([]);

  const handleCheckboxChangeChannel = (event) => {
    const { value, checked } = event.target;
    const id = parseInt(value, 10); // Convert to number if necessary
    const channel_name = event.target.dataset.name; // Get the name from data attribute

    setSelectedChannel((prevSelectedChannels) => {
      if (checked) {
        // If checked, add to the array
        return [...prevSelectedChannels, { id, channel_name }];
      } else {
        // If unchecked, remove from the array
        return prevSelectedChannels.filter((channel) => channel.id !== id);
      }
    });
  };

  const updateStayRestrictions = async () => {
    if (!dates?.from_date || !dates?.to_date) {
      showErrorToast("Please select the date range.");
      return;
    }

    if (!selectedValues || selectedValues.length === 0) {
      showErrorToast("Please select at least one room category.");
      return;
    }

    if (!(formState.closeToArrival || formState.closeToDeparture)) {
      showErrorToast("Please select at least one stay restriction.");
      return;
    }

    if (!selectedChannel || selectedChannel.length === 0) {
      showErrorToast("Please select at least one channel.");
      return;
    }

    const postData = {
      property_id: property_id.id,
      from_date: dates.from_date,
      to_date: dates.to_date,
      room_categories: selectedValues, //array
      closedToArrival: formState.closeToArrival ? 1 : 0,
      closedToDeparture: formState.closeToDeparture ? 1 : 0,
      channels: selectedChannel, // array
    };

    try {
      setLoader(true);

      const response = await fetch(
        `${baseUrl}/api/v1/update/closetoarrival/closetodeparture/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(postData),
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        // setInventoriesOptionShowAddModelMenuActive(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      //   showErrorToast(data.message);
    } finally {
      setLoader(false);
    }
  };

  const handleCloseBookingSlider = () => {
    setShow(false);
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  };
  return (
    <>
      <div className={styles.container}>
        <div style={{ width: "100%" }}>
          <div style={{ paddingBottom: "5px" }}>Select Date Range</div>
          <SelectDateRange
            dataFromChild={(from_date, to_date) => {
              setDates({
                from_date: from_date,
                to_date: to_date,
              });
            }}
            minDate={new Date(currentDate)}
          />
        </div>
        <hr />
        <div>
          <DaySelectionWeekDays onSaveDates={() => {}} />
        </div>

        <hr />
        <div className="flex flex_gap_15">
          <label className="flex flex_gap_5">
            <input
              type="checkbox"
              name="closeToArrival"
              value={formState.closeToArrival}
              checked={formState.closeToArrival === 1}
              onChange={handleRestrictionsChange}
            />
            CTA
          </label>
          <label className="flex flex_gap_5">
            <input
              type="checkbox"
              name="closeToDeparture"
              value={formState.closeToDeparture}
              checked={formState.closeToDeparture === 1}
              onChange={handleRestrictionsChange}
            />
            CTD
          </label>
        </div>

        <hr />

        <div>
          <div
            style={{
              color: "#333",
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "10px",
            }}
          >
            Select Room Categories
          </div>

          <div
            style={{
              display: "flex",
              gap: "10px",
              color: "#333",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "400",
              flexDirection: "column",
            }}
          >
            {categories.map((room) => {
              return (
                <div>
                  <input
                    type="checkbox"
                    name="update_type"
                    onChange={handleCheckboxChange}
                    value={room.id}
                    style={{ marginRight: "10px" }}
                  />
                  {room.room_name}
                </div>
              );
            })}
          </div>
        </div>

        <hr />

        <div>
          <div>
            <div
              style={{
                color: "#333",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Select Channels
            </div>

            <div
              style={{
                display: "flex",
                gap: "10px",
                color: "#333",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
                flexDirection: "column",
              }}
            >
              {channels &&
                channels?.length > 0 &&
                channels?.map((room) => (
                  <div key={room.channel_id}>
                    <input
                      type="checkbox"
                      value={room.channel_id}
                      data-name={room.channel_name} // Store the channel name as data attribute
                      onChange={handleCheckboxChangeChannel}
                      style={{ marginRight: "10px" }}
                    />
                    {room.channel_name}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`flex align_items_center flex_gap_20 ${styles.buttonContainer}`}
      >
        <BgThemeButton
          type="submit"
          onClick={updateStayRestrictions}
          children={"Update"}
        />

        <Button onClick={handleCloseBookingSlider}>Cancel</Button>
      </div>
    </>
  );
};

export default StayRestrictions;
