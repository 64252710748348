import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-quill/dist/quill.snow.css";
import "@shopify/polaris/build/esm/styles.css";
import Layout from "./Pages/Layout";
import Login from "./Components/Auth/Login";
import Signup from "./Components/Auth/Signup";
// import SettingsPage from "./Pages/SettingsPage";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import FetchInfoHOC from "./reduxToolkit/FetchInfoHOC";

import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import PreviewBillCopy from "./Components/PreviewBillSendWhatsapp";
import PreviewVoucher from "./Components/PreviewVoucher/PreviewVoucher";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import NoPropertyPage from "./Pages/NoPropertyPage";
import WebCheckIn from "./Components/WebCheckIn/WebCheckIn";
import CustomChatBot from "./Components/CustomChatbot/CustomChatBot";
import { useSelector } from "react-redux";
import SingleRoomGallery from "./Components/SingleRoomGallery/SingleRoomGallery";
import SelectProperty from "./Components/BookingEngine/SelectProperty";

function App() {

  useEffect(() => {
    // Set the meta title
    document.title = "RevCatalyst - Boosting Your Hotel Performance";

    // Create or update the meta description tag
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);

  const ProtectedRoute = ({ element }) => {
    const authenticated = localStorage.getItem("authenticated");
    const userToken = localStorage.getItem("userToken");
    return authenticated && userToken !== "undefined" ? (
      element
    ) : (
      <Navigate to="/login" replace />
    );
  };

  const authenticated = useSelector(
    (state) => state.isAuthenticated.authenticated
  );

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/webcheckin" element={<WebCheckIn />} />
          <Route path="/login" exact element={<Login />} />
          
          <Route path="/password-reset" element={<ForgotPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/singleroom/gallery/:roomId/:propertyId/:roomName" element={<SingleRoomGallery />} />

          <Route path="/previewbillwhatsapp" element={<PreviewBillCopy />} />
          <Route path="/voucherbillwhatsapp" element={<PreviewVoucher />} />
         
         
          
          <Route path="*" element={<ProtectedRoute element={<Layout />} />} />
        </Routes>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme="light"
        />
      </BrowserRouter>
    </div>
  );
}

export default App;
