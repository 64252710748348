import React, { useEffect, useRef, useState } from "react";
import "./AgentHoldRoomsSliderSideBar.css";
import { BgOutlineThemeButton, BgThemeButton } from "../../UI/Buttons";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DateRangePickerNew from "../CreateBookingNewPage/DateRangePickerNew/DateRangePickerNew";
import { formatDateYYYYMMDD } from "../../../utils/utils";

import { showErrorToast } from "../../../assets/toastUtils";
import styles from "./agentSliderMoreOptions.module.scss";


const AgentContractRoomsSidebarContent = ({
  singleAgentData,
  isOpen,
  toggleSidebar,
}) => {
  const navigate = useNavigate();

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const property_id = useSelector((state) => state.property.value);
  const [roomCategories, setRoomCategories] = useState([]);

  const fetchRoomCategories = async () => {
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${property_id?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    ).then((response) => response.json());
  };

  useEffect(() => {
    fetchRoomCategories().then((data) => {
      setRoomCategories(data.rooms);
    });
  }, []);

  const [roomInsightData, setRoomInsightData] = useState([]);
  const [roomId, setRoomId] = useState("");
  const handleSelectChange = (event) => {
    setRoomId(event.target.value);
  };

  const fetchRoomWiseData = async (from_date, to_date, roomId) => {
    return fetch(
      // https://dev.revcatalyst.in/api/v1/agent/contract/insights/1?room_id=2
      `${process.env.REACT_APP_BASE_URL}/api/v1/agent/contract/insights/${property_id.id}?from_date=${from_date}&to_date=${to_date}&room_id=${roomId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    ).then((response) => response.json());
  };

  const currentDate = new Date();
  const nextDate = new Date(currentDate);
  nextDate.setDate(currentDate.getDate() + 1);

  const formattedCurrentDate = formatDateYYYYMMDD(currentDate, "yyyy-MM-dd");
  const formattedNextDate = formatDateYYYYMMDD(nextDate, "yyyy-MM-dd");

  const handleDateRangeChange = (from_date, to_date) => {
    if (!roomId) {
      showErrorToast("please select room type");
      return;
    }
    if (from_date && to_date) {
      fetchRoomWiseData(from_date, to_date, roomId).then((data) => {
        setRoomInsightData(data?.data);
      });
    }
  };
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const moreOptionsButtonRef = useRef(null);
  const moreOptionsContainerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        moreOptionsContainerRef.current &&
        !moreOptionsContainerRef.current.contains(event.target)
      ) {
        setShowMoreOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [moreOptionsContainerRef]);
  return (
    <>
      
      <div>
       
        <div className="agentSidebarContentContainer flex flex_gap_10">
          <BgThemeButton
            children={"Create Booking"}
            onClick={() =>
              navigate(`/agent-contracts/create-booking/${singleAgentData.id}`)
            }
          />
          <BgOutlineThemeButton children={"Delete"}  style={{border:"1px solid red" ,color:"red"}}/>
          

          {/* more options popup */}
          {/* <div
            className={styles.moreOptionsButtonContainer}
            style={{ position: "relative" }}
            ref={moreOptionsContainerRef}
          >
            <BgOutlineThemeButton
              onClick={() => setShowMoreOptions(!showMoreOptions)}
            >
              More Options
              <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  transform: showMoreOptions
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                }}
              >
                <path d="M1 1L6 6L11 1" stroke="#3968ED" strokeWidth="1.5" />
              </svg>
            </BgOutlineThemeButton>

            {showMoreOptions && (
              <div className={styles.moreOptionsContainerChild}>
                <button>View Booking</button>
                <button>Agent Finance</button>
                <button>Delete</button>
              </div>
            )}
          </div> */}
          {/* more options popup end */}

        </div>
        {/* Agent Details */}
        <div>
          <div
            className="agentSidebarContentContainer agentHoldRoomsSidebarHeadingSmall flex flex_gap_10"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            Contract Details
          </div>
          <div className="agentSidebarContentContainer flex justify_content_between">
            <div>
              <h6 className="agentHoldRoomsSidebarHeadingSmall">Start Data</h6>
              <p>{singleAgentData.from_date || "NA"}</p>
            </div>
            <div>
              <h6 className="agentHoldRoomsSidebarHeadingSmall"> End Date </h6>
              <p>{singleAgentData.to_date || "NA"}</p>
            </div>
            <div>
              <h6 className="agentHoldRoomsSidebarHeadingSmall">
                Total Hold Rooms
              </h6>
              <p>{singleAgentData.total_hold_rooms || "NA"}</p>
            </div>
          </div>
        </div>

        {/* Room Categories */}

        <div>
          <div
            className="agentSidebarContentContainer agentHoldRoomsSidebarHeadingSmall flex flex_gap_10"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            Room Categories
          </div>

          <div className="agentSidebarContentContainer ">
            {singleAgentData &&
              singleAgentData?.roomDetails?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex justify_content_between"
                    style={{ padding: "5px 0", borderBottom: "1px solid #333" }}
                  >
                    <span className="agentHoldRoomsSidebarHeadingSmall">
                      {item.room_name}
                    </span>
                    <span>{item.hold_rooms}</span>
                  </div>
                );
              })}
          </div>
        </div>

        {/* release restrictions */}

        {singleAgentData?.release_label && (
          <div>
            <div
              className="agentSidebarContentContainer agentHoldRoomsSidebarHeadingSmall flex flex_gap_10"
              style={{ backgroundColor: "#f5f5f5" }}
            >
              Auto-Release Restriction
            </div>
            <div className="agentSidebarContentContainer ">
              <h5>Release Restrictions : </h5>
            </div>
            <div className="agentSidebarContentContainer ">
              {singleAgentData &&
                singleAgentData?.release_label.map((item, index) => {
                  const percent = singleAgentData?.release_percent[index];

                  return (
                    <div
                      key={index}
                      className="flex justify_content_between"
                      style={{
                        padding: "5px 0",
                        borderBottom: "1px solid #333",
                      }}
                    >
                      <span className="agentHoldRoomsSidebarHeadingSmall">
                        {item}
                      </span>
                      <span>{percent} %</span>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {/*Rooms insight */}

        <div>
          <div className="agentSidebarContentContainer ">
            <div className="agentHoldBookingSliderContractInsight">
              <div className="flex justify_content_between align_center">
                <h4 className="agentHoldRoomsSidebarHeadingSmall    ">
                  Room Insights
                </h4>
                <div
                  className="flex flex_gap_10 align_center"
                  style={{ width: "65%" }}
                >
                  <div style={{ width: "40%" }}>
                    <select
                      className="agentHoldBookingSliderContractInsightSelect"
                      value={roomId}
                      onChange={handleSelectChange}
                    >
                      <option value={""}>select</option>
                      {roomCategories.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.room_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div style={{ width: "60%" }}>
                    <DateRangePickerNew
                      showLabel={false}
                      dataFromChild={handleDateRangeChange}
                      minDate={formattedCurrentDate}
                    />
                  </div>
                </div>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Hold Rooms</span>
                <span>{roomInsightData[0]?.hold_rooms || "NA"}</span>
              </div>
              <div className="flex justify_content_between align_center">
                <span>Converted </span>
                <span>{roomInsightData[0]?.converted || "NA"}</span>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Not Used</span>
                <span>{roomInsightData[0]?.no_used || "NA"}</span>
              </div>
              <div
                className="flex justify_content_between align_center"
                style={{ margin: "5px 0" }}
              >
                <span>Remaining</span>
                <span>{roomInsightData[0]?.remaining || "NA"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentContractRoomsSidebarContent;
