import { useNavigate, useParams } from "react-router-dom";
import BookingStatusUpdate from "../CustomComponents/BookingStatusUpdate";
import "./Folio.css";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import { useEffect, useRef, useState } from "react";
import DashboardFilter from "../Dashboard/DashboardFiltersComponent/DashboardFilter";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  WithoutBgButtonBlue,
} from "../UI/Buttons";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import {
  IndexTable,
  LegacyCard,
  useIndexResourceState,
  Icon,
  Tooltip,
} from "@shopify/polaris";
import { CancelMajor } from "@shopify/polaris-icons";
import { useSelector } from "react-redux";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import HandleNaN from "../CustomComponents/HandleNaN/HandleNaN";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import FolioTopBarandNavigation from "./FolioTopBarandNavigation";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import {
  formatIndianCurrency,
  getCurrentPropertyDateHandler,
} from "../../utils/utils";
import BottomChargeAmounts from "./BottomChargeAmounts";
import Loader from "../UI/Loader/Loader";
import DeletePopupWithReason from "../CustomComponents/DeletePopup/DeletePopupWithReason";
import SidebarChargeAmounts from "./SidebarChargeAmounts";
import AddPaymentsPopup from "../CustomComponents/AddPaymentsPopup/AddPaymentsPopup";

const FolioPayments = () => {
  const navigate = useNavigate();
  const clickOutSideRef = useRef(null);
  const [editBillTo, setEditBillTo] = useState(false);
  const [showFolioFeature, setShowFolioFeature] = useState(false);
  const [createNewFolio, setCreateNewFolio] = useState(false);
  const [addGuestPopup, setAddGuestPopup] = useState(false);
  const [addChargesPopup, setAddChargesPopup] = useState(false);

  const [allFolioArray, setAllFolioArray] = useState([]);

  const [chargesList, setChargesList] = useState([]);
  const [settlements, setSettlements] = useState([]);
  const [payments, setPayments] = useState([]);
  const [folioSummery, setFolioSummery] = useState([]);
  const [folioData, setFolioData] = useState([]);
  const [folioBookings, setFolioBookings] = useState([]);

  const [guest, setGuest] = useState([]);
  const { folioId, folioUniqueId } = useParams();

  const property_id = useSelector((state) => state.property.value);
  const [loader, setLoader] = useState(true);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const folioFilterOptions = [
    {
      value: "today",
      label: "Today",
    },
    {
      value: "mtd",
      label: "MTD",
    },
    {
      value: "qtd",
      label: "QTD",
    },
    {
      value: "ytd",
      label: "YTD",
    },
    {
      value: "future 1 month",
      label: "Future 1 Month",
    },
    {
      value: "future 3 months",
      label: "Future 3 Months",
    },
  ];

  const proofOptions = [
    { label: "Select option" },
    { label: "Aadhar Card", value: "aadhar" },
    { label: "PAN Card", value: "pan" },
    { label: "Passport", value: "passport" },
    { label: "Driving License", value: "driving_license" },
    // { label: "Other", value: "other" },
  ];

  const gstTypeOptions = [
    { label: "select Option", value: "" },
    { label: "Inclusive", value: "inclusive" },
    { label: "Exclusive", value: "exclusive" },
  ];

  const gstOptions = [
    { label: "Select Option", value: "" },
    { label: "8%", value: "8" },
    { label: "12%", value: "12" },
    { label: "15%", value: "15" },
  ];

  const currentDate = new Date().toISOString().split("T")[0];

  const [addChargesData, setAddChargesData] = useState({
    date: currentDate,
    booking_id: "",
    folio_id: "",
    property_id: "",
    notes: "",
    notes: "",
    payment_mode: "",
    payment_type: "",
    amount: "",
  });

  const [selectedDateFilterValue, setSelectedDateFilterValue] = useState("MTD");
  const [dayTypeFilter, setDayTypeFilter] = useState("");

  const handleSelect = (label, value) => {
    setSelectedDateFilterValue(label);
    setDayTypeFilter(value);
  };

  const resourceName = {
    singular: "chargesList",
    plural: "chargesLists",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(chargesList);

  const settlementsRowMarkup = payments?.map(
    ({
      id,
      date,
      charge_type,
      description,
      rate,
      quantity,
      sub_total,
      discount_amount,
      tax_rate,
      net_total,

      notes,
      payment_mode,
      payment_type,
      settlement_name,
      amount,
      index,
      is_cancelled,
      reason,
    }) => {
      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={id}
        >
          <IndexTable.Cell>
            <UnderscoreRemoveCapitalize
              text={payment_mode ? payment_mode : payment_type}
            />
          </IndexTable.Cell>

          <IndexTable.Cell>{date}</IndexTable.Cell>

          <IndexTable.Cell>{notes}</IndexTable.Cell>
          <IndexTable.Cell>{formatIndianCurrency(amount)}</IndexTable.Cell>
          <IndexTable.Cell>
            {is_cancelled === 1 ? (
              <Tooltip content={reason} dismissOnMouseOut>
                <div style={{ width: "100px" }}>
                  <BookingStatusUpdate status={"cancelled"} />
                </div>
              </Tooltip>
            ) : (
              <div
                onClick={() => [
                  setDeleteWithReasonPopup(true),
                  setDeleteKotId(id),
                ]}
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  display: "inline-flex",
                }}
              >
                &times;
              </div>
            )}
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const paymentModeOptions = [
    { label: "Select Option", value: "" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    // { label: "Other", value: "other" },
  ];
  const settlementOptions = [
    { label: "Select Option", value: "" },
    { label: "Room Settlement", value: "room_settlement" },
    { label: "F & B Settlement", value: "f_and_b_settlement" },
  ];

  const paymentTypeOptions = [
    { label: "Select Option", value: "" },
    { label: "Cash", value: "cash" },
    { label: "City Ledger", value: "city_ledger" },
  ];

  const [roomDetails, setRoomDetails] = useState([]);

  useEffect(() => {
    // Convert input values to numbers
    const rate = parseFloat(addChargesData.rate);
    const quantity = parseFloat(addChargesData.quantity);
    const discount_rate = parseFloat(addChargesData.discount_rate);
    const tax_rate = parseFloat(addChargesData.tax_rate);

    let subtotal = rate * quantity;

    const discountAmount = (subtotal * discount_rate) / 100;
    const priceAfterDiscount = subtotal - discountAmount;

    // Apply GST
    let gstAmount = 0;

    if (addChargesData.gst_type === "exclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / 100;
    } else if (addChargesData.gst_type === "inclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / (100 + tax_rate);
    }

    let totalAmount = priceAfterDiscount + gstAmount;

    setAddChargesData({
      ...addChargesData,
      // subtotal: subtotal.toFixed(2),
      // net_total: totalAmount.toFixed(2),
      // discount_amount: discountAmount.toFixed(2),
      // tax_amount: gstAmount.toFixed(2),
    });
  }, [
    addChargesData.rate,
    addChargesData.quantity,
    addChargesData.discount_rate,
    addChargesData.gst_type,
    addChargesData.tax_rate,
    addChargesData.tax_amount,
  ]);

  const setAddChargesHandleChange = (event) => {
    if (
      event.target.name === "amount" &&
      addChargesData.payment_type === "city_ledger"
    ) {
      if (
        agentCreditLimit > 0 &&
        parseFloat(event.target.value) > agentCreditLimit
      ) {
        showErrorToast(
          "Amount should not be greater than Agent Balance Credit Limit"
        );
        return;
      }
    }
    if (event.target.name === "company_name") {
      setAddChargesData({
        // ...addChargesData,
        amount: 0,
      });
    }

    setAddChargesData({
      ...addChargesData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    setAddChargesData({
      ...addChargesData,
      amount: "",
    });
  }, [addChargesData.payment_type]);

  const fetchFolioInfo = async (propertyId, selectedFolioId) => {
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/folios/${folioId}/${propertyId}?folio_id=${
          selectedFolioId ? selectedFolioId : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setFolioData(data?.data);
      setChargesList(data?.data?.charges);
      setSettlements(data?.data?.payments);
      setFolioSummery(data?.data?.folio_summary);
      setGuest(data?.data?.guest);
      setRoomDetails(data?.data?.roomDetails);
      setFolioBookings(data?.data?.booking);
      setLoader(false);
      setPayments(data?.data?.payments);

      setAllFolioArray(data?.allFolios);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id && folioUniqueId) {
      fetchFolioInfo(property_id?.id, folioUniqueId);
      fetchAgentLists();
    }
  }, [property_id]);

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }
  }, [property_id]);

 
  const handleClickOutside = (event) => {
    if (
      clickOutSideRef.current &&
      !clickOutSideRef.current.contains(event.target)
    ) {
      setShowFolioFeature(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const roomNumberArray = [
    {
      label: "Select option",
      value: null,
    },
    ...roomDetails?.map((room) => ({
      label: room?.roomNo?.toString(),
      value: room?.roomNo?.toString(),
    })),
  ];
  const guestsArray = [
    {
      label: "Select option",
      value: null,
    },
    ...roomDetails?.flatMap((room) =>
      room?.guest_details?.map((guest) => ({
        label: guest?.guest_name,
        value: guest?.id?.toString(),
      }))
    ),
  ];

  const dataFromChild = (id) => {
    fetchFolioInfo(property_id?.id, id);
    navigate(`/bookings/payment/${folioId}/${id}`);
  };
  const [chargeAddedTransactions, setChargeAddedTransactions] = useState({
    discountAmountSum: "",
    subTotalSum: "",
    taxAmountSum: "",
    grandTotalSum: "",
    paidAmountSum: "",
    dueAmountSum: "",
  });

  useEffect(() => {
    const filteredChargesList = chargesList?.filter(
      (transaction) => transaction.is_cancelled === 0
    );
    const discountAmountSum = filteredChargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.discount_amount || 0),
      0
    );
    const subTotalSum = filteredChargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.sub_total),
      0
    );
    const taxAmountSum = filteredChargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.tax_amount),
      0
    );
    const grandTotalSum = filteredChargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.net_total),
      0
    );

    const filteredPayments = folioData?.payments?.filter(
      (transaction) => transaction.is_cancelled === 0
    );

    const paidAmountSum = filteredPayments?.reduce(
      (sum, transaction) => sum + parseFloat(transaction.amount || 0),
      0
    );
    const dueAmountSum = filteredChargesList.reduce(
      (sum, transaction) => sum + parseFloat(transaction.due_amount || 0),
      0
    );

    const transactions = {
      discountAmountSum,
      subTotalSum,
      taxAmountSum,
      grandTotalSum,
      paidAmountSum,
      dueAmountSum,
    };
    setChargeAddedTransactions(transactions);
  }, [chargesList]);

  const [agentLists, setAgentLists] = useState([]);

  const fetchAgentLists = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      if (data?.data.length > 0) {
        setAgentLists(data.data);
      } else setAgentLists([]);
    } catch (error) {
      console.error(error);
    }
  };

  const agentListOptions = [
    {
      label: "Select option",
      value: "",
    },
    ...agentLists?.map((agent) => ({
      label: agent?.company_name,
      value: agent?.agent_information,
    })),
  ];

  const [agentCreditLimit, setAgentCreditLimit] = useState("");
  useEffect(() => {
    const selectedAgent = agentLists?.find(
      (agent) =>
        Number(agent?.agent_information) ===
        Number(addChargesData?.company_name)
    );

    if (selectedAgent && selectedAgent?.credit.length > 0) {
      const creditLimit = selectedAgent?.credit?.reduce(
        (acc, agent) => Number(acc) + Number(agent.balance_amount),
        0
      );
      setAgentCreditLimit(creditLimit);
    } else {
      setAgentCreditLimit(null);
    }

    setAddChargesData({
      ...addChargesData,
      amount: null,
    });
  }, [addChargesData.company_name, agentLists]);

  const [deleteWithReasonPopup, setDeleteWithReasonPopup] = useState(false);
  const [reasonFromChild, setReasonFromChild] = useState("");
  const [deleteKotId, setDeleteKotId] = useState("");
  const handleDeleteWithReason = (reason) => {
    setReasonFromChild(reason);
  };

  const handleCancelStatusButton = async (id) => {
    try {
      const response = await fetch(
        // https://dev.revcatalyst.in/api/v1/folios/payment/delete/{payment_id}/{property_id}
        `${process.env.REACT_APP_BASE_URL}/api/v1/folios/payment/delete/${deleteKotId}/${property_id.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            reason: reasonFromChild,
            folio_id: folioData?.id,
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        fetchFolioInfo(property_id?.id, folioData?.id);
        setReasonFromChild("");
        setDeleteWithReasonPopup(false);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const settlementDataFromComponent = (data ,e) => {
    e.preventDefault();
   console.log(data ,"settlementDataFromComponent")
   fetch(
    `${process.env.REACT_APP_BASE_URL}/api/v1/add/folios/payments/${property_id?.id}`,
    {
      method: "POST",
      body: JSON.stringify({
        ...data,
        folio_id: folioData.id,
        property_id: property_id?.id,
        booking_id: folioData.booking_id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        showSuccessToast(data.message);
        setAddChargesData(false);
        fetchFolioInfo(property_id?.id, folioUniqueId);
        setAddChargesPopup(false);
      } else {
        showErrorToast(data.message);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    });
  };
  const handleSubmitCreateFolio = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add/folios/payments/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addChargesData,
          folio_id: folioData.id,
          property_id: property_id?.id,
          booking_id: folioData.booking_id,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddChargesData(false);
          fetchFolioInfo(property_id?.id, folioUniqueId);
          setAddChargesPopup(false);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };


  return (
    <>
      <FolioTopBarandNavigation
        guestsArray={guestsArray}
        roomNumberArray={roomNumberArray}
        // handleSelect={handleSelect}
        // fetchFolioInfoParent = {fetchFolioInfo(property_id?.id)}
        dataFromChild={dataFromChild}
      />
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify_content_end" style={{ margin: "20px" }}>
            {folioData?.booking?.status !== "checked_out" && (
              <WithoutBgButtonBlue
                style={{ fontSize: "14px", padding: "0" }}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_5311_4678)">
                      <path
                        d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                        stroke="#3968ED"
                      />
                      <path
                        d="M10.5868 7H3.41357"
                        stroke="#3968ED"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M7 3.4132L7 10.5864"
                        stroke="#3968ED"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5311_4678">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                }
                children={"Add"}
                onClick={() => setAddChargesPopup(!addChargesPopup)}
              />
            )}
          </div>

          <div
            className=" mt-20"
            style={{
              marginRight: "20px",
              marginLeft: "20px",
            }}
          >
            <div className="bg_white">
              {payments && payments.length > 0 ? (
                <>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      style={{
                        // height: "300px",
                        overflow: "auto",
                        width: "80%",
                      }}
                    >
                      <LegacyCard>
                        <IndexTable
                          resourceName={resourceName}
                          itemCount={payments.length}
                          selectedItemsCount={
                            allResourcesSelected
                              ? "All"
                              : selectedResources.length
                          }
                          selectable={false}
                          onSelectionChange={handleSelectionChange}
                          headings={[
                            { title: "Type" },
                            { title: "Date" },
                            { title: "Notes" },
                            { title: "Amount" },
                            { title: " " },
                          ]}
                        >
                          {settlementsRowMarkup}
                        </IndexTable>
                      </LegacyCard>
                    </div>
                    <div style={{ width: "20%" }}>
                      <SidebarChargeAmounts
                        chargesList={
                          chargesList.length > 0
                            ? chargesList.filter(
                                (charge) => charge?.is_cancelled === 0
                              )
                            : []
                        }
                        folioData={folioData}
                        style={{ position: "sticky", top: "90px" }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <NoDataFoundSmall />
              )}
            </div>
          </div>

          {addChargesPopup && (
            // <>
            //   <div className="bg-backdrop">
            //   <form
            //     onSubmit={handleSubmitCreateFolio}
            //     style={{ width: "600px" }}
            //     className="stock-add-model-container"
            //   >
            //     <div
            //       style={{
            //         display: "flex",
            //         justifyContent: "space-between",
            //         alignItems: "center",
            //         marginBottom: "15px",
            //       }}
            //     >
            //       <div className="add-ota-model-container-heading">
            //         Add Payments
            //       </div>
            //       <div
            //         onClick={() => setAddChargesPopup(!addChargesPopup)}
            //         style={{ cursor: "pointer" }}
            //       >
            //         <Icon source={CancelMajor} color="base" />
            //       </div>
            //     </div>
            //     <div className="form-container-div-half">
            //       <div className="form-container-div">
            //         <VerticalInputFieldCustom
            //           required={true}
            //           focused={false}
            //           name="date"
            //           type="date"
            //           titleName="Date"
            //           onChange={setAddChargesHandleChange}
            //           autoComplete="off"
            //           value={addChargesData.date || currentDate}
            //         />
            //       </div>
            //       <div className="form-container-div">
            //         <VerticalInputSelectCustomCopy
            //           options={paymentTypeOptions}
            //           type="text"
            //           required={true}
            //           focused={false}
            //           name="payment_type"
            //           titleName="Payment Type"
            //           onChange={setAddChargesHandleChange}
            //           autoComplete="off"
            //           value={addChargesData.payment_type}
            //         />
            //       </div>
            //       <div className="form-container-div">
            //         {addChargesData.payment_type === "cash" && (
            //           <VerticalInputSelectCustomCopy
            //             options={paymentModeOptions}
            //             type="text"
            //             required={true}
            //             focused={false}
            //             name="payment_mode"
            //             titleName="Payment Mode"
            //             onChange={setAddChargesHandleChange}
            //             autoComplete="off"
            //             value={addChargesData.payment_mode}
            //           />
            //         )}
            //         {addChargesData.payment_type === "city_ledger" && (
            //           <VerticalInputSelectCustomCopy
            //             options={agentListOptions}
            //             type="text"
            //             required={true}
            //             focused={false}
            //             name="company_name"
            //             titleName="Company Name"
            //             onChange={setAddChargesHandleChange}
            //             autoComplete="off"
            //             value={addChargesData.company_name}
            //           />
            //         )}
            //       </div>
            //     </div>
            //     <div className="form-container-div-half">
            //       <div className="form-container-div">
            //         <VerticalInputFieldCustom
            //           required={true}
            //           focused={false}
            //           name="notes"
            //           titleName="Notes"
            //           onChange={setAddChargesHandleChange}
            //           autoComplete="off"
            //           value={addChargesData.notes}
            //         />
            //       </div>
            //       <div className="form-container-div">
            //         {agentCreditLimit < 1 &&
            //           addChargesData.payment_type === "city_ledger" ? (
            //           <VerticalInputFieldCustom
            //             // type="number"
            //             required={true}
            //             focused={false}
            //             disabled={true}
            //             name="amount"
            //             titleName="Amount"
            //             onChange={setAddChargesHandleChange}
            //             autoComplete="off"
            //             value={addChargesData.amount}
            //           />
            //         ) : (
            //           <VerticalInputFieldCustom
            //             type="number"
            //             required={true}
            //             focused={false}
            //             // disabled={true}
            //             name="amount"
            //             titleName="Amount"
            //             onChange={setAddChargesHandleChange}
            //             autoComplete="off"
            //             value={addChargesData.amount}
            //           />
            //         )}
            //       </div>
            //       {/* <div className="form-container-div">
            //         <VerticalInputSelectCustomCopy
            //           options={settlementOptions}
            //           type="text"
            //           required={true}
            //           focused={false}
            //           name="settlement_name"
            //           titleName="Settlement Name"
            //           onChange={setAddChargesHandleChange}
            //           autoComplete="off"
            //           value={addChargesData.settlement_name}
            //         />
            //       </div> */}
            //     </div>
            //     {addChargesData.payment_type === "city_ledger" && (
            //       <div className="form-container-div-full">
            //         <>
            //           Balance Credit Limit:
            //           <span>
            //             {agentCreditLimit
            //               ? formatIndianCurrency(agentCreditLimit)
            //               : formatIndianCurrency(agentCreditLimit)}
            //           </span>
            //         </>
            //       </div>
            //     )}

            //     <div className="flex flex_gap_10">
            //       {chargeAddedTransactions?.grandTotalSum -
            //         chargeAddedTransactions?.paidAmountSum >=
            //         addChargesData?.amount ? (
            //         <BgThemeButton children={"Add"} type={"submit"} />
            //       ) : (
            //         <BgThemeButtonDisabled children={"Add"} />
            //       )}

            //       <BgOutlineThemeButton
            //         children={"Cancel"}
            //         type={"button"}
            //         onClick={() => setAddChargesPopup(!addChargesPopup)}
            //       />
            //     </div>
            //   </form>
            // </div>



            
             
            // </>

            <>
             <AddPaymentsPopup
                // bookingId={"1"}
                setShowCheckOutSettlementDetails={setAddChargesPopup}
                balanceAmount={
                  chargeAddedTransactions.grandTotalSum -
                  chargeAddedTransactions.paidAmountSum
                }
                  settlementDataFromComponent={settlementDataFromComponent}
              />
            
            
            </>

          )}

          {/* <BottomChargeAmounts
            chargesList={chargesList}
            folioData={folioData}
          /> */}

          {deleteWithReasonPopup && (
            <DeletePopupWithReason
              title={"Payment"}
              textAreaName={"reason_of_cancelation"}
              onDeleteWithReason={handleDeleteWithReason}
              wordLimit={50}
              deleteWithReasonPopup={deleteWithReasonPopup}
              setDeleteWithReasonPopup={setDeleteWithReasonPopup}
              deleteFunction={() => handleCancelStatusButton(deleteKotId)}
              id={deleteKotId}
            />
          )}
        </>
      )}
    </>
  );
};
export default FolioPayments;
