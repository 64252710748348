// Polaris Imports
import {
  Button,
  Card,
  ChoiceList,
  HorizontalStack,
  Icon,
  IndexTable,
  Pagination,
  Tooltip,
  useIndexResourceState,
  Text,
} from "@shopify/polaris";
import BarCharts from "../BarCharts";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "../UI/Buttons";

import DatePicker from "react-datepicker";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import CustomProgressBar from "../CustomComponents/CustomProgressBar";
import CustomToggleButton from "../UI/CustomToggleButton";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { Page, LegacyCard, DataTable } from "@shopify/polaris";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { useSelector } from "react-redux";
import Loader from "../UI/Loader/Loader";
import { useNavigate } from "react-router-dom";
import CompetitorsAfter from "../CompetitorsAfter";
import SelectDateRangeWithoutInput from "../CustomComponents/SelectDateRangeWithoutInput/SelectDateRangeWithoutInput";
import ShowConfirmedArrivals from "../UI/ShowConfirmedArrivals";
import ShowHouseCheckIn from "../UI/ShowHouseCheckIn";
import ShowDepartureCheckOut from "../UI/ShowDepartureCheckOut";
import NoDataFound from "../CustomComponents/NoDataFound";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import { current } from "@reduxjs/toolkit";
import LineChartDashboard from "../LineChartsDashboard";
import SelectDateRange from "../CustomComponents/SelectDateRange/SelectDateRange";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import ReactDatePicker from "react-datepicker";
import SelectDatePickerCustom from "../CustomComponents/SelectDateRange/SelectDatePickerCustom";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import {
  permission_inner_lists,
  rooms_inner_lists,
} from "../../assets/constant";
import RegionReportComp from "../CustomComponents/RegionReportComp";
import LoaderSmall from "../UI/Loader/LoaderSmall";
import ReportByMealPlan from "../CustomComponents/ReportByMealPlan";
import ReportByRoomType from "../CustomComponents/ReportByRoomType";
import CustomRoomChoiceSelect from "../CustomComponents/CustomRoomChoiceSelect/CustomRoomChoiceSelect";
import ComparisonRevparDoubleCharts from "./ComparisonRevparDoubleCharts";
import ReportByRoomOccupancyNew from "../CustomComponents/ReportByRoomOccupancyNew";
import VerticalInputSelectCustomPermissions from "../CustomComponents/VerticalInputSelectCustomPermissions";
import ReportComplimentaryKot from "../CustomComponents/ReportComplimentaryKot";
import ReportCancelledKot from "../CustomComponents/ReportCancelledKot";
import ReportOccupancyChart from "../CustomComponents/ReportOccupancyChart";


Chart.register(ArcElement);

const ProductionReports = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [fetchedData, setFetchedData] = useState(null);
  const [openDayStatus, setOpenDayStatus] = useState();
  const [showDashBoardCheckIn, setShowDashBoardCheckIn] = useState(false);
  const [showDashBoardCheckOut, setShowDashBoardCheckOut] = useState(false);
  const [showDashboardAllCheckIn, setShowDashboardAllCheckIn] = useState(false);
  const [showManualSearchInput, setShowManualSearchInput] = useState(false);
  const [showWebInput, setShowWebInput] = useState(false);
  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const [regionReportsData, setRegionReportsData] = useState([]);

  const [showCheckOutBookingDetails, setShowCheckOutBookingDetails] =
    useState(false);
  const [
    showCheckOutIdClickBookingDetails,
    setShowCheckOutIdClickBookingDetails,
  ] = useState(false);

  const [settlementDoneCheckOut, setSettlementDoneCheckOut] = useState(false);

  const [showCheckOutSettlementDetails, setShowCheckOutSettlementDetails] =
    useState(false);
  const [openBookingDetails, setOpenBookingDetails] = useState(true);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const [bookingDetails, setBookingDetails] = useState([]);
  const [allSettlementsData, setAllSettlementsData] = useState([]);

  // show click arrow buttons
  const [periodStartDate, setPeriodStartDate] = useState("");
  const [periodEndDate, setPeriodEndDate] = useState("");
  const [showAllSettlement, setShowAllSettlement] = useState(false);
  const [showInHouseCheckIn, setShowInHouseCheckIn] = useState(false);
  const [showDepartureCheckOut, setShowDepartureCheckOut] = useState(false);

  const [checkOutBookingDetail, setCheckOutBookingDetail] = useState([]);

  const property_id = useSelector((state) => state.property.value);

  const [dayTypeFilter, setDayTypeFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);

  const [valueArray, setValueArray] = useState([]);
  const accordRef = useRef(null);
  const regionReportsRef = useRef(null);
  const paymentModeRef = useRef(null);

  const toggleAccord = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
    setIsOpen2(false);
  };

  const toggleAccord1 = () => {
    setIsOpen(false);
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
  };

  const toggleAccord2 = () => {
    setIsOpen2(!isOpen2);
    // setIsOpen1(false);
    // setIsOpen(false);
  };

  const toggleAccord3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen1(false);
    setIsOpen(false);
  };

  const toggleAccord4 = () => {
    setIsOpen4(!isOpen4);
  };

  const toggleAccord5 = () => {
    setIsOpen5(!isOpen5);
  };

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  // Reservations
  const dateOptions = [
    { id: 0, value: "-1", label: "Today" },
    { id: 0, value: "-2", label: "Yesterday" },
    { id: 1, value: "-7", label: "Past 7 Days" },
    { id: 2, value: "-30", label: "Past 30 Days" },
    { id: 3, value: "-90", label: "Past 90 Days" },
    { id: 4, value: "-1", label: "Last Year" },
    { id: 5, value: "-3", label: "Last 3 year" },
  ];
  // Reservations
  const dateOptions1 = [
    { id: 0, value: "revenue", label: "Revenue" },
    { id: 1, value: "profit", label: "Profit" },
    { id: 2, value: "reservation", label: "Reservation" },
    { id: 3, value: "expense", label: "Expense" },
    { id: 4, value: "event", label: "Event" },
    { id: 5, value: "f&b", label: "F&B" },
  ];

  const [allRoomTypes, setAllRoomTypes] = useState([]);

  const fetchAllRoomTypes = async (propertyId) => {
    try {
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAllRoomTypes(data.rooms);
      setLoader(false);
      const roomIds = data.rooms.map((room) => room.id);
      setSelectedRoomType(roomIds);
      if (property_id) {
        fetchInfo(property_id?.id, roomIds, selectedRoomType);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const choiceListRoomsArray = allRoomTypes.map((room) => {
    return { label: room.room_name, value: room.id };
  });

  const roomIdsArray = allRoomTypes.map((room) => room.id);

  const handleAccordClick = (id, label, value) => {
    setValueArray([{ id: id, label: label, value: value }]);
    setDayTypeFilter(value);
  };

  const handleShowDashBoardCheckIn = (e) => {
    e.stopPropagation();
    setShowDashBoardCheckIn(!showDashBoardCheckIn);
  };

  const handleShowDashBoardCheckOut = (e) => {
    e.stopPropagation();
    setBookingId("");
    setShowDashBoardCheckOut(!showDashBoardCheckOut);
  };

  const handleShowManualSearchInput = () => {
    {
      showWebInput === true && setShowWebInput(false);
    }
    setShowManualSearchInput(!showManualSearchInput);
  };

  const handleShowWebInput = () => {
    {
      showManualSearchInput === true && setShowManualSearchInput(false);
    }
    setShowWebInput(!showWebInput);
  };

  const [bookingOpenIndex, setOpenBookingIndex] = useState(1);
  const [bookingAccordianActive, setBookingAccordianActive] = useState("");

  const handleOpenBookigDetails = (idx) => {
    setOpenBookingIndex(idx);
    if (bookingOpenIndex === idx) {
      setBookingAccordianActive("active");
    } else {
      setBookingAccordianActive("");
    }
  };

  const handleShowDashBoardAllCheckIn = () => {
    setShowDashboardAllCheckIn(!showDashboardAllCheckIn);
  };

  const [loader, setLoader] = useState(false);

  const data = [
    {
      name: "Values in Thousand",
      color: "#7C9CF3",
      barMargin: "Large",
      data: [
        { value: 1000, key: "Jan" },
        { value: 7000, key: "Feb" },
        { value: 10000, key: "Mar" },
        { value: 2000, key: "Apr" },
        { value: 2000, key: "May" },
        { value: 2000, key: "Jun" },
        { value: 2000, key: "Jul" },
        { value: 2000, key: "Aug" },
        { value: 2000, key: "Sep" },
        { value: 2000, key: "Oct" },
        { value: 2000, key: "Nov" },
        { value: 2000, key: "Dec" },
      ],
    },
  ];

  const navigate = useNavigate();

  const [selectedDiscountMethod, setSelectedDiscountMethod] =
    useState("automatic");

  const handleDiscountMethodChange = (value) => {
    setSelectedDiscountMethod(value);
  };

  const [selectedRoomType, setSelectedRoomType] = useState([]);
  const [selectedPaymentOf, setSelectedPaymentOf] = useState([]);
  const [selectedBalanceType, setSelectedBalanceType] = useState([]);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState([]);

  const [selectedBookingStatus, setSelectedBookingStatus] = useState([
    "in-house",
  ]);

  const handleBalanceTypeStatus = useCallback(
    (value) => setSelectedBalanceType(value),
    []
  );
  const handlePaymentOf = useCallback(
    (value) => setSelectedPaymentOf(value),
    []
  );
  const handlePaymentMode = useCallback(
    (value) => setSelectedPaymentMode(value),
    []
  );
  const handleBookingStatus = useCallback(
    (value) => setSelectedBookingStatus(value),
    []
  );

  const fetchDashboardInfo = async (propertyId, dayTypeFilter) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/${property_id?.id}?dayTypeFilter=${dayTypeFilter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        setLoader(false);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (data.message === "Unauthenticated.") {
        navigate("/login");
      }
      setLoader(false);
      setFetchedData(data);
    } catch (error) {
      if (error === "Unauthenticated.") {
        navigate("/login");
      }
      setLoader(false);
      console.error(error);
    }
  };

  // const currentDate = new Date().toISOString().split("T")[0];

  // const calculateToDate = (fromDate) => {
  //   const toDate = new Date(fromDate);
  //   toDate.setDate(toDate.getDate() - 100);
  //   return toDate.toISOString().split("T")[0];
  // };

  const calculateToDate = (fromDate, days, operation) => {
    const toDate = new Date(fromDate);

    if (operation === "add") {
      toDate.setDate(toDate.getDate() + days);
    } else if (operation === "subtract") {
      toDate.setDate(toDate.getDate() - days);
    } else {
      throw new Error('Invalid operation. Use "add" or "subtract".');
    }

    return toDate.toISOString().split("T")[0];
  };

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: calculateToDate(currentDate, 15, "subtract"),
    to_date: calculateToDate(currentDate, 15, "add"),
  });

  const [comparisonStartDateRange, setComparisonStartDateRange] = useState({
    from_date: calculateToDate(currentDate, 15, "subtract"),
    to_date: calculateToDate(currentDate, 15, "add"),
  });

  const [comparisonEndDateRange, setComparisonEndDateRange] = useState({
    from_date: calculateToDate(currentDate, 15, "subtract"),
    to_date: calculateToDate(currentDate, 15, "add"),
  });

  const sendingData = {
    comparisonFrom: comparisonStartDateRange,
    comparisonTo: comparisonEndDateRange,
    roomType: selectedRoomType,
    source: selectedBookingStatus,
  };

  const dataFromChild = (from_date, to_date) => {
    setSelectedDatesRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  const dataFromChild1 = (from_date, to_date) => {
    setComparisonStartDateRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  const dataFromChild2 = (from_date, to_date) => {
    setComparisonEndDateRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  useEffect(() => {
    if (property_id) {
      // fetchDashboardInfo(property_id.id, dayTypeFilter);
      fetchAllRoomTypes(property_id.id);
    }
  }, [property_id]);

  const openDayStatusHandler = useCallback((data) => {
    setOpenDayStatus(data);
  }, []);

  const chart_data = {
    datasets: [
      {
        data: [fetchedData?.total_revenue, fetchedData?.foodSale, 0],
        backgroundColor: ["#3968ED", "#15AA12", "#CCCCCC"],
        display: true,
      },
    ],
  };

  const [bookingId, setBookingId] = useState("");

  const [openAccordion, setOpenAccordion] = useState("first");

  // UseState for forms
  const [settlementAddData, setSettlementAddData] = useState({
    settlement_name: "",
    payment_mode: "",
    notes: "",
    date: "",
    amount: "",
    property_id: property_id?.id,
    other_payment_mode: "",
  });

  const paymentModeOptions = [
    { label: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    { label: "Other", value: "other" },
  ];

  const reportFilterOptions = [
    { label: "Select Option" },
    { label: "RevPar", value: "rev_par", permission: "production_rev_par" },
    {
      label: "Rooms Sold/Occupancy",
      value: "occupancy",
      permission: "production_occupancy",
    },
    {
      label: "Reservation By Region",
      value: "reservation_by_region",
      permission: "production_reservation_by_region",
    },
    {
      label: "ADR (Average Daily Rate)",
      value: "adr_report",
      permission: "production_adr_report",
    },
    {
      label: "Reservation By Room Categories",
      value: "reservations_by_type",
      permission: "production_reservations_by_type",
    },
    {
      label: "Reservation By Plan",
      value: "reservations_by_plan",
      permission: "production_reservations_by_plan",
    },
    // {
    //   label: "Room Occupancy",
    //   value: "room_occupancy",
    //   permission: "production_room_occupancy",
    // },
    {
      label: "Complimentary KOT's",
      value: "complimentary_kot",
      permission: "production_complimentary_kot",
    },
    {
      label: "Cancelled KOT's",
      value: "cancelled_kot",
      permission: "production_cancelled_kot",
    },
    {
      label: "Occupancy Chart",
      value: "occupancy_chart",
      permission: "production_cancelled_kot",
    },
  ];

  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [fullData, setFullData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filterName, setFilterName] = useState("rev_par");
  const [polarisData, setPolarisData] = useState([]);

  useEffect(() => {
    setPolarisData([
      {
        data: fullData.from_data || [],
        name: "2024",
        color: "lightseagreen",
      },
      {
        data: fullData.to_data || [],
        name: "2023",
        color: "lightgreen",
      },
    ]);
  }, [fullData]);

  const generateYearOptions = (currentYear) => {
    const numYears = 3;
    const startYear = currentYear - numYears;
    const endYear = currentYear;

    const years = [];
    years.push({ label: "Select a year", value: "" });
    for (let year = startYear; year <= endYear; year++) {
      years.push({ label: year.toString(), value: year });
    }

    return years;
  };

  const [urlFilter, setUrlFilter] = useState("rev_par");

  const handleUrlInnerFilter = useCallback(
    (e) => {
      if (e === "occupancy") {
        setUrlFilter("occupancy");
      } else if (e === "channel_production") {
        setUrlFilter("cp");
      } else if (e === "reservation_by_region") {
        setUrlFilter("reservation_by_region");
      } else if (e === "adr_report") {
        setUrlFilter("adr_report");
      } else if (e === "reservations_by_type") {
        setUrlFilter("reservations_by_type");
      } else if (e === "reservations_by_plan") {
        setUrlFilter("reservations_by_plan");
      } else {
        setUrlFilter("rev_par");
      }
    },
    [urlFilter]
  );

  // useEffect(() => {
  //   const calculate_data = () => {
  //     while (true) {
  //       console.log("Data not ready yet");
  //     }
  //   };
  //   calculate_data();
  // }, []);

  // Example usage:
  const currentYear = new Date().getFullYear();
  const yearOptions = generateYearOptions(currentYear);

  const fetchInfo = async (PropertyId, roomIds) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/revenue-par-occupancy-report/${PropertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${roomIds}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const setErrorWithTimeout = (setterFunction, keyName, duration = 3000) => {
    setterFunction((prev) => ({ ...prev, [keyName]: true }));
    setTimeout(() => {
      setterFunction((prev) => ({ ...prev, [keyName]: false }));
    }, duration);
};
  
  const [revParError, setRevParError] = useState({
    roomType: false,
  });
  const fetchRevparHandle = async (PropertyId, roomIds) => {
    if (selectedRoomType.length === 0) {
      showErrorToast("Please select room type");
      setErrorWithTimeout(setRevParError, "roomType",3000)
      return;
    }
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/revenue-par-occupancy-report/${PropertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [occupancyError, setOccupancyError] = useState({
    roomType: false,
  })
  const fetchOccupancyHandle = async (PropertyId, roomIds) => {
    if (selectedRoomType.length === 0) {
      showErrorToast("Please select room type");
      setErrorWithTimeout(setOccupancyError, "roomType",3000)
      return;
    }
    setLoader(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/sold-room-report/${PropertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchADRHandle = async (PropertyId, roomIds) => {
    if (selectedRoomType.length === 0) {
      showErrorToast("Please select room type");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/average-daily-rate-report/${PropertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchReservationByTypeHandle = async (PropertyId, roomIds) => {
    if (selectedRoomType.length === 0) {
      showErrorToast("Please select room type");
      return;
    }
    setLoader(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/reservation-by-category-report/${PropertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setRegionReportsData(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchReservationByPlanHandle = async (PropertyId, roomIds) => {
    if (selectedRoomType.length === 0) {
      showErrorToast("Please select room type");
      return;
    }
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/reservation-by-plan-report/${PropertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setRegionReportsData(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchRoomOccupancyInfoReport = async (PropertyId, roomIds) => {
    if (selectedRoomType.length === 0) {
      showErrorToast("Please select room type");
      return;
    }
    setLoader(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/room-occupancy-report/${PropertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchComplimentaryKot = async (PropertyId, roomIds) => {
    if (selectedRoomType.length === 0) {
      showErrorToast("Please select room type");
      return;
    }
    setLoader(true);

    try {
      const response = await fetch(
        // https://dev.revcatalyst.in/api/v1/get/complimentary-kot-report/4?from_date=2024-02-01&to_date=2024-04-30
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/complimentary-kot-report/${PropertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setFullData([]);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchCAncelledKot = async (PropertyId, roomIds) => {
    if (selectedRoomType.length === 0) {
      showErrorToast("Please select room type");
      return;
    }
    setLoader(true);

    try {
      const response = await fetch(
        // https://dev.revcatalyst.in/api/v1/get/complimentary-kot-report/4?from_date=2024-02-01&to_date=2024-04-30
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/cancelled-kot-report/${PropertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setFullData([]);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchOccupancyChart = async (PropertyId, roomIds) => {
    if (selectedRoomType.length === 0) {
      showErrorToast("Please select room type");
      return;
    }
    setLoader(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/room-occupancy-report/${PropertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setFullData([]);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchRegionReport = async (PropertyId) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/reservation-by-region-report/${PropertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setRegionReportsData(data.data);
        setLoader(false);
      } else {
        showErrorToast(data.message);
        setLoader(false);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  const otherMenusRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      otherMenusRef.current &&
      !otherMenusRef.current.contains(event.target)
    ) {
      setIsOpen(false);
      setIsOpen1(false);
      setIsOpen2(false);
      setIsOpen3(false);
      setIsOpen4(false);
      setIsOpen5(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getDataFromCustomRoomChoiceSelect = (data) => {
    setSelectedRoomType(data);
  };

  console.log(filterName, "filterName");

  return (
    <>
      <SidebarInnerLists innerLists={permission_inner_lists} zIndex={20} />

      {/* {loader ? (
        <Loader />
      ) : ( */}
      <>
        <section className="dashboard_page">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <div
                style={{ fontSize: "20px" }}
                className="report-chart-heading"
              >
                Production Reports
              </div>
             
            </div>
            <div>
              <div
                style={{
                  background: "rgba(255, 255, 255, 1)",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <div title="Reservation Insights">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "10px",
                    }}
                  >
                    <div className="report-chart-heading">Filters</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <div className="flex flex_gap_10">
                      <div style={{ width: "200px" }}>
                        <VerticalInputSelectCustomPermissions
                          titleName={"Filter Name"}
                          value={filterName}
                          defaultValue={"rev_par"}
                          onChange={(e) => {
                            setFilterName(e.target.value);
                            // setSelectedRoomType([]);
                            handleUrlInnerFilter(e.target.value);
                            setBookingDetails([]);
                            setSelectedBookingStatus([]);
                            setSelectedPaymentMode([]);
                            setSelectedPaymentOf([]);
                            setRegionReportsData([]);
                            setOrders([]);
                            setPolarisData([]);
                            setLoader(false);
                          }}
                          options={reportFilterOptions}
                        />
                      </div>
                      {filterName === "rev_par" && (
                        <>
                          {/* closeOnScroll={(e) => e.target === document} */}
                          <div style={{ width: "200px" }}>
                            <div style={{ marginBottom: "5px" }}>
                              Comparison Date Range
                            </div>
                            <SelectDateRange
                              defaultDate={`${calculateToDate(
                                currentDate,
                                15,
                                "subtract"
                              )}:${calculateToDate(currentDate, 15, "add")}`}
                              dataFromChild={dataFromChild}
                            />
                          </div>

                         

                          <div style={{ width: "210px" }}>
                            <CustomRoomChoiceSelect
                              roomChoice={choiceListRoomsArray}
                              getDataFromCustomRoomChoiceSelect={
                                getDataFromCustomRoomChoiceSelect
                              }
                              selectedRoomsIds={selectedRoomType}
                              heading="Room Type"
                              showButtons={true}
                              error={revParError.roomType}
                            />
                          </div>

                          {/* <div style={{ width: "200px" }}>
                              <div style={{ marginBottom: "5px" }}>Source</div>
                              <div
                                ref={accordRef}
                                style={{
                                  position: "relative",
                                }}
                              >
                                <div
                                  onClick={toggleAccord3}
                                  style={{
                                    display: "flex",
                                    padding: "10px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    position: "relative",
                                    cursor: "pointer",
                                    border: "0.5px solid #CCC",
                                    gap: "10px",
                                    borderRadius: "5px",
                                    fontSize: "14px",
                                    height: "35px",
                                  }}
                                >
                                  <div
                                    style={{ borderRadius: "5px" }}
                                    className=""
                                  >
                                    {selectedBookingStatus.length} of 4 SELECTED
                                  </div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="6"
                                    viewBox="0 0 10 6"
                                    fill="none"
                                    className={`${
                                      isOpen ? "accordImageRotate" : null
                                    } `}
                                  >
                                    <path d="M1 1L5 5L9 1" stroke="#333333" />
                                  </svg>
                                </div>
                                {isOpen3 && (
                                  <div
                                    ref={otherMenusRef}
                                    style={{
                                      position: "absolute",
                                      border: "1px #3333332d solid",
                                      left: "0",
                                      top: "45px",
                                      width: "100%",
                                      height: "fit-content",
                                      zIndex: 999,
                                    }}
                                    className="accord_container"
                                  >
                                    <ChoiceList
                                      choices={[
                                        {
                                          label: "Walk In Booking",
                                          value: "walk_in_booking",
                                        },
                                        {
                                          label: "Website Booking",
                                          value: "website_booking",
                                        },
                                        {
                                          label: "Channel Booking",
                                          value: "channel_booking",
                                        },
                                        {
                                          label: "Agent Booking",
                                          value: "agent_booking",
                                        },
                                      ]}
                                      selected={selectedBookingStatus}
                                      onChange={handleBookingStatus}
                                      allowMultiple
                                    />
                                  </div>
                                )}
                              </div>
                            </div> */}
                        </>
                      )}
                      {filterName === "occupancy" && (
                        <>
                          {/* closeOnScroll={(e) => e.target === document} */}
                          <div style={{ width: "200px" }}>
                            <div style={{ marginBottom: "5px" }}>
                              Comparison Date Range
                            </div>

                            <SelectDateRange
                              minDate={false}
                              dataFromChild={dataFromChild}
                            />
                          </div>

                          <div style={{ width: "210px" }}>
                            <CustomRoomChoiceSelect
                              roomChoice={choiceListRoomsArray}
                              getDataFromCustomRoomChoiceSelect={
                                getDataFromCustomRoomChoiceSelect
                              }
                              selectedRoomsIds={selectedRoomType}
                              heading="Room Type"
                              showButtons={true}
                              error={occupancyError.roomType}
                            />
                          </div>
                        </>
                      )}
                      {filterName === "room_occupancy" && (
                        <>
                          {/* closeOnScroll={(e) => e.target === document} */}
                          <div style={{ width: "200px" }}>
                            <div style={{ marginBottom: "5px" }}>
                              Comparison Date Range
                            </div>
                            {/* <SelectDatePickerCustom
                              minDate={false}
                                date={true}
                                month={true}
                                year={true}
                                dataFromChild={dataFromChild}
                              /> */}

                            {/* <div style={{ width: "200px" }}>
                                <VerticalInputSelectCustomCopy
                                  options={yearOptions}
                                  titleName={"Select Year"}
                                />
                              </div> */}
                            <SelectDateRange
                              minDate={false}
                              dataFromChild={dataFromChild}
                            />
                          </div>
                          {/* <div style={{ width: "200px" }}>
                              <div style={{ marginBottom: "5px" }}>
                                Comparison Start Range
                              </div>
                              <SelectDateRange dataFromChild={dataFromChild1} />
                            </div> */}

                          {/* <div style={{ width: "200px" }}>
                              <div style={{ marginBottom: "5px" }}>
                                Comparison End Range
                              </div>
                              <SelectDateRange dataFromChild={dataFromChild2} />
                            </div> */}

                          <div style={{ width: "210px" }}>
                            <CustomRoomChoiceSelect
                              roomChoice={choiceListRoomsArray}
                              getDataFromCustomRoomChoiceSelect={
                                getDataFromCustomRoomChoiceSelect
                              }
                              selectedRoomsIds={selectedRoomType}
                              heading="Room Type"
                              showButtons={true}
                            />
                          </div>
                        </>
                      )}
                      {filterName === "complimentary_kot" && (
                        <>
                          {/* closeOnScroll={(e) => e.target === document} */}
                          <div style={{ width: "200px" }}>
                            <div style={{ marginBottom: "5px" }}>
                              Comparison Date Range
                            </div>
                            <SelectDateRange
                              minDate={false}
                              dataFromChild={dataFromChild}
                              maxDate={new Date()}
                            />
                          </div>
                        </>
                      )}
                      {filterName === "cancelled_kot" && (
                        <>
                          {/* closeOnScroll={(e) => e.target === document} */}
                          <div style={{ width: "200px" }}>
                            <div style={{ marginBottom: "5px" }}>
                              Comparison Date Range
                            </div>
                            <SelectDateRange
                              minDate={false}
                              dataFromChild={dataFromChild}
                              maxDate={new Date()}
                            />
                          </div>
                        </>
                      )}
                      {filterName === "occupancy_chart" && (
                        <>
                          {/* closeOnScroll={(e) => e.target === document} */}
                          <div style={{ width: "200px" }}>
                            <div style={{ marginBottom: "5px" }}>
                              Comparison Date Range
                            </div>
                            <SelectDateRange
                              minDate={false}
                              dataFromChild={dataFromChild}
                              maxDate={new Date()}
                            />
                          </div>
                        </>
                      )}
                      {filterName === "adr_report" && (
                        <>
                          {/* closeOnScroll={(e) => e.target === document} */}
                          <div style={{ width: "200px" }}>
                            <div style={{ marginBottom: "5px" }}>
                              Comparison Date Range
                            </div>
                            {/* <SelectDatePickerCustom
                              minDate={false}
                                date={true}
                                month={true}
                                year={true}
                                dataFromChild={dataFromChild}
                              /> */}

                            {/* <div style={{ width: "200px" }}>
                                <VerticalInputSelectCustomCopy
                                  options={yearOptions}
                                  titleName={"Select Year"}
                                />
                              </div> */}
                            <SelectDateRange
                              minDate={false}
                              dataFromChild={dataFromChild}
                            />
                          </div>
                          {/* <div style={{ width: "200px" }}>
                              <div style={{ marginBottom: "5px" }}>
                                Comparison Start Range
                              </div>
                              <SelectDateRange dataFromChild={dataFromChild1} />
                            </div> */}

                          {/* <div style={{ width: "200px" }}>
                              <div style={{ marginBottom: "5px" }}>
                                Comparison End Range
                              </div>
                              <SelectDateRange dataFromChild={dataFromChild2} />
                            </div> */}

                          <div style={{ width: "210px" }}>
                            <CustomRoomChoiceSelect
                              roomChoice={choiceListRoomsArray}
                              getDataFromCustomRoomChoiceSelect={
                                getDataFromCustomRoomChoiceSelect
                              }
                              selectedRoomsIds={selectedRoomType}
                              heading="Room Type"
                              showButtons={true}
                            />
                          </div>
                        </>
                      )}
                      {filterName === "channel_production" && (
                        <>
                          {/* closeOnScroll={(e) => e.target === document} */}
                          <div style={{ width: "200px" }}>
                            <div style={{ width: "200px" }}>
                              <VerticalInputFieldCustom
                                options={yearOptions}
                                titleName={"Period"}
                                type={"date"}
                                value={periodStartDate}
                                onChange={(e) =>
                                  setPeriodStartDate(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div style={{ width: "200px" }}>
                            <VerticalInputFieldCustom
                              options={yearOptions}
                              titleName={"."}
                              type={"date"}
                              value={periodEndDate}
                              onChange={(e) => setPeriodEndDate(e.target.value)}
                              min={periodStartDate}
                            />
                          </div>

                          <div style={{ width: "210px" }}>
                            <CustomRoomChoiceSelect
                              roomChoice={choiceListRoomsArray}
                              getDataFromCustomRoomChoiceSelect={
                                getDataFromCustomRoomChoiceSelect
                              }
                              selectedRoomsIds={selectedRoomType}
                              heading="Room Type"
                              showButtons={true}
                            />
                          </div>

                          <div style={{ width: "200px" }}>
                            <div style={{ marginBottom: "5px" }}>
                              Reservation Source
                            </div>
                            <div
                              ref={accordRef}
                              style={{
                                position: "relative",
                              }}
                            >
                              <div
                                onClick={toggleAccord3}
                                style={{
                                  display: "flex",
                                  padding: "10px",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  position: "relative",
                                  cursor: "pointer",
                                  border: "0.5px solid #CCC",
                                  gap: "10px",
                                  borderRadius: "5px",
                                  fontSize: "14px",
                                  height: "35px",
                                }}
                              >
                                <div
                                  style={{ borderRadius: "5px" }}
                                  className=""
                                >
                                  {selectedBookingStatus.length} of 4 SELECTED
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="6"
                                  viewBox="0 0 10 6"
                                  fill="none"
                                  className={`${
                                    isOpen ? "accordImageRotate" : null
                                  } `}
                                >
                                  <path d="M1 1L5 5L9 1" stroke="#333333" />
                                </svg>
                              </div>
                              {isOpen3 && (
                                <div
                                  ref={otherMenusRef}
                                  style={{
                                    position: "absolute",
                                    border: "1px #3333332d solid",
                                    left: "0",
                                    top: "45px",
                                    width: "100%",
                                    height: "fit-content",
                                    zIndex: 999,
                                  }}
                                  className="accord_container"
                                >
                                  <ChoiceList
                                    choices={[
                                      {
                                        label: "Walk In Booking",
                                        value: "walk_in_booking",
                                      },
                                      {
                                        label: "Website Booking",
                                        value: "website_booking",
                                      },
                                      {
                                        label: "Channel Booking",
                                        value: "channel_booking",
                                      },
                                      {
                                        label: "Agent Booking",
                                        value: "agent_booking",
                                      },
                                    ]}
                                    selected={selectedBookingStatus}
                                    onChange={handleBookingStatus}
                                    allowMultiple
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {filterName === "reservation_by_region" && (
                        <>
                          <div style={{ width: "200px" }}>
                            <div style={{ marginBottom: "5px" }}>
                              Comparison Date Range
                            </div>
                            <SelectDateRange
                              minDate={false}
                              dataFromChild={dataFromChild}
                            />
                          </div>

                          {/* <div style={{ width: "200px" }}>
                            <div style={{ marginBottom: "5px" }}>
                              Payment Of
                            </div>
                            <div
                              ref={paymentModeRef}
                              style={{
                                position: "relative",
                              }}
                            >
                              <div
                                onClick={toggleAccord4}
                                style={{
                                  display: "flex",
                                  padding: "10px",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  position: "relative",
                                  cursor: "pointer",
                                  border: "0.5px solid #CCC",
                                  gap: "10px",
                                  borderRadius: "5px",
                                  fontSize: "14px",
                                  height: "35px",
                                }}
                              >
                                <div
                                  style={{ borderRadius: "5px" }}
                                  className=""
                                >
                                  {selectedPaymentOf.length} of 3 SELECTED
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="6"
                                  viewBox="0 0 10 6"
                                  fill="none"
                                  className={`${
                                    isOpen4 ? "accordImageRotate" : null
                                  } `}
                                >
                                  <path d="M1 1L5 5L9 1" stroke="#333333" />
                                </svg>
                              </div>
                              {isOpen4 && (
                                <div
                                  ref={otherMenusRef}
                                  style={{
                                    position: "absolute",
                                    border: "1px #3333332d solid",
                                    left: "0",
                                    top: "45px",
                                    width: "100%",
                                    height: "fit-content",
                                    zIndex: 999,
                                  }}
                                  className="accord_container"
                                >
                                  <ChoiceList
                                    choices={[
                                      {
                                        label: "Booking",
                                        value: "booking",
                                      },
                                      {
                                        label: "Event",
                                        value: "event",
                                      },
                                      {
                                        label: "food",
                                        value: "food",
                                      },
                                    ]}
                                    selected={selectedPaymentOf}
                                    onChange={handlePaymentOf}
                                    allowMultiple
                                  />
                                </div>
                              )}
                            </div>
                          </div> */}

                          {/* <div style={{ width: "200px" }}>
                            <div style={{ marginBottom: "5px" }}>
                              Payment Mode
                            </div>
                            <div
                              ref={paymentModeRef}
                              style={{
                                position: "relative",
                              }}
                            >
                              <div
                                onClick={toggleAccord5}
                                style={{
                                  display: "flex",
                                  padding: "10px",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  position: "relative",
                                  cursor: "pointer",
                                  border: "0.5px solid #CCC",
                                  gap: "10px",
                                  borderRadius: "5px",
                                  fontSize: "14px",
                                  height: "35px",
                                }}
                              >
                                <div
                                  style={{ borderRadius: "5px" }}
                                  className=""
                                >
                                  {selectedPaymentMode.length} of 6 SELECTED
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="6"
                                  viewBox="0 0 10 6"
                                  fill="none"
                                  className={`${
                                    isOpen5 ? "accordImageRotate" : null
                                  } `}
                                >
                                  <path d="M1 1L5 5L9 1" stroke="#333333" />
                                </svg>
                              </div>
                              {isOpen5 && (
                                <div
                                  ref={otherMenusRef}
                                  style={{
                                    position: "absolute",
                                    border: "1px #3333332d solid",
                                    left: "0",
                                    top: "45px",
                                    width: "100%",
                                    height: "fit-content",
                                    zIndex: 999,
                                  }}
                                  className="accord_container"
                                >
                                  <ChoiceList
                                    choices={[
                                      {
                                        label: "Cash",
                                        value: "cash",
                                      },
                                      {
                                        label: "Bank Payment",
                                        value: "bank_ayment",
                                      },
                                      {
                                        label: "UPI",
                                        value: "upi",
                                      },
                                      {
                                        label: "Credit Card",
                                        value: "credit_card",
                                      },
                                      {
                                        label: "Debit Card",
                                        value: "debit_card",
                                      },
                                      {
                                        label: "Other",
                                        value: "other",
                                      },
                                    ]}
                                    selected={selectedPaymentMode}
                                    onChange={handlePaymentMode}
                                    allowMultiple
                                  />
                                </div>
                              )}
                            </div>
                          </div> */}
                        </>
                      )}
                      {filterName === "reservations_by_type" && (
                        <>
                          <div style={{ width: "200px" }}>
                            <div style={{ marginBottom: "5px" }}>
                              Comparison Date Range
                            </div>
                            <SelectDateRange
                              minDate={false}
                              dataFromChild={dataFromChild}
                            />
                          </div>
                        </>
                      )}
                      {filterName === "reservations_by_plan" && (
                        <>
                          <div style={{ width: "200px" }}>
                            <div style={{ marginBottom: "5px" }}>
                              Comparison Date Range
                            </div>
                            <SelectDateRange
                              minDate={false}
                              dataFromChild={dataFromChild}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div></div>
                    <div className="flex flex_gap_10">
                      <>
                        {filterName === "rev_par" && (
                          <BgThemeButton
                            children={"Apply"}
                            loader={loader}
                            onClick={() => fetchRevparHandle(property_id.id)}
                          />
                        )}

                        {filterName === "occupancy" && (
                          <BgThemeButton
                            children={"Apply"}
                            loader={loader}
                            onClick={() => fetchOccupancyHandle(property_id.id)}
                          />
                        )}

                        {filterName === "reservation_by_region" && (
                          <BgThemeButton
                            children={"Apply"}
                            loader={loader}
                            onClick={() => fetchRegionReport(property_id.id)}
                          />
                        )}

                        {filterName === "adr_report" && (
                          <BgThemeButton
                            children={"Apply"}
                            loader={loader}
                            onClick={() => fetchADRHandle(property_id.id)}
                          />
                        )}
                        {filterName === "reservations_by_type" && (
                          <BgThemeButton
                            children={"Apply"}
                            loader={loader}
                            onClick={() =>
                              fetchReservationByTypeHandle(property_id.id)
                            }
                          />
                        )}
                        {filterName === "reservations_by_plan" && (
                          <BgThemeButton
                            children={"Apply"}
                            loader={loader}
                            onClick={() =>
                              fetchReservationByPlanHandle(property_id.id)
                            }
                          />
                        )}
                        {filterName === "room_occupancy" && (
                          <BgThemeButton
                            children={"Apply"}
                            loader={loader}
                            onClick={() =>
                              fetchRoomOccupancyInfoReport(property_id.id)
                            }
                          />
                        )}
                        {filterName === "complimentary_kot" && (
                          <BgThemeButton
                            children={"Apply"}
                            loader={loader}
                            onClick={() =>
                              fetchComplimentaryKot(property_id.id)
                            }
                          />
                        )}
                        {filterName === "cancelled_kot" && (
                          <BgThemeButton
                            children={"Apply"}
                            loader={loader}
                            onClick={() => fetchCAncelledKot(property_id.id)}
                          />
                        )}
                        {filterName === "occupancy_chart" && (
                          <BgThemeButton
                            children={"Apply"}
                            loader={loader}
                            onClick={() => fetchOccupancyChart(property_id.id)}
                          />
                        )}
                      </>

                      <BgOutlineThemeButton
                        onClick={() => {
                          setFilterName("");
                          // setSelectedRoomType([]);
                          setBookingDetails([]);
                          setSelectedBookingStatus([]);
                          setRegionReportsData([]);
                          setSelectedPaymentMode([]);
                          setSelectedPaymentOf([]);
                          setOrders([]);
                          setSelectedPaymentOf([]);
                        }}
                        type={"button"}
                        children={"Clear"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {filterName === "rev_par" && polarisData.length > 0 && (
              <>
                <LineChartDashboard
                  data={polarisData}
                  title={"RevPar Insight (Revenue Per Available Room)"}
                />

                <ComparisonRevparDoubleCharts
                  tableOneData={fullData?.from_data}
                  tableTwoData={fullData?.to_data}
                  status={"RevPar"}
                  reportData={fullData}
                  title={"RevPar Insight (Revenue Per Available Room)"}
                />
              </>
            )}
            {filterName === "occupancy" && polarisData.length > 0 && (
              <>
                <LineChartDashboard data={polarisData} title={"Occupancy"} />

                <ComparisonRevparDoubleCharts
                  tableOneData={fullData?.from_data}
                  tableTwoData={fullData?.to_data}
                  status={"Occupancy"}
                  reportData={fullData}
                  title={"RevPar Insight (Revenue Per Available Room)"}
                />
               


              </>
            )}

            {filterName === "reservation_by_region" &&
              regionReportsData &&
              regionReportsData?.length > 0 && (
                <>
                  {loader ? (
                    <LoaderSmall />
                  ) : (
                    <RegionReportComp regionReportsData={regionReportsData} />
                  )}
                </>
              )}

            {filterName === "reservations_by_type" &&
              regionReportsData &&
              regionReportsData?.length > 0 && (
                <>
                  {loader ? (
                    <LoaderSmall />
                  ) : (
                    <ReportByRoomType reportData={regionReportsData} />
                  )}
                </>
              )}
            {/* {filterName === "room_occupancy" &&
              orders &&
              orders?.length > 0 && (
                <>
                  {loader ? (
                    <LoaderSmall />
                  ) : (
                    // <div>ppp</div>
                    <ReportByRoomOccupancyNew reportData={orders} />
                  )}
                </>
              )} */}
            {filterName === "complimentary_kot" && fullData && (
              <>
                {loader ? (
                  <LoaderSmall />
                ) : (
                  // <div>ppp</div>
                  <ReportComplimentaryKot reportData={orders} />
                )}
              </>
            )}
            {filterName === "cancelled_kot" && fullData && (
              <>
                {loader ? (
                  <LoaderSmall />
                ) : (
                  // <div>ppp</div>
                  <ReportCancelledKot reportData={orders} />
                )}
              </>
            )}
            {filterName === "occupancy_chart" && fullData && (
              <>
                {loader ? (
                  <LoaderSmall />
                ) : (
                  // <div>ppp</div>
                  <ReportOccupancyChart reportData={orders} />
                )}
              </>
            )}

            {filterName === "reservations_by_plan" &&
              regionReportsData &&
              regionReportsData?.length > 0 && (
                <>
                  {loader ? (
                    <LoaderSmall />
                  ) : (
                    <ReportByMealPlan regionReportsData={regionReportsData} />
                  )}
                </>
              )}

            {filterName === "adr_report" &&
              polarisData &&
              polarisData.length > 0 && (
                <>
                  {loader ? (
                    <LoaderSmall />
                  ) : (
                    <>
                      <LineChartDashboard
                        data={polarisData}
                        title={"ADR (Average Daily Rate)"}
                      />
                      <ComparisonRevparDoubleCharts
                        tableOneData={fullData?.from_data}
                        tableTwoData={fullData?.to_data}
                        status={"ADR"}
                        reportData={fullData}
                        title={"ADR (Average Daily Rate)"}
                      />
                    </>
                  )}
                </>
              )}
          </div>
        </section>
      </>
      {/* )} */}
    </>
  );
};

export default ProductionReports;
