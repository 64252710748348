import React, { useState, useEffect } from 'react';
import styles from './daySelectionWeekDays.module.scss'; // Assuming you have the CSS in the same directory

const DaySelectionWeekDays = ({ onSaveDates }) => {
  const [selectedDays, setSelectedDays] = useState([]);
  const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'];

  const handleDayChange = day => {
    const isSelected = selectedDays.includes(day);
    let updatedDays;

    if (isSelected) {
      updatedDays = selectedDays.filter(d => d !== day);
    } else {
      updatedDays = [...selectedDays, day];
    }

    setSelectedDays(updatedDays);
  };

  const selectWeekdays = () => {
    setSelectedDays(daysOfWeek.slice(1, 6)); // Monday to Friday
  };

  const selectWeekends = () => {
    setSelectedDays(['sun', 'sat']);
  };

  const selectCustom = () => {
    setSelectedDays(daysOfWeek);
  };

  const isWeekdaySelected = () => {
    const weekdays = daysOfWeek.slice(1, 6);
    return (
      weekdays.every(day => selectedDays.includes(day)) &&
      selectedDays.length === 5
    );
  };

  const isWeekendSelected = () =>
    selectedDays.includes('sun') &&
    selectedDays.includes('sat') &&
    selectedDays.length === 2;

  const isCustomSelected = () => {
    const weekdays = daysOfWeek.slice(1, 6);
    const weekends = ['sun', 'sat'];
    const isMixedSelection =
      selectedDays.some(day => weekdays.includes(day)) &&
      selectedDays.some(day => weekends.includes(day));

    return (
      selectedDays.length > 0 &&
      (selectedDays.length === 7 || // All days are selected
        isMixedSelection || // Mix of weekdays and weekends
        selectedDays.length === 1 || // Only one day is selected
        (!isWeekdaySelected() && !isWeekendSelected())) // Neither all weekdays nor all weekends are selected
    );
  };

  const getDaySelectionObject = () => {
    const daySelection = {};

    daysOfWeek.forEach(day => {
      daySelection[day] = selectedDays.includes(day) ? 1 : 0;
    });

    return daySelection;
  };

  useEffect(() => {
    const daySelection = getDaySelectionObject();
    onSaveDates(daySelection); // Pass the object to the parent component
  }, [selectedDays, onSaveDates]);

  return (
    <>
    
    <div>
      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <button
          onClick={selectWeekdays}
          className={ `${styles.selectDayButtons}  ${isWeekdaySelected() ? styles.selected : ''}`}
        >
          Weekdays
        </button>
        <button
          onClick={selectWeekends}
          className={  `${styles.selectDayButtons} ${isWeekendSelected() ? styles.selected : ''}`}
        >
          Weekends
        </button>
        <button
          onClick={selectCustom}
          className={`${styles.selectDayButtons} ${isCustomSelected() ? styles.selected : ''}`}
        >
          Custom
        </button>
      </div>

      <div className='flex flex_gap_10'>
        {daysOfWeek.map((day, index) => (
          <label key={index}  className={styles.dayName}>
            <input
              type='checkbox'
              checked={selectedDays.includes(day)}
              onChange={() => handleDayChange(day)}
            />
            {day}
          </label>
        ))}
      </div>
    </div>
    
    
    
    
    
    
    
    </>
  );
};

export default DaySelectionWeekDays;