import React, { useEffect, useRef } from "react";
import { NavLink, Outlet } from "react-router-dom";

const ChannelManagerSettingsPage = () => {
  const ref = useRef(null);

  useEffect(() => {
    // Set the meta title
    document.title =
      "Room Type - RevCatalyst - Boosting Your Hotel Performance";

    // Create or update the meta description tag
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";


    

  
  }, []);

  const links = [
    // { label: "Rooms", path: "rooms" },
    { label: "Channel", path: "channel" },
    // { label: "Channel Mapping", path: "channel-mapping" },
    
    // { label: "Room Types Plan", path: "room-rate-plans" },
    // { label: "Inventory", path: "inventory" },
    // { label: "Inventory Rates", path: "inventory-rates" },

  ];


 






  return (
    <div className="main_container">
      <div className="footer_inner_lists " style={{border:"none" ,marginTop:"20px",boxShadow :"0 0 0 1px rgb(0 0 0 / 4%)",borderRadius:"4px"}}>
        <ul className="flex flex_gap_10">
          {links.map((link, index) => (
            <li key={index} style={{ listStyle: "none" }}>
              <NavLink
                to={link.path}
                className={({ isActive }) =>
                  `inner_list_item${isActive ? " active" : ""}`
                }
              >
                {link.label}
              </NavLink>
            </li>
          ))}

         
        </ul>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Outlet />
      </div>
    </div>
  );
};

export default ChannelManagerSettingsPage;
